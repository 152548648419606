import { generateUuid } from '../utils/generateUuid';

class EnvironmentVariables {
    readonly API_URL = process.env.REACT_APP_API_URL;
    readonly OAUTH_API_URL = process.env.REACT_APP_OAUTH_API_URL;

    constructor() {
        if (!this.isTestEnvironment()) {
            this.validateEnvVars();
        } else {
            this.initEnvVarsWithRandomStrings();
        }
    }

    private isTestEnvironment(): boolean {
        return process?.env?.JEST_WORKER_ID !== undefined;
    }

    private validateEnvVars(): void {
        for (const envVarName in this) {
            if (!this[envVarName]) {
                this.throwMissingVariableError(envVarName);
            }
        }
    }

    private throwMissingVariableError(varName: string): never {
        throw new Error(`Missing environment variable ${varName}`);
    }

    private initEnvVarsWithRandomStrings(): void {
        for (const envVarName in this) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this[envVarName] = generateUuid();
        }
    }
}

export const environmentVariables = new EnvironmentVariables();
