export enum CourseType {
    Initiation,
    Qualification,
    Specialization,
    Training,
}

export const CourseTypeValue = {
    [CourseType.Initiation]: 'INITIATION',
    [CourseType.Qualification]: 'QUALIFICATION',
    [CourseType.Specialization]: 'SPECIALIZATION',
    [CourseType.Training]: 'TRAINING',
};
