import { Callout, mergeStyleSets } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import React, { useEffect, useRef, useState } from 'react';
import { pickerListService } from '../../services/dataAccess';
import { convertToString } from '../../utils/convertToString';
import { ControllerInputProps } from '../input/InputFieldTypes';
import { TextFieldStyled } from '../input/TextFieldStyled';
import { InputPickerModal, SelectedIndexSource, Props as InputPickerModalProps, InputPickerModalScrollEvent } from './InputPickerModal';

export interface InputPickerProperties<TItem> extends ControllerInputProps {
    label: string;
    // Endpoint which we should call, the component will automatically call {{apiEndpoint}}/GetPage
    apiEndpoint: string;
    // Function to get additional params for api endpoint
    getApiParams?: () => Record<string, unknown>;
    // This means that we let the user update the input value freely
    allowFreeText: boolean;
    // How we render an item
    renderItem?: InputPickerModalProps<TItem>['renderItem'];
    // Convert an item to a string to show it in the text input
    displayItem?: (item: TItem) => string;
}

// This is needed for the callout so he knows what css class he needs to call window.getComputedStyles
const calloutStyles = mergeStyleSets({
    buttonArea: {},
});

export function InputPicker<TItem>(props: InputPickerProperties<TItem>): JSX.Element {
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);

    // We keep the text field value internally
    const [textFieldValue, setTextFieldValue] = useState<string>('');

    // Pagination related data
    const [hasNextPage, setHasNextPage] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [items, setItems] = useState<TItem[]>([]);
    const [loading, setLoading] = useState(false);
    const [isAnyChange, setIsAnyChange] = useState(false);

    // What item is selected
    const [selectedItem, setSelectedItem] = useState<{ index: number; source: SelectedIndexSource }>({
        index: 0,
        source: SelectedIndexSource.InitialRender,
    });

    const pickerModalRef = useRef<HTMLDivElement | null>(null);
    const inputContainerRef = useRef<HTMLDivElement | null>(null);

    const displayItem =
        props.displayItem ??
        ((itemRef: TItem): string =>
            // We return the items as it is
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            itemRef as string);

    const onFocus = (e: React.FocusEvent<HTMLInputElement>): void => {
        if (!isCalloutVisible) {
            toggleIsCalloutVisible();
        }
    };

    const onBlur = (): void => {
        if (isCalloutVisible) {
            toggleIsCalloutVisible();
        }
        if (!props.allowFreeText) {
            const textSearchedValue = textFieldValue?.toLocaleLowerCase() ?? '';
            const selectedItem = items.find((it) => displayItem(it).toLocaleLowerCase() === textSearchedValue);

            if (selectedItem) {
                onItemSelected(selectedItem);
            } else if (isAnyChange) {
                props.onChange({});
            }
        }
        props.onBlur?.();
    };

    const resetToFirstPage = (newValue?: string): void => {
        setItems([]);
        setSelectedItem({ index: 0, source: SelectedIndexSource.InitialRender });
        setCurrentPage(1);
        readItemsFromApi(newValue ?? '', 1);
    };

    // const readNextPageWithDelay = useRef(throttle(resetToFirstPage, 500));

    useEffect(() => {
        const newTextFieldValue = convertToString(!props.allowFreeText ? displayItem(props.value) : props.value);
        setTextFieldValue(newTextFieldValue);

        // Debounce reading data from server
        setLoading(true);
        const timeoutId = setTimeout(() => {
            resetToFirstPage(newTextFieldValue);
        }, 200);
        return (): void => {
            clearTimeout(timeoutId);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.getApiParams, props.value, props.allowFreeText]);

    const readItemsFromApi = async (searchText: string, newCurrentPage: number): Promise<void> => {
        const maxCount = 20;
        const skip = (newCurrentPage - 1) * maxCount;

        setLoading(true);
        try {
            const apiParams = props.getApiParams ? props.getApiParams() : null;
            const page = await pickerListService.getItems<TItem>(props.apiEndpoint, { maxCount, skip, searchText, ...apiParams });

            setHasNextPage(page?.hasNextPage);
            setItems([...page.items]);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const onTextChanged = (event: any, newValue?: string): void => {
        const currentValue = newValue ?? '';
        setTextFieldValue(newValue ?? '');
        if (props.allowFreeText) {
            props.onChange(newValue);
            // onChange will trigger props.Value to change and our hook will update items so we'll return now.
            return;
        }
        resetToFirstPage(currentValue);
    };

    const onScroll = (event: InputPickerModalScrollEvent<TItem>): void => {
        event.stopPropagation();

        if (!pickerModalRef.current) {
            return;
        }

        const scrolledDistanceToReachBottom =
            pickerModalRef.current.scrollHeight - pickerModalRef.current.scrollTop - pickerModalRef.current?.offsetHeight;
        const isAlmostBottom = scrolledDistanceToReachBottom <= 10;
        if (isAlmostBottom && hasNextPage) {
            readItemsFromApi(textFieldValue, currentPage + 1);
            setCurrentPage((oldCurrentPage): number => oldCurrentPage + 1);
        }
    };

    const onItemSelected = (item: TItem): void => {
        const displayValue = displayItem(item) ?? '';
        setTextFieldValue(displayValue);
        if (props.allowFreeText) {
            props.onChange(displayValue);
        } else {
            props.onChange(item);
        }
        setIsAnyChange(false);
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        setIsAnyChange(true);
        if (event.key === 'Enter' && selectedItem.index < items.length && selectedItem.index >= 0) {
            event.preventDefault();
            event.stopPropagation();
            onItemSelected(items[selectedItem.index]);
            toggleIsCalloutVisible();
        }
        if (event.key === 'Tab' && selectedItem.index < items.length && selectedItem.index >= 0) {
            onItemSelected(items[selectedItem.index]);
        }
        if (event.key === 'ArrowDown' && selectedItem.index < items.length - 1) {
            event.preventDefault();
            event.stopPropagation();
            const nextSelectedIndex = selectedItem.index + 1;
            updateSelectedIndex(nextSelectedIndex, SelectedIndexSource.ArrowNavigation);
        }
        if (event.key === 'ArrowUp' && selectedItem.index > 0) {
            event.preventDefault();
            event.stopPropagation();
            const nextSelectedIndex = selectedItem.index - 1;
            updateSelectedIndex(nextSelectedIndex, SelectedIndexSource.ArrowNavigation);
        }
    };
    const updateSelectedIndex = (index: number, source: SelectedIndexSource): void => {
        setSelectedItem({ index, source });
        setItems([...items]);
    };

    const maxWidthPickerModal = inputContainerRef.current ? inputContainerRef.current.clientWidth + 'px' : '100%';
    const renderItem = props.renderItem ?? ((item: TItem): JSX.Element => <>{displayItem(item)}</>);
    return (
        <div>
            <div className={calloutStyles.buttonArea} ref={inputContainerRef}>
                <TextFieldStyled
                    label={props.label}
                    autoComplete="off"
                    onFocus={onFocus}
                    value={textFieldValue}
                    onBlur={onBlur}
                    onChange={onTextChanged}
                    onKeyDown={onKeyDown}
                    errorMessage={props.errorMessage}
                    disabled={props.disabled}
                    required={props.required}
                />
            </div>
            {isCalloutVisible && (
                <Callout role="alertdialog" gapSpace={0} target={inputContainerRef.current} setInitialFocus>
                    <InputPickerModal
                        loading={loading}
                        maxWidthPickerModal={maxWidthPickerModal}
                        onScroll={onScroll}
                        modalRef={pickerModalRef}
                        items={items}
                        renderItem={renderItem}
                        selectedItem={selectedItem}
                        onItemSelected={(item): void => onItemSelected(item)}
                        onItemMouseEnter={(_, index): void => updateSelectedIndex(index, SelectedIndexSource.MouseOver)}
                    />
                </Callout>
            )}
        </div>
    );
}
