import * as yup from 'yup';
import { StringSchema } from 'yup';
import { ErrorCode } from './errors.util';

function requiredUuid(): StringSchema<string> {
    return yup.string().uuid(ErrorCode.Uuid).required(ErrorCode.Required);
}

export function requiredPickerValue(): yup.ObjectSchema<{ id: string } | undefined> {
    return yup.object().shape({
        id: requiredUuid(),
    });
}
