import { BaseApiModel } from '../../common/baseApiModel';
import { ContributorType } from '../../common/contributorType';
import { PaginatedOutput } from '../../common/paginatedApiOutput';

export interface BranchStaffMemberApiModel extends BaseApiModel {
    firstName: string;
    lastName: string;
    contributorType: ContributorType;
    socialSecurityNumber: string;
}

export function emptyBranchStaffMember(): BranchStaffMemberApiModel {
    return {
        id: '',
        firstName: '',
        lastName: '',
        contributorType: ContributorType.Internal,
        socialSecurityNumber: '',
    };
}

export interface BranchStaffMemberListItemApiModel extends BranchStaffMemberApiModel, BaseApiModel {}

export type BranchStaffMemberListApiModel = PaginatedOutput<BranchStaffMemberListItemApiModel>;
