import { SessionData } from '../../state/models/sessionData';
import { LoginApiInput, LoginApiOutput } from './loginApiModels';
import { AxiosInstance } from 'axios';
import { apiUrls } from '../../environment/apiUrls';

export class AuthService {
    readonly oauthApiEndpoint = apiUrls.IDENTITY;

    constructor(private httpClient: AxiosInstance) {}

    public async login(input: LoginApiInput): Promise<SessionData> {
        const { data } = await this.httpClient.post<LoginApiOutput>(`${this.oauthApiEndpoint}/Login`, input);
        return new SessionData({ token: data.token, isExpired: false });
    }

    public async repopulateToken(): Promise<SessionData> {
        const { data } = await this.httpClient.get<LoginApiOutput>(`${this.oauthApiEndpoint}/ReToken`);
        return new SessionData({ token: data.token, isExpired: false });
    }
}
