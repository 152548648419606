import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

// the translations
const backendOptions = {
    loadPath: '/locales/{{lng}}.json',
};

export function initAppLanguage(): Promise<unknown> {
    return i18n
        .use(Backend)
        .use(detector)
        .use(initReactI18next) // passes i18n down to react-i18next
        .init({
            backend: backendOptions,
            lng: 'ro',
            fallbackLng: 'ro',

            keySeparator: false, // we do not use keys in form messages.welcome

            interpolation: {
                escapeValue: false, // react already safes from xss
            },
        });
}
