import React from 'react';
import styled from 'styled-components';
import { ReactProps } from '../../utils/types';
import { CenteredContent } from './CeteredContent';
import { PageContent } from './PageContent';

const FlexCenteredContent = styled(CenteredContent)`
    flex: 1;
    min-width: 0;
`;

const CenteredPageStyle = styled.div`
    display: flex;
    flex: 1;
    min-height: 100vh;
    min-width: 0;
`;

export function CenteredPage(props: ReactProps): JSX.Element {
    return (
        <CenteredPageStyle>
            <FlexCenteredContent>
                <PageContent>
                    <FlexCenteredContent>{props.children}</FlexCenteredContent>
                </PageContent>
            </FlexCenteredContent>
        </CenteredPageStyle>
    );
}
