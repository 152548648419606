import { DefaultButton, Stack } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { JsonParam, useQueryParams, withDefault } from 'use-query-params';
import { Card } from '../../../componentsUx/card/Card';
import { CardActions } from '../../../componentsUx/card/CardActions';
import { CardTitle } from '../../../componentsUx/card/CardTitle';
import { SubmitButton } from '../../../componentsUx/input/SubmitButton';
import { reportService } from '../../../services/dataAccess';
import { APP_PATHS_FROM_ROOT } from '../../routing/commands';
import { entityTypeToReportTypeMap, ReportApiModel } from './reportApiModel';
import { ReportFilter } from './ReportFilter';
import { ReportVariable } from './ReportVariable';
import { GeneratedReportType } from './reportFilterApiModel';
import { apiUrls } from '../../../environment/apiUrls';

interface Props {
    reportId: string;
    setIsGenerating: (isGenerating: boolean) => void;
}

export function ReportDetailsCard(props: Props): JSX.Element {
    const [t] = useTranslation();

    const [report, setReport] = useState<(ReportApiModel & { hasFilters: boolean; hasVariables: boolean }) | null>(null);
    const [generatedReportId, setGeneratedReportId] = useState<string>('');

    const defaultValues: Record<string, string> = {};
    const [currentValues, setCurrentValues] = useQueryParams({
        filters: withDefault(JsonParam, defaultValues),
        variables: withDefault(JsonParam, defaultValues),
        prefillFilterValues: withDefault(JsonParam, defaultValues),
    });
    const { control, handleSubmit, errors } = useForm();
    const history = useHistory();

    useEffect(() => {
        const readReportData = async (): Promise<void> => {
            const reportDetails = await reportService.getReport(props.reportId);
            const hasFilters = reportDetails.filters.filter((f) => !currentValues.filters[f.id]).length > 0;
            const hasVariables = reportDetails.variables.length > 0;
            setReport({ ...reportDetails, hasFilters, hasVariables });
        };
        readReportData();
    }, [props.reportId, currentValues.filters]);

    if (report === null) {
        return <></>;
    }

    const onGenerateReport = async (data: Record<string, string>): Promise<void> => {
        props.setIsGenerating(true);
        const filterValues: Record<string, string> = {};
        const variableValues: Record<string, string> = {};
        report.variables.forEach((v) => {
            const value = data[v.id] ?? currentValues.variables[v.id];
            if (value) {
                variableValues[v.id] = value.toString();
            }
        });
        report.filters.forEach((f) => {
            const value = data[f.id] ?? currentValues.filters[f.id];
            if (value) {
                filterValues[f.id] = value;
            }
        });

        const generatedReportId = await reportService.generateReport({
            reportId: report.id,
            filterValues: filterValues,
            variableValues: variableValues,
        });
        setGeneratedReportId(generatedReportId);
        history.push(APP_PATHS_FROM_ROOT.generatedReportPage(report.id, generatedReportId));
        setCurrentValues({ filters: filterValues, variables: variableValues });
    };

    const onDownloadReport = async (): Promise<void> => {
        const url = `${apiUrls.API}/Report/GeneratedReport/${generatedReportId}/${GeneratedReportType.Pdf}`;
        window.open(url, '_blank');
    };

    const showFilters = (): JSX.Element => {
        const filters = (
            <Stack>
                <h3>{t('REPORT_CARD_FILTERS')}</h3>
                {report.filters.map(
                    (filter): JSX.Element => (
                        <Stack.Item key={filter.id} order={filter.order}>
                            <ReportFilter
                                key={filter.id}
                                filter={filter}
                                control={control}
                                errors={errors}
                                defaultValue={currentValues.filters[filter.id]}
                                prefillFilterValues={currentValues.prefillFilterValues}
                            />
                        </Stack.Item>
                    ),
                )}
            </Stack>
        );

        return report.hasFilters ? filters : <></>;
    };

    const showVariables = (): JSX.Element => {
        const variables = (
            <Stack>
                <h3>{t('REPORT_CARD_VARIABLES')}</h3>
                {report.variables.map(
                    (variable): JSX.Element => (
                        <Stack.Item key={variable.id} order={variable.order}>
                            <ReportVariable
                                key={variable.id}
                                variable={variable}
                                control={control}
                                errors={errors}
                                defaultValue={currentValues.variables[variable.id]}
                            />
                        </Stack.Item>
                    ),
                )}
            </Stack>
        );

        return report.hasVariables ? variables : <></>;
    };

    return (
        <Card>
            <CardTitle>{report.name}</CardTitle>
            <p>{t(entityTypeToReportTypeMap[report.entityType])}</p>
            <form onSubmit={handleSubmit(onGenerateReport)}>
                {showFilters()}
                {showVariables()}
                <CardActions>
                    <SubmitButton label={t('REPORT_CARD_GENERATE')} />
                    {!!generatedReportId && <DefaultButton onClick={onDownloadReport}>{t('REPORT_CARD_DOWNLOAD')}</DefaultButton>}
                </CardActions>
            </form>
        </Card>
    );
}
