import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { SeriesListCard } from '../../../componentsBusiness/seriesListCard';
import { Breadcrumb } from '../../../componentsUx/breadcrumb/Breadcrumb';
import { dashboardSeriesListCardQuery } from '../../../services/dataAccess';
import { APP_PATHS_FROM_ROOT } from '../../routing/commands';
import { SeriesListItemApiModel } from '../serie/seriesApiModel';

export function Dashboard(): JSX.Element {
    const history = useHistory();
    const { t } = useTranslation();

    const breadcrumbItems = [
        {
            text: t('HOME_PAGE_NAV_ACTION_DASHBOARD'),
            key: 'HOME_PAGE_NAV_ACTION_DASHBOARD',
            isCurrentItem: true,
        },
    ];

    return (
        <>
            <Breadcrumb items={breadcrumbItems} />
            <SeriesListCard
                cardTitle="SERIE_LIST_CARD_TITLE"
                allowAdd={true}
                service={dashboardSeriesListCardQuery}
                staticServiceParams={{}}
                onAdd={(): void => {
                    history.push(APP_PATHS_FROM_ROOT.newSeriesPage());
                }}
                onItemClicked={(model: SeriesListItemApiModel): void => {
                    history.push(APP_PATHS_FROM_ROOT.seriesPage(model.id));
                }}
            />
        </>
    );
}
