import { AxiosInstance } from 'axios';
import { PaginatedApiOutput, createPaginatedOutput, GenericCommandResultOutput, PaginatedApiInput } from '../../common/paginatedApiOutput';
import { CourseCompetencyApiModel, CourseCompetencyListApiModel, CourseCompetencyListItemApiModel } from './courseCompetencyApiModel';

type CourseCompetenciesListApiInput = PaginatedApiInput & { courseProgramId: string };

export class CourseCompetencyService {
    readonly courseCompetencyEndpoint = `CourseCompetency`;

    constructor(private httpClient: AxiosInstance) {}

    public async readCourseCompetencies(courseCompetenciesListApiModel: CourseCompetenciesListApiInput): Promise<CourseCompetencyListApiModel> {
        const { data } = await this.httpClient.get<PaginatedApiOutput<CourseCompetencyListItemApiModel>>(`${this.courseCompetencyEndpoint}/GetPage`, {
            params: courseCompetenciesListApiModel,
        });

        return createPaginatedOutput(data);
    }

    public async updateCourseCompetency(params: CourseCompetencyApiModel): Promise<string | null> {
        const result = await this.httpClient.put<GenericCommandResultOutput>(`${this.courseCompetencyEndpoint}/Update`, params);
        return result.data.id;
    }

    public async addCourseCompetency(params: CourseCompetencyApiModel): Promise<string | null> {
        const result = await this.httpClient.post<GenericCommandResultOutput>(`${this.courseCompetencyEndpoint}/Create`, params);
        return result.data.id;
    }

    public async getCourseCompetency(courseCompetencyId: string): Promise<CourseCompetencyApiModel> {
        const { data } = await this.httpClient.get<CourseCompetencyApiModel>(`${this.courseCompetencyEndpoint}/${courseCompetencyId}`);
        return data;
    }
}
