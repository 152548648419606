import React, { Suspense } from 'react';
import { AppSetup } from './AppSetup';
import { GlobalProgressBar } from './state/GlobalLoaderState';

function App(): JSX.Element {
    return (
        <Suspense fallback={<GlobalProgressBar />}>
            <AppSetup />
        </Suspense>
    );
}

export default App;
