import { Toggle } from '@fluentui/react';
import React, { forwardRef, PropsWithChildren, RefObject, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { InputDropDown } from '../dropdown/InputDropDown';
import { InputDatePicker } from '../inputDatePicker/InputDatePicker';
import { InputPicker } from '../inputPicker/InputPicker';
import { InputPrice } from '../inputPrice/InputPrice';
import { InputTimeSpan } from '../inputTimeSpan/InputTimeSpan';
import {
    ControlledComponentProps,
    ControllerInputProps,
    FieldProps,
    FocusableComponent,
    InternalInputComponent,
    SupportedInputType,
} from './InputFieldTypes';
import { MaskedTextFieldStyled, TextFieldStyled } from './TextFieldStyled';

interface InternalInputProps<TInputType extends SupportedInputType = SupportedInputType> {
    errorMessage: string;
    type?: TInputType;
    fieldProps: FieldProps;
    internalProps: ControlledComponentProps<TInputType>;
    customField?: (props: ControllerInputProps) => JSX.Element;
    className?: string;
    required?: boolean;
    disabled?: boolean;
}

export const InputFieldSpacing = styled.div`
    max-width: 100%;
    min-width: 0;
    min-height: 82px;
    height: 82px;
    max-height: 82px;
    overflow-y: hidden;

    * {
        border-color: ${(props): string => props.theme.colors.secondary2};
    }
`;

function renderInput(
    type: string | unknown,
    fieldProps: ControllerInputProps,
    internalProps: ControlledComponentProps,
    inputRef: RefObject<InternalInputComponent>,
): JSX.Element {
    type = type ?? SupportedInputType.Text;
    if (
        type === SupportedInputType.Text ||
        type === SupportedInputType.Password ||
        type === SupportedInputType.Email ||
        type === SupportedInputType.TextArea ||
        type === SupportedInputType.Number
    ) {
        return <TextFieldStyled {...fieldProps} {...internalProps} type={type} componentRef={inputRef} />;
    }
    if (type === SupportedInputType.MaskedInput) {
        return <MaskedTextFieldStyled {...fieldProps} {...internalProps} componentRef={inputRef} />;
    }
    if (type === SupportedInputType.Picker) {
        return <InputPicker {...fieldProps} {...(internalProps as ControlledComponentProps<SupportedInputType.Picker>)} />;
    }

    if (type === SupportedInputType.DropDown) {
        return <InputDropDown {...fieldProps} {...(internalProps as ControlledComponentProps<SupportedInputType.DropDown>)} />;
    }

    if (type === SupportedInputType.Price) {
        return <InputPrice {...fieldProps} {...(internalProps as ControlledComponentProps<SupportedInputType.Price>)} />;
    }

    if (type === SupportedInputType.TimeSpan) {
        return <InputTimeSpan {...fieldProps} {...(internalProps as ControlledComponentProps<SupportedInputType.TimeSpan>)} />;
    }

    if (type === SupportedInputType.DatePicker) {
        return <InputDatePicker {...fieldProps} {...(internalProps as ControlledComponentProps<SupportedInputType.DatePicker>)} />;
    }

    if (type === SupportedInputType.Toggle) {
        return (
            <Toggle
                {...fieldProps}
                onChange={(_event: unknown, isChecked?: boolean): void => fieldProps.onChange(Boolean(isChecked))}
                {...(internalProps as ControlledComponentProps<SupportedInputType.Toggle>)}
            />
        );
    }

    return <div>NA</div>;
}

const TextAreaFieldSpacing = styled(InputFieldSpacing)`
    min-height: 92px;
    height: unset;
    max-height: unset;
`;

export const ControlledInputField = forwardRef<FocusableComponent, InternalInputProps>(
    (props: PropsWithChildren<InternalInputProps>, ref): JSX.Element => {
        const inputRef: RefObject<InternalInputComponent> = useRef<InternalInputComponent>(null);
        const { t } = useTranslation();

        useImperativeHandle(ref, () => ({
            focus: (): void => {
                inputRef.current?.focus();
            },
        }));

        const onFocus = (): void => {
            inputRef?.current?.focus();
        };

        const fieldProps = {
            ...props.fieldProps,
            onFocus: onFocus,
            errorMessage: t(props.errorMessage ? `ERRORS.${props.errorMessage}` : ``),
            className: props.className,
            required: props.required,
            disabled: props.disabled,
        };

        if (props.type === SupportedInputType.Custom && props.customField) {
            return props.customField(fieldProps);
        }

        if (props.type === SupportedInputType.TextArea) {
            return (
                <TextAreaFieldSpacing>
                    <TextFieldStyled {...fieldProps} {...props.internalProps} multiline type="text" componentRef={inputRef} />{' '}
                </TextAreaFieldSpacing>
            );
        }

        return <InputFieldSpacing>{renderInput(props.type, fieldProps, props.internalProps, inputRef)}</InputFieldSpacing>;
    },
);
