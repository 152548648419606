import { initializeIcons } from '@fluentui/react';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './componentsUx/icons/AppIcons';
import './componentsUx/theme/loadTheme';
import { initAppLanguage } from './i18n';
import './index.scss';
import './polyfills';
import * as serviceWorker from './serviceWorker';

initializeIcons();

const bootstrap = async (): Promise<void> => {
    await initAppLanguage();

    ReactDOM.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>,
        document.getElementById('root'),
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
};

bootstrap().catch((err) => console.error(`App bootstrap failed`, err));
