import React from 'react';
import { Separator, createTheme, ITheme } from '@fluentui/react';

const smallSeparatorTheme: ITheme = createTheme({
    fonts: {
        medium: {
            fontSize: '15px',
            fontWeight: 'bold',
        },
    },
});

export function ReportFilterVariableSeparator(props: { description?: string }): JSX.Element {
    return (
        <Separator alignContent="start" theme={smallSeparatorTheme}>
            {props.description}
        </Separator>
    );
}
