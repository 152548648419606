import { TextField, MaskedTextField } from '@fluentui/react';
import styled from 'styled-components';

export const TextFieldStyled = styled(TextField)`
    .ms-TextField-wrapper {
        min-width: 0;
        overflow: hidden;

        .ms-Label {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .ms-TextField {
        .ms-TextField-errorMessage {
            min-width: 0;
            overflow: hidden;

            span {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }
`;
export const MaskedTextFieldStyled = styled(MaskedTextField)`
    .ms-TextField-wrapper {
        min-width: 0;
        overflow: hidden;

        .ms-Label {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .ms-TextField {
        .ms-TextField-errorMessage {
            min-width: 0;
            overflow: hidden;

            span {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }
`;
