import { AxiosRequestConfig } from 'axios';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';
import { useShowError } from '../../componentsUx/toast/toastState';
import { APP_PATHS_FROM_ROOT } from '../../features/routing/commands';
import { useGlobalLoader } from '../../state/GlobalLoaderState';
import { SessionData } from '../../state/models/sessionData';
import { SessionState } from '../../state/SessionState';
import { generateUuid } from '../../utils/generateUuid';
import { parseError } from '../../utils/parseError';
import { httpClient } from './index';
import { createAuthHeaderInterceptor, createApiRequestUrlInterceptor, createParseDatesInResponseInterceptor } from './interceptors';

const FP_REQUEST_ID = `Fp-Request-Id`;

export function DataAccessSetup(): JSX.Element {
    const [sessionData, setSessionData] = useRecoilState(SessionState);
    const globalLoader = useGlobalLoader();
    const showToastError = useShowError();
    const history = useHistory();

    useEffect(() => {
        httpClient.interceptors.request.use(createApiRequestUrlInterceptor());
    }, []);

    // Track each request with a loader and handle errors
    useEffect(() => {
        const globalProgressId = `GLOBAL_LOADER-${generateUuid()}`;

        const onError = (err: { config: AxiosRequestConfig; response: { data: any }; message: string }): any => {
            // Check explicitly for 403 errors
            if (err.message === 'Request failed with status code 403') {
                setSessionData(new SessionData({ isExpired: true, token: '' }));
                history.push(APP_PATHS_FROM_ROOT.auth());
            }
            const error = parseError(err);
            showToastError({ infoMessage: error });
            globalLoader.markNotLoading(globalProgressId);
            return Promise.reject(err);
        };

        const requestInterceptorId = httpClient.interceptors.request.use((config) => {
            config.headers[FP_REQUEST_ID] = generateUuid();
            globalLoader.markLoading(globalProgressId);
            return config;
        }, onError);
        const responseInterceptorId = httpClient.interceptors.response.use((config) => {
            globalLoader.markNotLoading(globalProgressId);
            if (config?.data?.wasSuccessful === false) {
                showToastError({ infoMessage: config.data.message });
                return Promise.reject(config.data);
            }
            return config;
        }, onError);

        return (): void => {
            httpClient.interceptors.request.eject(requestInterceptorId);
            httpClient.interceptors.response.eject(responseInterceptorId);
            globalLoader.detachLoader(globalProgressId);
        };
    }, [globalLoader, showToastError, history, setSessionData]);

    // Add auth interceptor based on session data
    useEffect(() => {
        const authInterceptorId = httpClient.interceptors.request.use(createAuthHeaderInterceptor(sessionData));
        return (): void => {
            httpClient.interceptors.request.eject(authInterceptorId);
        };
    }, [sessionData]);

    // Add interceptor which takes the JSON response and traverses it to transform date strings into date objects.
    useEffect(() => {
        const dateParsingInterceptor = httpClient.interceptors.response.use(createParseDatesInResponseInterceptor());
        return (): void => {
            httpClient.interceptors.response.eject(dateParsingInterceptor);
        };
    }, []);

    return <></>;
}
