import { BaseApiModel } from '../../common/baseApiModel';

export enum ReportVariableType {
    Boolean,
    Numeric,
    DateTime,
    Date,
    Time,
    String,
}

export interface ReportVariableApiModel extends BaseApiModel {
    type: ReportVariableType;
    order: number;
    isMandatory: boolean;
    name?: string;
}
