import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { CourseProgramEditor } from '../../../componentsBusiness/courseProgramEditor';
import { Breadcrumb } from '../../../componentsUx/breadcrumb/Breadcrumb';
import { device } from '../../../componentsUx/cssUtils/breakpoints';
import { APP_PATHS_FROM_ROOT } from '../../routing/commands';
import { CourseCompetenciesListCard } from '../courseCompetency/CourseCompetenciesListCard';

const CourseProgramGrid = styled.div`
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    @media ${device.featurePhone} {
        grid-template-areas: 'courseProgramCard' 'competenciesListCard';
        grid-template-columns: minmax(0, 1fr);
    }

    @media ${device.tabletLandscape} {
        grid-template-columns: 1fr minmax(0, 2fr);
        grid-template-areas: 'courseProgramCard competenciesListCard';
    }
`;

const GridCard = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 0;
`;

const CourseProgramCardArea = styled(GridCard)`
    grid-area: courseProgramCard;
`;

const CourseCompetenciesArea = styled(GridCard)`
    grid-area: competenciesListCard;
`;

export function CourseProgram(): JSX.Element {
    const { t } = useTranslation();
    const history = useHistory();
    const { id, branchId } = useParams<{ id: string; branchId: string }>();

    const isInAddMode = id === 'new';

    const breadcrumbItems = [
        {
            text: t('HOME_PAGE_NAV_ACTION_COMPANY'),
            key: 'HOME_PAGE_NAV_ACTION_COMPANY',
            onClick: (): void => history.push(APP_PATHS_FROM_ROOT.companyPage()),
        },
        {
            text: t('BRANCH_CARD_TITLE'),
            key: 'BRANCH_CARD_TITLE',
            onClick: (): void => history.push(APP_PATHS_FROM_ROOT.branchPage(branchId)),
        },
        {
            text: t('COURSE_PROGRAM_CARD_TITLE'),
            key: 'COURSE_PROGRAM_CARD_TITLE',
            isCurrentItem: true,
        },
    ];

    return (
        <>
            <Breadcrumb items={breadcrumbItems} />
            <CourseProgramGrid>
                <CourseProgramCardArea>
                    <CourseProgramEditor courseProgramId={id} isAddMode={id === 'new'} branchId={branchId} />
                </CourseProgramCardArea>
                <CourseCompetenciesArea>
                    {isInAddMode || <CourseCompetenciesListCard branchId={branchId} courseProgramId={id} />}
                </CourseCompetenciesArea>
            </CourseProgramGrid>
        </>
    );
}
