import { defaultsDeep, omit, pick } from 'lodash';
import * as yup from 'yup';
import { Amount, FormFieldValues, PickerFieldValue } from '../../features/common/baseApiModel';
import { SeriesListItemApiModel } from '../../features/home/serie/seriesApiModel';
import { PaymentForm, StudentProgramAttendanceApiModel } from '../../features/home/studentSeriesAttendance/studentProgramAttendanceApiModel';
import {
    CreateOrUpdateAttendanceApiModel,
    StudentSeriesAttendanceApiResponse,
} from '../../features/home/studentSeriesAttendance/studentSeriesAttendance.service';
import { StudentApiModel } from '../../models/studentApiModel';
import { ErrorCode } from '../../services/validators/errors.util';
import { stringValidator } from '../../services/validators/stringValidator';
import { numberValidator } from '../../services/validators/numberValidator';
import { dateValidator } from '../../services/validators/dateValidator';
import { enumValuesValidator } from '../../services/validators/enumValuesValidator';
import { requiredPickerValue } from '../../services/validators/pickerValueValidator';

// Form validated data model
export type ValidFormValues = {
    comments: string;
    wasAbsent: boolean;
    abandoned: boolean;
    paymentForm: PaymentForm;
    price: Amount;
    contractNumber: string;
    contractDate: Date;
    isInstallmentable: boolean;
    // Dependent on isInstallmentable
    installmentableDependent: {
        numberOfInstallments: number;
        firstInstallmentDate: Date;
        dayOfInstallment: number;
    };
    notes: string;
    certificateNumber: string;
    certificateSeries: string;
    certificateSeriesNumber: string;
    provider: string;
    providerCity: string;
    startCoursesDate: Date;
    endCoursesDate: Date;
    graduationDate: Date;
    student: PickerFieldValue<StudentApiModel>;
    series: PickerFieldValue<SeriesListItemApiModel>;
};

// Form data model which can contain invalid values for patching the object
export type FormValues = FormFieldValues<ValidFormValues>;

export const FormSchema = {
    fields: {
        wasAbsent: yup.boolean(),
        abandoned: yup.boolean(),
        comments: stringValidator(),
        paymentForm: enumValuesValidator({ enumRef: PaymentForm, isNumericEnum: true }),
        price: yup.object().shape({
            value: numberValidator(),
            currency: stringValidator(),
        }),
        contractNumber: stringValidator(),
        contractDate: dateValidator(),
        isInstallmentable: yup.boolean(),
        installmentableDependent: yup.object().when('isInstallmentable', {
            is: true,
            then: yup.object().shape({
                numberOfInstallments: numberValidator({ isRequired: true }),
                firstInstallmentDate: dateValidator({ isRequired: true }),
                dayOfInstallment: numberValidator({ isRequired: true }),
            }),
            otherwise: yup.object(),
        }),
        notes: stringValidator(),
        certificateNumber: stringValidator(),
        certificateSeries: stringValidator(),
        certificateSeriesNumber: stringValidator(),
        provider: stringValidator(),
        providerCity: stringValidator(),
        startCoursesDate: yup.date().min(yup.ref('startCoursesDate'), ErrorCode.StartDateBeforeEndDate),
        endCoursesDate: yup.date().min(yup.ref('startCoursesDate'), ErrorCode.StartDateBeforeEndDate),
        graduationDate: dateValidator(),
        student: requiredPickerValue(),
        series: requiredPickerValue(),
    },
    dependencies: [['startCoursesDate', 'endCoursesDate']],
};

// Form schema enforcing ValidFormValues validation rules
export const StudentSeriesAttendanceSchema = yup.object().shape(FormSchema.fields, FormSchema.dependencies as Array<[string, string]>);

export function defaultMissingValues(data?: Partial<FormValues> | null): FormValues {
    return defaultsDeep(data ?? {}, {
        student: {},
        series: {},
        abandoned: false,
        comments: '',
        wasAbsent: false,
        certificateNumber: '',
        certificateSeries: '',
        certificateSeriesNumber: '',
        contractNumber: '',
        endCoursesDate: null,
        graduationDate: null,
        notes: '',
        paymentForm: '',
        price: { currency: '', value: '' },
        contractDate: null,
        provider: '',
        providerCity: '',
        startCoursesDate: null,
        isInstallmentable: false,
        installmentableDependent: {
            numberOfInstallments: '',
            dayOfInstallment: '',
            firstInstallmentDate: '',
        },
    });
}

// Mapping from StudentSeriesAttendanceApiResponse -> FormValues
export function mapStudentSeriesAttendanceToFormData(input: Partial<StudentSeriesAttendanceApiResponse> | null): FormValues {
    input = input ?? {};
    const studentProgramAttendance: Partial<StudentProgramAttendanceApiModel> = input.studentProgramAttendance ?? {};
    return defaultMissingValues({
        student: input.student ?? {},
        series: input.series ?? {},
        ...pick(input, ['abandoned', 'comments', 'wasAbsent']),
        installmentableDependent: {
            numberOfInstallments: studentProgramAttendance.numberOfInstallments ?? '',
            dayOfInstallment: studentProgramAttendance.dayOfInstallment ?? '',
            firstInstallmentDate: studentProgramAttendance.firstInstallmentDate ?? '',
        },
        ...pick(studentProgramAttendance, [
            'certificateNumber',
            'certificateSeries',
            'certificateSeriesNumber',
            'contractNumber',
            'notes',
            'paymentForm',
            'price',
            'provider',
            'providerCity',
        ]),
        contractDate: studentProgramAttendance.contractDate ?? undefined,
        endCoursesDate: studentProgramAttendance.endCoursesDate ?? undefined,
        graduationDate: studentProgramAttendance.graduationDate ?? undefined,
        startCoursesDate: studentProgramAttendance.startCoursesDate ?? undefined,
    });
}

// Mapping from ValidFormValues -> StudentSeriesAttendanceApiResponse
export function mapFormDataToStudentSeriesAttendance(objectId: string | undefined, input: ValidFormValues): CreateOrUpdateAttendanceApiModel {
    return {
        id: objectId,
        ...pick(input, ['comments', 'wasAbsent', 'abandoned']),
        // The following properties are required in the form schema
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        seriesId: input.series!.id,
        // The following properties are required in the form schema
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        studentId: input.student!.id,
        studentProgramAttendance: {
            ...omit(input, ['comments', 'wasAbsent', 'abandoned', 'installmentableDependent']),
            ...input.installmentableDependent,
        },
    };
}
