export const PATHS = {
    auth: 'auth',
    login: 'login',
    home: 'home',
    dashboard: 'dashboard',
    students: 'students',
    student: 'student',
    reports: 'reports',
    report: 'report',
    company: 'company',
    branch: 'branch',
    branchStaffMember: 'branchStaffMember',
    series: 'series',
    courseProgram: 'courseProgram',
    courseCompetency: 'courseCompetency',
    studentSeriesAttendance: 'studentSeriesAttendance',
};

export const CreateEntityId = 'new';

export function isNewEntityId(id: string): boolean {
    return id === CreateEntityId;
}

export const APP_PATHS_FROM_ROOT = {
    home: (): string => `/${PATHS.home}`,
    dashboard: (): string => `/${PATHS.home}/${PATHS.dashboard}`,
    students: (): string => `/${PATHS.home}/${PATHS.students}`,
    student: (studentId: string): string => `/${PATHS.home}/${PATHS.student}/${studentId}`,
    newStudent: (): string => `/${PATHS.home}/${PATHS.student}/${CreateEntityId}`,
    newStudentSeriesAttendanceFromSerie: (): string => `/${PATHS.home}/${PATHS.studentSeriesAttendance}/serie/new`,
    editStudentSeriesAttendanceFromSerie: (attendanceId: string): string => `/${PATHS.home}/${PATHS.studentSeriesAttendance}/serie/${attendanceId}`,
    newStudentSeriesAttendanceFromStudent: (): string => `/${PATHS.home}/${PATHS.studentSeriesAttendance}/student/new`,
    editStudentSeriesAttendanceFromStudent: (attendanceId: string): string =>
        `/${PATHS.home}/${PATHS.studentSeriesAttendance}/student/${attendanceId}`,
    auth: (): string => `/${PATHS.auth}/${PATHS.login}`,
    companyPage: (): string => `/${PATHS.home}/${PATHS.company}`,
    branchPage: (branchId: string): string => `/${PATHS.home}/${PATHS.branch}/${branchId}`,
    branchStaffMemberPage: (branchStaffMemberId: string, branchId: string): string =>
        `/${PATHS.home}/${PATHS.branchStaffMember}/${branchStaffMemberId}/${branchId}`,
    seriesPage: (serieId: string): string => `/${PATHS.home}/${PATHS.series}/${serieId}`,
    newBranchPage: (): string => `/${PATHS.home}/${PATHS.branch}/${CreateEntityId}`,
    newBranchStaffMemberPage: (branchId: string): string => `/${PATHS.home}/${PATHS.branchStaffMember}/${CreateEntityId}/${branchId}`,
    newSeriesPage: (): string => `/${PATHS.home}/${PATHS.series}/${CreateEntityId}`,
    courseProgramPage: (branchId: string, courseProgramId: string): string => `/${PATHS.home}/${PATHS.courseProgram}/${courseProgramId}/${branchId}`,
    newCourseProgramPage: (branchId: string): string => `/${PATHS.home}/${PATHS.courseProgram}/${CreateEntityId}/${branchId}`,
    courseCompetencyPage: (branchId: string, courseProgramId: string, courseCompetencyId: string): string =>
        `/${PATHS.home}/${PATHS.courseCompetency}/${courseCompetencyId}/${courseProgramId}/${branchId}`,
    newCourseCompetencyPage: (branchId: string, courseProgramId: string): string =>
        `/${PATHS.home}/${PATHS.courseCompetency}/${CreateEntityId}/${courseProgramId}/${branchId}`,
    reportsPage: (): string => `/${PATHS.home}/${PATHS.reports}`,
    reportPage: (reportId: string): string => `/${PATHS.home}/${PATHS.report}/${reportId}`,
    generatedReportPage: (reportId: string, generatedReportId: string): string => `/${PATHS.home}/${PATHS.report}/${reportId}/${generatedReportId}`,
    reports: (): string => `/${PATHS.home}/${PATHS.reports}`,
};
