import React from 'react';
import { useRouteMatch, Route } from 'react-router-dom';
import { PATHS } from '../routing/commands';
import { Branch } from './branch/Branch';
import { Company } from './company/Company';
import { CourseCompetency } from './courseCompetency/CourseCompetency';
import { CourseProgram } from './courseProgram/CourseProgram';
import { Dashboard } from './dashboard/Dashboard';
import { Report } from './report/Report';
import { ReportsList } from './report/ReportsList';
import { Series } from './serie/Series';
import { StudentDetails } from './students/studentDetails/StudentDetails';
import { Students } from './students/studentsList/Students';
import { StudentSeriesAttendanceFromSerie, StudentSeriesAttendanceFromStudent } from './studentSeriesAttendance';
import { BranchStaffMember } from './branchStaffMember/BranchStaffMember';

export function HomeRouting(): JSX.Element {
    const match = useRouteMatch();

    return (
        <>
            <Route path={`${match?.path}/${PATHS.dashboard}`} component={Dashboard} />
            <Route path={`${match?.path}/${PATHS.students}`} component={Students} />
            <Route path={`${match?.path}/${PATHS.studentSeriesAttendance}/student/:id`} component={StudentSeriesAttendanceFromStudent} />
            <Route path={`${match?.path}/${PATHS.studentSeriesAttendance}/serie/:id`} component={StudentSeriesAttendanceFromSerie} />
            <Route path={`${match?.path}/${PATHS.student}/:id`} component={StudentDetails} />
            <Route path={`${match?.path}/${PATHS.company}`} component={Company} />
            <Route path={`${match?.path}/${PATHS.branch}/:id`} component={Branch} />
            <Route path={`${match?.path}/${PATHS.branchStaffMember}/:id/:branchId?`} component={BranchStaffMember} />
            <Route path={`${match?.path}/${PATHS.series}/:id`} component={Series} />
            <Route path={`${match?.path}/${PATHS.courseProgram}/:id/:branchId?`} component={CourseProgram} />
            <Route path={`${match?.path}/${PATHS.courseCompetency}/:id/:courseProgramId/:branchId?`} component={CourseCompetency} />
            <Route path={`${match?.path}/${PATHS.reports}`} component={ReportsList} />
            <Route path={`${match?.path}/${PATHS.report}/:id/:generatedReportId?`} component={Report} />
        </>
    );
}
