import React, { useEffect } from 'react';
import { atom, useRecoilState, SetterOrUpdater } from 'recoil';
import { persistenceService } from '../services/persistence.service';
import { SessionDataProps, SessionData } from './models/sessionData';

const key = 'persistence-session-data';

const sessionInitialValue = persistenceService.getItem<SessionDataProps, SessionData>(key, (data) => {
    return new SessionData(data);
}) as SessionData;

export const SessionState = atom({
    key,
    default: sessionInitialValue,
});

export const useSession = (): [SessionData, SetterOrUpdater<SessionData>] => {
    return useRecoilState(SessionState);
};

export function SessionPersistenceSetup(): JSX.Element {
    const [sessionData] = useRecoilState(SessionState);

    useEffect(() => {
        persistenceService.setItem(key, sessionData.toJson());
    }, [sessionData]);
    return <></>;
}
