import { IDropdownOption } from '@fluentui/react';

export enum DateToFilter {
    StartDate,
    EndDate,
    ExamDate,
}

export const DateToFilterValue = {
    [DateToFilter.StartDate]: 'SERIES_LIST_CARD_FIELD_START_DATE',
    [DateToFilter.EndDate]: 'SERIES_LIST_CARD_FIELD_END_DATE',
    [DateToFilter.ExamDate]: 'SERIES_LIST_CARD_FIELD_EXAM_DATE',
};

export function getDateToFilterOptions(t: (rawText: string) => string): IDropdownOption[] {
    return [
        { key: DateToFilter.StartDate, text: t(DateToFilterValue[DateToFilter.StartDate]) },
        { key: DateToFilter.EndDate, text: t(DateToFilterValue[DateToFilter.EndDate]) },
        { key: DateToFilter.ExamDate, text: t(DateToFilterValue[DateToFilter.ExamDate]) },
    ];
}
