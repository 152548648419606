import { SeriesListItemApiModel } from '../features/home/serie/seriesApiModel';

export type ConvertableToString = null | undefined | { toString(): string };

export function convertToString(value: ConvertableToString): string {
    if (value === null || value === undefined) {
        return '';
    }

    return value.toString();
}

export function convertSeriesToString(value: SeriesListItemApiModel): string {
    if (value?.specializationName === null || value?.specializationName === undefined) {
        return '';
    }

    return `${value.specializationName}${value.seriesNumber ? ' ' + value.seriesNumber : ''}`;
}
