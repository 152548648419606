import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactProps } from '../../utils/types';
import { Auth } from '../auth/Auth';
import { Home } from '../home/Home';
import { PATHS } from './commands';
import { NoMatch } from './NoMatch';
import { UserAuthGuard } from './UserAuthGuard';

export function Routing(props: ReactProps): JSX.Element {
    return (
        <Router>
            <QueryParamProvider ReactRouterRoute={Route}>
                {props.children}
                <Switch>
                    <Route path={`/${PATHS.auth}`}>
                        <Auth />
                    </Route>
                    <UserAuthGuard path={`/${PATHS.home}`}>
                        <Home />
                    </UserAuthGuard>
                    <Route path="*">
                        <NoMatch />
                    </Route>
                </Switch>
            </QueryParamProvider>
        </Router>
    );
}
