import React from 'react';
import styled from 'styled-components';
import { ReactProps } from '../../utils/types';
import { device } from '../cssUtils/breakpoints';
import { CssGlobals } from '../cssUtils/variables';

const PageContentSizeBase = styled.div`
    width: 100%;
    min-width: 0;
    display: flex;
    flex-direction: column;
`;

const PageContentSize = styled(PageContentSizeBase)`
    @media ${device.featurePhone} {
        padding: ${CssGlobals.pagePadding.smallScreen}px;
        max-width: ${CssGlobals.maxContentWidth + 2 * CssGlobals.pagePadding.smallScreen}px;
    }
    @media ${device.tabletPortrait} {
        padding: ${CssGlobals.pagePadding.mediumScreen}px;
        max-width: ${CssGlobals.maxContentWidth + 2 * CssGlobals.pagePadding.mediumScreen}px;
    }
    @media ${device.desktop} {
        padding: ${CssGlobals.pagePadding.largeScreen}px;
        max-width: ${CssGlobals.maxContentWidth + 2 * CssGlobals.pagePadding.largeScreen}px;
    }
`;

const PageContentContainer = styled.div`
    display: flex;
    min-width: 0;
    flex-direction: column;
`;

// Page content which adds the padding on top-left-right-bottom and specifies page size
export function PageContent(props: ReactProps): JSX.Element {
    return (
        <PageContentSize>
            <PageContentContainer>{props.children}</PageContentContainer>
        </PageContentSize>
    );
}

const PageContentSizeLeftRight = styled(PageContentSizeBase)`
    @media ${device.featurePhone} {
        padding: 0 ${CssGlobals.pagePadding.smallScreen}px;
        max-width: ${CssGlobals.maxContentWidth + 2 * CssGlobals.pagePadding.smallScreen}px;
    }
    @media ${device.tabletPortrait} {
        padding: 0 ${CssGlobals.pagePadding.mediumScreen}px;
        max-width: ${CssGlobals.maxContentWidth + 2 * CssGlobals.pagePadding.mediumScreen}px;
    }
    @media ${device.desktop} {
        padding: 0 ${CssGlobals.pagePadding.largeScreen}px;
        max-width: ${CssGlobals.maxContentWidth + 2 * CssGlobals.pagePadding.largeScreen}px;
    }
`;

// Page content which adds the padding on left-right and specifies page size
export function PageContentLeftRight(props: ReactProps): JSX.Element {
    return (
        <PageContentSizeLeftRight>
            <PageContentContainer>{props.children}</PageContentContainer>
        </PageContentSizeLeftRight>
    );
}
