import { defaultsDeep } from 'lodash';
import { AddressApiModel } from '../../address/addressApiModel';
import { BaseApiModel } from '../../common/baseApiModel';
import { PaginatedOutput } from '../../common/paginatedApiOutput';

export interface BranchApiModel extends BaseApiModel {
    name: string;
    email?: string;
    phoneNumber?: string;
    companyId: string;
}

export function defaultBranchMissingValues(branch?: Partial<BranchApiModel> | null): BranchApiModel {
    return defaultsDeep(branch ?? {}, {
        id: '',
        name: '',
        companyId: '',
        email: '',
        phoneNumber: '',
    });
}

export declare namespace BranchApiModelRelations {
    export interface Address<AddressRelations = unknown> {
        address: AddressApiModel & AddressRelations;
    }
}

export interface BranchListItemApiModel extends BaseApiModel {
    name: string;
    city?: string;
    street?: string;
    number?: string;
}

export type BranchListApiModel = PaginatedOutput<BranchListItemApiModel>;
