export enum StudiesType {
    //Studii Generale, 8 clase
    General = 0,
    //Studii Generale, 10 clase
    Medium = 1,
    //Studii Profesionale
    Proffesional = 2,
    //Liceu (fara Bac)
    HighSchool = 3,
    //Liceu (cu Bac)
    HighSchoolWithExamDiploma = 4,
    //Studii Superioare
    Superior = 5,
    //Master
    Master = 6,
}
