import { defaultsDeep } from 'lodash';
import * as yup from 'yup';
import { Amount, FormFieldValues, PickerFieldValue } from '../../features/common/baseApiModel';
import { CourseProgramApiModel, CourseProgramApiModelRelations } from '../../features/home/courseProgram/courseProgramApiModel';
import { SpecializationApiModel } from '../../features/home/specialization/specializationApiModel';
import { dateValidator } from '../../services/validators/dateValidator';
import { ErrorCode } from '../../services/validators/errors.util';
import { numberValidator } from '../../services/validators/numberValidator';

export type CourseProgramModel = CourseProgramApiModel & CourseProgramApiModelRelations.Specialization;
export type ValidFormValues = Omit<
    CourseProgramModel,
    'id' | 'branchId' | 'basePrice' | 'specialization' | 'specializationName' | 'specializationCode'
> & {
    basePrice: PickerFieldValue<Amount>;
    specialization: PickerFieldValue<SpecializationApiModel>;
};
export type FormValues = FormFieldValues<ValidFormValues>;

export const CourseProgramFormSchema = yup.object().shape<FormValues>({
    type: yup.number().required(ErrorCode.Required),
    specialization: yup.object(),
    authorization: yup.string().required(ErrorCode.Required),
    secretary: yup.string(),
    basePrice: yup.object(),
    registerNumber: yup.string(),
    timeSpan: yup.number(),
    authorizationEmittedDate: dateValidator(),
    totalPracticeHours: numberValidator(),
    totalTheoreticHours: numberValidator(),
});

export function defaultMissingCourseProgramValues(data?: Partial<FormValues> | null): FormValues {
    return defaultsDeep(data ?? {}, {
        name: '',
        type: '',
        specialization: {},
        authorization: '',
        secretary: '',
        basePrice: {
            value: '',
            currency: '',
        },
        registerNumber: '',
        timeSpan: '',
        authorizationEmittedDate: '',
        totalPracticeHours: '',
        totalTheoreticHours: '',
    });
}
