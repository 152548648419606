import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { Card } from '../../../componentsUx/card/Card';
import { CardActions } from '../../../componentsUx/card/CardActions';
import { CardTitle } from '../../../componentsUx/card/CardTitle';
import { InputField } from '../../../componentsUx/input/InputField';
import { SupportedInputType } from '../../../componentsUx/input/InputFieldTypes';
import { SubmitButton } from '../../../componentsUx/input/SubmitButton';
import { useShowInfo } from '../../../componentsUx/toast/toastState';
import { branchStaffMemberService } from '../../../services/dataAccess';
import { enumValuesValidator } from '../../../services/validators/enumValuesValidator';
import { stringValidator } from '../../../services/validators/stringValidator';
import { useEffectAsync } from '../../../utils/useEffectAsync';
import { useFpForm, setFormValues } from '../../../utils/useFormHook';
import { ContributorType } from '../../common/contributorType';
import { APP_PATHS_FROM_ROOT } from '../../routing/commands';
import { BranchStaffMemberApiModel, emptyBranchStaffMember } from './branchStaffMemberApiModel';

type BranchStaffMemberFormFields = Omit<BranchStaffMemberApiModel, 'id'>;

const BranchStaffMemberFormSchema = yup.object().shape<BranchStaffMemberFormFields>({
    firstName: stringValidator({ isRequired: true }),
    lastName: stringValidator({ isRequired: true }),
    contributorType: enumValuesValidator({ enumRef: ContributorType, isNumericEnum: true }),
    socialSecurityNumber: stringValidator({ isRequired: true }),
});

interface BranchStaffMemberCardProps {
    branchStaffMemberId: string;
    branchId: string;
}

export function BranchStaffMemberCard(props: BranchStaffMemberCardProps): JSX.Element {
    const { branchStaffMemberId, branchId } = props;

    const { t } = useTranslation();
    const [branchStaffMember, setBranchStaffMember] = useState<Partial<BranchStaffMemberApiModel> | null>(null);

    const isAddMode = branchStaffMemberId === 'new';

    const history = useHistory();

    const { control, formState, handleSubmit, setValue } = useFpForm<BranchStaffMemberFormFields>({
        schema: BranchStaffMemberFormSchema,
        defaultValues: emptyBranchStaffMember(),
    });
    const showToastInfo = useShowInfo();

    useEffectAsync(
        async ({ canceled }) => {
            const branchStaffMemberDetails = isAddMode
                ? emptyBranchStaffMember()
                : await branchStaffMemberService.getBranchStaffMember(branchStaffMemberId);
            if (canceled) {
                return;
            }
            setBranchStaffMember(branchStaffMemberDetails);
            setFormValues(branchStaffMemberDetails, setValue);
        },
        [setValue, branchStaffMemberId, isAddMode],
    );

    async function saveBranchStaffMemberDetails(params: BranchStaffMemberFormFields): Promise<void> {
        if (!branchStaffMember) {
            return;
        }
        const branchStaffMemberData = {
            id: branchStaffMemberId,
            branchId: branchId,
            ...params,
        };
        let newBranchStaffMemberId: string | null = null;

        if (isAddMode) {
            newBranchStaffMemberId = await branchStaffMemberService.addBranchStaffMember(branchStaffMemberData);
        } else {
            newBranchStaffMemberId = await branchStaffMemberService.updateBranchStaffMember(branchStaffMemberData);
        }

        showToastInfo({ infoMessage: t('BRANCH_CARD_SAVE_SUCCESFULLY') });
        if (newBranchStaffMemberId) {
            history.push(APP_PATHS_FROM_ROOT.branchPage(branchId));
        }
    }

    return (
        <Card>
            <CardTitle>{t('BRANCH_STAFF_MEMBER_CARD_TITLE')}</CardTitle>
            <form onSubmit={handleSubmit(saveBranchStaffMemberDetails)}>
                <InputField
                    control={control}
                    errors={formState.errors}
                    name="firstName"
                    required={true}
                    internalProps={{
                        label: t('BRANCH_STAFF_LIST_COLUMN_FIRST_NAME'),
                    }}
                />
                <InputField
                    control={control}
                    errors={formState.errors}
                    name="lastName"
                    required={true}
                    internalProps={{
                        label: t('BRANCH_STAFF_LIST_COLUMN_LAST_NAME'),
                    }}
                />
                <InputField
                    control={control}
                    errors={formState.errors}
                    name="socialSecurityNumber"
                    required={true}
                    internalProps={{
                        label: t('SOCIAL_SECURITY_NUMBER'),
                    }}
                />
                <InputField
                    control={control}
                    errors={formState.errors}
                    name="contributorType"
                    required={true}
                    type={SupportedInputType.DropDown}
                    internalProps={{
                        label: t('BRANCH_STAFF_LIST_COLUMN_CONTRIBUTOR_TYPE'),
                        dropDownEnumType: ContributorType,
                        isNumericEnum: true,
                    }}
                />

                <CardActions>
                    <SubmitButton label={t('FORM_SAVE')} />
                </CardActions>
            </form>
        </Card>
    );
}
