import { IColumn } from '@fluentui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../componentsUx/card/Card';
import { CardTitle } from '../../../componentsUx/card/CardTitle';
import { PaginatedList } from '../../../componentsUx/paginatedList/PaginatedList';
import { useListInput } from '../../../componentsUx/paginatedList/useListInput';
import { reportService } from '../../../services/dataAccess';
import { useEffectAsync } from '../../../utils/useEffectAsync';
import { createDefaultPaginatedOutput, PaginatedApiInput } from '../../common/paginatedApiOutput';
import { ReportFilterEntityType } from './filterPickers/reportFilterEntityType';
import { entityTypeToReportTypeMap, ReportListApiModel, ReportListItemApiModel } from './reportApiModel';

interface Props {
    entityName?: string;
    entityType?: ReportFilterEntityType;
    onReportClicked: (model: ReportListItemApiModel) => void;
}

export function ReportsListCard({ entityName, entityType, onReportClicked }: Props): JSX.Element {
    const [t] = useTranslation();
    const [listInput, setListInput] = useListInput({
        orderByProperty: 'name',
    });
    const [reportsList, setReportsList] = useState<ReportListApiModel>(createDefaultPaginatedOutput());

    useEffectAsync(
        async ({ canceled }) => {
            const reportsList = await reportService.readReports({ ...listInput, entityName, entityType });
            if (canceled) {
                return;
            }
            setReportsList(reportsList);
        },
        [listInput],
    );

    const commonColumnProps = {
        minWidth: 100,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: true,
    };

    const columns: IColumn[] = [
        {
            key: 'columnName',
            name: t('REPORT_LIST_COLUMN_NAME'),
            fieldName: 'name',
            ...commonColumnProps,
            maxWidth: 400,
        },
        {
            key: 'columnEntityType',
            name: t('REPORT_LIST_ENTITY_TYPE'),
            fieldName: 'entityType',
            ...commonColumnProps,
            onRender: (item: any): JSX.Element => {
                return t(entityTypeToReportTypeMap[item.entityType]);
            },
        },
    ];

    return (
        <Card>
            <CardTitle>{t('REPORT_LIST_TITLE')}</CardTitle>
            <PaginatedList
                entityType="Report"
                onItemClicked={onReportClicked}
                searchTextLabel={t('SEARCH_BY_NAME')}
                model={reportsList}
                pagination={listInput}
                onReadPage={(inputs: Required<PaginatedApiInput>): void => setListInput(inputs)}
                columns={columns}
            />
        </Card>
    );
}
