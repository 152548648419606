import { Stack } from '@fluentui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Card } from '../../componentsUx/card/Card';
import { CardActions } from '../../componentsUx/card/CardActions';
import { CardTitle } from '../../componentsUx/card/CardTitle';
import { InputFieldSpacing } from '../../componentsUx/input/ControlledInputField';
import { InputField } from '../../componentsUx/input/InputField';
import { SupportedInputType, ControllerInputProps } from '../../componentsUx/input/InputFieldTypes';
import { SubmitButton } from '../../componentsUx/input/SubmitButton';
import { InputPicker } from '../../componentsUx/inputPicker/InputPicker';
import { useShowInfo, useShowError } from '../../componentsUx/toast/toastState';
import { CourseType } from '../../features/common/courseType';
import { SpecializationApiModel } from '../../features/home/specialization/specializationApiModel';
import { APP_PATHS_FROM_ROOT } from '../../features/routing/commands';
import { courseProgramService } from '../../services/dataAccess';
import { useEffectAsync } from '../../utils/useEffectAsync';
import { useFpForm, setFormValues } from '../../utils/useFormHook';
import { CourseProgramModel, FormValues, CourseProgramFormSchema, defaultMissingCourseProgramValues, ValidFormValues } from './form.util';

export function CourseProgramEditor(parameters: { courseProgramId: string; isAddMode: boolean; branchId?: string }): JSX.Element {
    const { t } = useTranslation();
    const [courseProgram, setCourseProgram] = useState<Partial<CourseProgramModel> | null>(null);

    const history = useHistory();

    const { control, formState, handleSubmit, setValue } = useFpForm<FormValues>({
        schema: CourseProgramFormSchema,
        defaultValues: defaultMissingCourseProgramValues(courseProgram),
    });
    const showToastInfo = useShowInfo();
    const showToastError = useShowError();

    useEffectAsync(
        async ({ canceled }) => {
            const courseProgramDetails = parameters.isAddMode
                ? { name: '', courseType: CourseType.Initiation, authorization: '' }
                : await courseProgramService.getCourseProgram(parameters.courseProgramId);
            if (canceled) {
                return;
            }
            setCourseProgram(courseProgramDetails);
            setFormValues(defaultMissingCourseProgramValues(courseProgramDetails), setValue);
        },
        [setValue, parameters.courseProgramId, parameters.isAddMode],
    );

    async function saveCourseProgramDetails(params: ValidFormValues): Promise<void> {
        if (!courseProgram) {
            return;
        }
        const branchId = courseProgram.branchId ?? parameters.branchId;
        if (!branchId) {
            showToastError({ infoMessage: t('COURSE_PROGRAM_CARD_CREATE_UNSUCCESFULLY') });
            return;
        }
        const courseProgramData = {
            id: parameters.courseProgramId,
            branchId: branchId,
            specializationId: params.specialization.id,
            ...params,
        };

        const courseProgramId = parameters.isAddMode
            ? await courseProgramService.addCourseProgram(courseProgramData)
            : await courseProgramService.updateCourseProgram(courseProgramData);

        showToastInfo({ infoMessage: t('COURSE_PROGRAM_CARD_SAVE_SUCCESFULLY') });
        if (courseProgramId && courseProgramId !== parameters.courseProgramId) {
            history.push(APP_PATHS_FROM_ROOT.courseProgramPage(branchId, courseProgramId));
        }
    }

    return (
        <Card>
            <CardTitle>{t('COURSE_PROGRAM_CARD_TITLE')}</CardTitle>
            <form onSubmit={handleSubmit(saveCourseProgramDetails)}>
                <InputField
                    control={control}
                    errors={formState.errors}
                    name="type"
                    type={SupportedInputType.DropDown}
                    required={true}
                    internalProps={{
                        label: t('COURSE_PROGRAM_CARD_FIELD_TYPE'),
                        dropDownEnumType: CourseType,
                        isNumericEnum: true,
                    }}
                />

                <InputField
                    control={control}
                    errors={formState.errors}
                    name="specialization"
                    type={SupportedInputType.Custom}
                    required={true}
                    customField={(props: ControllerInputProps): JSX.Element => (
                        <Stack>
                            <InputFieldSpacing>
                                <InputPicker
                                    {...props}
                                    label={t('COURSE_PROGRAM_CARD_FIELD_SPECIALIZATION_NAME')}
                                    apiEndpoint="Specialization"
                                    allowFreeText={false}
                                    displayItem={(item: SpecializationApiModel): string => item?.name}
                                    renderItem={(item: SpecializationApiModel): JSX.Element => (
                                        <div>
                                            {item.name} ({t('COURSE_PROGRAM_CARD_FIELD_SPECIALIZATION_CODE')}: {item.code})
                                        </div>
                                    )}
                                />
                            </InputFieldSpacing>
                            <InputFieldSpacing>
                                <InputPicker
                                    {...props}
                                    label={t('COURSE_PROGRAM_CARD_FIELD_SPECIALIZATION_CODE')}
                                    apiEndpoint="Specialization"
                                    allowFreeText={false}
                                    displayItem={(item: SpecializationApiModel): string => item?.code}
                                    renderItem={(item: SpecializationApiModel): JSX.Element => (
                                        <div>
                                            {item.code} ({t('COURSE_PROGRAM_CARD_FIELD_SPECIALIZATION_NAME')}: {item.name})
                                        </div>
                                    )}
                                />
                            </InputFieldSpacing>
                        </Stack>
                    )}
                />

                <InputField
                    control={control}
                    errors={formState.errors}
                    name="authorization"
                    required={true}
                    type={SupportedInputType.MaskedInput}
                    internalProps={{
                        label: t('COURSE_PROGRAM_CARD_FIELD_AUTHORIZATION'),
                        mask: '** 999999',
                    }}
                />

                <InputField
                    control={control}
                    errors={formState.errors}
                    type={SupportedInputType.DatePicker}
                    name="authorizationEmittedDate"
                    internalProps={{
                        label: t('COURSE_PROGRAM_CARD_FIELD_AUTHORIZATION_EMITTED_DATE'),
                    }}
                />
                <InputField
                    control={control}
                    errors={formState.errors}
                    name="secretary"
                    internalProps={{
                        label: t('COURSE_PROGRAM_CARD_FIELD_SECRETARY'),
                    }}
                />
                <InputField
                    control={control}
                    errors={formState.errors}
                    name="basePrice"
                    type={SupportedInputType.Price}
                    internalProps={{
                        label: t('COURSE_PROGRAM_CARD_FIELD_BASE_PRICE'),
                        labelSecond: t('COURSE_PROGRAM_CARD_FIELD_BASE_PRICE_CURRENCY'),
                    }}
                />
                <InputField
                    control={control}
                    errors={formState.errors}
                    name="registerNumber"
                    internalProps={{
                        label: t('COURSE_PROGRAM_CARD_FIELD_REGISTER_NUMBER'),
                    }}
                />
                <InputField
                    control={control}
                    errors={formState.errors}
                    type={SupportedInputType.Number}
                    name="totalTheoreticHours"
                    internalProps={{
                        label: t('COURSE_PROGRAM_CARD_FIELD_TOTAL_THEORETIC_HOURS'),
                    }}
                />
                <InputField
                    control={control}
                    errors={formState.errors}
                    type={SupportedInputType.Number}
                    name="totalPracticeHours"
                    internalProps={{
                        label: t('COURSE_PROGRAM_CARD_FIELD_TOTAL_PRACTICE_HOURS'),
                    }}
                />

                <CardActions>
                    <SubmitButton label={t('FORM_SAVE')} />
                </CardActions>
            </form>
        </Card>
    );
}
