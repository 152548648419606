import { BaseApiModel } from '../../common/baseApiModel';

export enum ReportFilterType {
    Boolean,
    Numeric,
    DateTime,
    Date,
    Time,
    Guid,
    String,
}

export enum GeneratedReportType {
    Html,
    Pdf,
}

export interface ReportFilterApiModel extends BaseApiModel {
    type: ReportFilterType;
    entityType?: string;
    order: number;
    isMandatory: boolean;
    description?: string;
}
