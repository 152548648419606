import { AxiosInstance } from 'axios';
import { StudentRelationsApiModel } from '../../../models/studentApiModel';
import { convertToString } from '../../../utils/convertToString';
import { GenericCommandResultOutput } from '../../common/paginatedApiOutput';
import { StudentProgramAttendanceApiModel } from './studentProgramAttendanceApiModel';
import { StudentSeriesAttendanceApiModel, StudentSeriesAttendanceRelations } from './studentSeriesAttendanceApiModel';

export type StudentSeriesAttendanceApiResponse = StudentSeriesAttendanceApiModel &
    StudentSeriesAttendanceRelations.Series &
    StudentSeriesAttendanceRelations.Student<StudentRelationsApiModel.Branch> &
    StudentSeriesAttendanceRelations.StudentProgramAttendance;

export type CreateOrUpdateAttendanceApiModel = Partial<StudentSeriesAttendanceApiModel> & {
    studentId: string;
    seriesId: string;
    studentProgramAttendance: Partial<Omit<StudentProgramAttendanceApiModel, 'branchId' | 'programId'>>;
};

export class StudentSeriesAttendanceService {
    private readonly studentsSeriesApiEndpoint = 'StudentSeriesAttendance';

    constructor(private httpClient: AxiosInstance) {}

    public async getSeriesAttendance(id: string): Promise<StudentSeriesAttendanceApiResponse> {
        const { data } = await this.httpClient.get<StudentSeriesAttendanceApiResponse>(`${this.studentsSeriesApiEndpoint}/${id}`);
        return data;
    }

    async createOrUpdateAttendance(param: CreateOrUpdateAttendanceApiModel): Promise<string> {
        const { data } = await this.httpClient.post<GenericCommandResultOutput>(
            `${this.studentsSeriesApiEndpoint}/${param.id ? 'Update' : 'Create'}`,
            param,
        );
        return convertToString(data.id);
    }
}
