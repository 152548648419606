import styled from 'styled-components';
import { device } from '../cssUtils/breakpoints';

export const ResponsiveBody = styled.div`
    min-width: 0;

    @media ${device.featurePhone} {
        padding: 18px;
    }
    @media ${device.tabletPortrait} {
        padding: 24px;
    }
`;
