import { pick } from 'lodash';
import { useState } from 'react';
import { StudentInputApiModel } from '../../../models/studentApiModel';
import { branchService, seriesService, studentSeriesAttendanceService, studentsService } from '../../../services/dataAccess';
import { isInitialized } from '../../../utils/isInitialized';
import { useEffectAsync } from '../../../utils/useEffectAsync';
import { isNewEntityId } from '../../routing/commands';
import { BranchApiModel, BranchApiModelRelations } from '../branch/branchApiModel';
import { SeriesListItemApiModel } from '../serie/seriesApiModel';
import { StudentSeriesAttendanceApiResponse } from './studentSeriesAttendance.service';

interface BranchOwnedEntity {
    branchId: string;
}

export type SeriesQueryParam = SeriesListItemApiModel | null;
export type StudentQueryParam = StudentInputApiModel | null;

export interface StudentSeriesAttendanceState {
    studentSeriesAttendance: StudentSeriesAttendanceApiResponse | null;
    branch: (BranchApiModel & BranchApiModelRelations.Address) | null;
    params: {
        student: StudentQueryParam;
        series: SeriesQueryParam;
    };
    filteringParams: {
        student: Partial<BranchOwnedEntity>;
        series: Partial<BranchOwnedEntity>;
    };
    loading: boolean;
}

export function useStudentSeriesAttendance(
    id: string,
    seriesPrefill: SeriesQueryParam,
    studentPrefill: StudentQueryParam,
): StudentSeriesAttendanceState {
    const [state, setState] = useState<StudentSeriesAttendanceState>({
        branch: null,
        studentSeriesAttendance: null,
        filteringParams: { series: {}, student: {} },
        params: { series: null, student: null },
        loading: true,
    });

    useEffectAsync(
        async ({ canceled }) => {
            if (isNewEntityId(id)) {
                const student = isInitialized(studentPrefill) ? await studentsService.readStudentDetails(studentPrefill.id) : null;
                const series = isInitialized(seriesPrefill) ? await seriesService.getSeriesAsListItem(seriesPrefill.id) : null;
                if (canceled) {
                    return;
                }
                const branchId = student?.branchId ?? series?.branchId;
                if (!isInitialized(branchId)) {
                    return;
                }
                const branch = await branchService.getBranch(branchId);
                if (canceled) {
                    return;
                }
                setState({
                    branch,
                    filteringParams: {
                        series: series ? pick(series, 'branchId') : {},
                        student: student ? pick(student, 'branchId') : {},
                    },
                    params: { student, series },
                    studentSeriesAttendance: null,
                    loading: false,
                });
                return;
            }
            const studentSeriesAttendance = await studentSeriesAttendanceService.getSeriesAttendance(id);
            const branch = await branchService.getBranch(studentSeriesAttendance.branchId);
            if (canceled) {
                return;
            }
            setState({
                branch,
                studentSeriesAttendance,
                params: { student: studentSeriesAttendance.student, series: studentSeriesAttendance.series },
                filteringParams: {
                    student: studentSeriesAttendance.student,
                    series: {},
                },
                loading: false,
            });
        },
        [id, seriesPrefill, studentPrefill],
    );

    return state;
}
