import { CommandBarButton, FontIcon, IconButton, mergeStyles } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { device } from '../../componentsUx/cssUtils/breakpoints';
import { CssGlobals } from '../../componentsUx/cssUtils/variables';
import { PageContent, PageContentLeftRight } from '../../componentsUx/pageContent/PageContent';
import { SessionData } from '../../state/models/sessionData';
import { useSession } from '../../state/SessionState';
import { ThemeProps } from '../../utils/types';
import { APP_PATHS_FROM_ROOT } from '../routing/commands';
import { HomeRouting } from './HomeRouting';
import { Logo } from '../../logo/Logo';

const HOME_TOOLBAR_HEIGHT = `56px`;

type ToolbarState = 'hidden' | 'visible';

interface HomeToolbarProps extends ThemeProps {
    toolbarState$: ToolbarState;
}

const HomeToolbar = styled.div<HomeToolbarProps>`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    min-height: ${HOME_TOOLBAR_HEIGHT};
    box-shadow: ${(props: HomeToolbarProps): string => props.theme.effects.elevation4};
    background: ${(props): string => props.theme.colors.headerBackground};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transition: transform 250ms ease-in-out;
    transform-origin: top left;
    transform: scaleY(${(props: HomeToolbarProps): number => (props.toolbarState$ === 'hidden' ? 0 : 1)});
`;

const HomePageContainer = styled.div`
    margin-top: ${HOME_TOOLBAR_HEIGHT};
    min-height: calc(100vh - ${HOME_TOOLBAR_HEIGHT});
    display: flex;
    justify-content: center;
    background: ${(props): string => props.theme.colors.background};
`;
const HomeToolbarActionsLeft = styled.div`
    display: flex;
    flex: 1;

    & > {
        * + * {
            margin-left: 10px;
        }
    }
`;

const HomeToolbarActions = styled.div`
    .ms-Button {
        min-height: 27px;
    }

    display: flex;
    background: ${(props): string => props.theme.colors.headerBackground};

    span {
        color: ${(props): string => props.theme.colors.primary};
        font-weight: bold;
    }
`;

const StyledLogo = styled(Logo)`
    margin-right: 80px;
    padding: 0px 4px;
`;

const StyledIconButton = styled(IconButton)`
    fill: ${(props): string => props.theme.colors.primary};
`;

// Inline button texts with content texts
const HomeVerticalPadding = styled.div`
    display: flex;
    min-width: 0;
    flex-direction: column;
    padding-left: ${CssGlobals.buttonPadding}px;
    padding-right: ${CssGlobals.buttonPadding}px;

    @media ${device.featurePhone} {
        padding-bottom: 24px;
    }

    @media ${device.tabletPortrait} {
        padding-bottom: 30px;
    }

    @media ${device.desktop} {
        padding-bottom: 36px;
    }
`;

function getToolbarState(): ToolbarState {
    return window.scrollY > 53 ? 'hidden' : 'visible';
}

const iconClass = mergeStyles({
    fontSize: 24,
    height: 24,
    width: 24,
});

export function Home(): JSX.Element {
    const { t } = useTranslation();
    const [toolbarState, setToolbarState] = useState<'hidden' | 'visible'>(getToolbarState());
    const history = useHistory();
    const [session, setSession] = useSession();

    useEffect(() => {
        const scrollListener = (): void => {
            const newToolbarState = getToolbarState();
            if (toolbarState !== newToolbarState) {
                setToolbarState(newToolbarState);
            }
        };
        window.addEventListener('scroll', scrollListener, {
            passive: true,
        });
        return (): void => window.removeEventListener('scroll', scrollListener);
    }, [toolbarState]);

    const logout = (): void => {
        setSession(new SessionData());
        history.push(APP_PATHS_FROM_ROOT.auth());
    };

    return (
        <>
            <HomeToolbar toolbarState$={toolbarState}>
                <PageContentLeftRight>
                    <HomeToolbarActions>
                        {session?.isBranchAdmin() && (
                            <HomeToolbarActionsLeft>
                                <StyledLogo width={80} onClick={(): void => history.push(APP_PATHS_FROM_ROOT.dashboard())} />
                                <CommandBarButton
                                    onClick={(): void => history.push(APP_PATHS_FROM_ROOT.dashboard())}
                                    text={t('HOME_PAGE_NAV_ACTION_DASHBOARD')}
                                />
                                <CommandBarButton
                                    onClick={(): void => history.push(APP_PATHS_FROM_ROOT.students())}
                                    text={t('HOME_PAGE_NAV_ACTION_STUDENTS')}
                                />
                                <CommandBarButton
                                    onClick={(): void => history.push(APP_PATHS_FROM_ROOT.dashboard())}
                                    text={t('HOME_PAGE_NAV_ACTION_PAYMENTS')}
                                />
                                <CommandBarButton
                                    onClick={(): void => history.push(APP_PATHS_FROM_ROOT.reportsPage())}
                                    text={t('HOME_PAGE_NAV_ACTION_REPORTS')}
                                />
                            </HomeToolbarActionsLeft>
                        )}
                        <div>
                            <StyledIconButton onClick={(): void => history.push(APP_PATHS_FROM_ROOT.companyPage())}>
                                <FontIcon iconName="fp-company" className={iconClass} />
                            </StyledIconButton>
                            <StyledIconButton onClick={(): void => logout()}>
                                <FontIcon iconName="fp-logout" className={iconClass} />
                            </StyledIconButton>
                        </div>
                    </HomeToolbarActions>
                </PageContentLeftRight>
            </HomeToolbar>
            <HomePageContainer>
                <PageContent>
                    <HomeVerticalPadding>
                        <HomeRouting />
                    </HomeVerticalPadding>
                </PageContent>
            </HomePageContainer>
        </>
    );
}
