import { AxiosInstance } from 'axios';
import { StudentsListCardQuery } from '../../../componentsBusiness/studentListCard/studentsListCardQuery';
import { StudentsListApiModel } from '../../../models/studentApiModel';
import { createPaginatedOutput, PaginatedApiInput } from '../../common/paginatedApiOutput';

export interface StudentsQuery {
    branchId: string;
}

export class AllStudentsListQuery implements StudentsListCardQuery<StudentsQuery> {
    private studentsApiEndpoint = 'Students';

    constructor(private httpClient: AxiosInstance) {}

    public async run(apiInput: Partial<StudentsQuery> & PaginatedApiInput): Promise<StudentsListApiModel> {
        const { data } = await this.httpClient.get<StudentsListApiModel>(`${this.studentsApiEndpoint}/GetPage`, {
            params: apiInput,
        });

        return createPaginatedOutput(data);
    }
}
