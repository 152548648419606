import { ITextField, ITextFieldProps, IToggleProps, IMaskedTextFieldProps } from '@fluentui/react';
import React from 'react';
import { ControllerProps } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { DictionaryOf } from '../../utils/dictionaryType';
import { InputDropDownProperties } from '../dropdown/InputDropDown';
import { InputDatePickerProps } from '../inputDatePicker/InputDatePicker';
import { InputPickerProperties } from '../inputPicker/InputPicker';
import { InputPriceProps } from '../inputPrice/InputPrice';
import { TimeSpanProps } from '../inputTimeSpan/InputTimeSpan';

export type ControllerAsElement = React.ReactElement | React.ComponentType<any> | 'input' | 'select' | 'textarea';

export type InternalInputComponent = ITextField;

export interface FieldProps {
    value: any;
    required?: boolean;
    onChange: (...event: any[]) => void;
    onBlur?: () => void;
}

export interface ControllerInputProps extends FieldProps {
    onFocus?: () => void;
    errorMessage?: string;
    className?: string;
    disabled?: boolean;
}

export enum SupportedInputType {
    Text = 'text',
    Password = 'password',
    Email = 'email',
    TextArea = 'textarea',
    Picker = 'picker',
    DropDown = 'dropdown',
    Price = 'price',
    TimeSpan = 'time-span',
    Custom = 'custom',
    DatePicker = 'date-picker',
    Number = 'number',
    Toggle = 'toggle',
    MaskedInput = 'maskedInput',
}

// Prettier formatting is way worse when it comes to readability of this statement
// prettier-ignore
export type InternalControlledComponentProps<TInputType extends SupportedInputType = SupportedInputType> =
    TInputType extends SupportedInputType.Text ? ITextFieldProps :
    TInputType extends SupportedInputType.Password ? ITextFieldProps :
    TInputType extends SupportedInputType.Email ? ITextFieldProps :
    TInputType extends SupportedInputType.TextArea ? ITextFieldProps :
    TInputType extends SupportedInputType.Picker ? InputPickerProperties<unknown> :
    TInputType extends SupportedInputType.DropDown ? InputDropDownProperties :
    TInputType extends SupportedInputType.Price ? InputPriceProps :
    TInputType extends SupportedInputType.TimeSpan ? TimeSpanProps :
    TInputType extends SupportedInputType.DatePicker ? InputDatePickerProps :
    TInputType extends SupportedInputType.Toggle ? IToggleProps :
    TInputType extends SupportedInputType.MaskedInput ? IMaskedTextFieldProps :
    TInputType extends SupportedInputType.Custom ? unknown :
    never;

export type ControlledComponentProps<TInputType extends SupportedInputType = SupportedInputType> = Exclude<
    InternalControlledComponentProps<TInputType>,
    ControllerInputProps
>;

export type InputFieldProps<
    TAs extends ControllerAsElement,
    TControl extends Control = Control,
    TInputType extends SupportedInputType = SupportedInputType
> = Omit<ControllerProps<TAs, TControl>, 'as' | 'render'> & {
    type?: TInputType;
    errors: DictionaryOf<any>;
    elementHeight?: number;
    internalProps?: ControlledComponentProps<TInputType>;
    customField?: (props: ControllerInputProps) => JSX.Element;
    rules?: { required: string | boolean };
    defaultValue?: unknown;
    className?: string;
    required?: boolean;
    disabled?: boolean;
};

export interface FocusableComponent {
    focus?(): void;
}
