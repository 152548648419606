import { AxiosInstance } from 'axios';
import { SeriesListCardQuery } from '../../../componentsBusiness/seriesListCard/seriesListCardQuery';
import { PaginatedApiInput, createPaginatedOutput, PaginatedApiOutput } from '../../common/paginatedApiOutput';
import { SeriesListApiModel, SeriesListItemApiModel } from '../serie/seriesApiModel';

export interface StudentSeriesListCardApiInput {
    showClosed?: boolean;
    studentId: string;
}

export class StudentSeriesAttendanceListCardQuery implements SeriesListCardQuery<StudentSeriesListCardApiInput> {
    private studentsSeriesAttendance = 'StudentSeriesAttendance/StudentSeries';

    constructor(private httpClient: AxiosInstance) {}

    public async run(seriesListApiModel: Partial<StudentSeriesListCardApiInput> & PaginatedApiInput): Promise<SeriesListApiModel> {
        const { data } = await this.httpClient.get<PaginatedApiOutput<SeriesListItemApiModel>>(`${this.studentsSeriesAttendance}/GetPage`, {
            params: seriesListApiModel,
        });

        return createPaginatedOutput(data);
    }
}
