import { ThemeProvider as FluentUiThemeProvider } from '@fluentui/react-theme-provider';
import React from 'react';
import { RecoilRoot } from 'recoil';
import { ThemeProvider } from 'styled-components';
import { theme } from './componentsUx/theme/loadTheme';
import Toast from './componentsUx/toast';
import { Routing } from './features/routing/Routing';
import { DataAccessSetup } from './services/dataAccess/DataAccessSetup';
import { GlobalLoaderSetup } from './state/GlobalLoaderState';
import { SessionPersistenceSetup } from './state/SessionState';
import { UniqueBranchSetup } from './state/UniqueBranchState';

export function AppSetup(): JSX.Element {
    return (
        <RecoilRoot>
            <FluentUiThemeProvider theme={theme} style={{ background: theme.colors.background }}>
                <ThemeProvider theme={theme}>
                    <Routing>
                        <Toast />
                        <GlobalLoaderSetup />
                        <SessionPersistenceSetup />
                        <DataAccessSetup />
                        <UniqueBranchSetup />
                    </Routing>
                </ThemeProvider>
            </FluentUiThemeProvider>
        </RecoilRoot>
    );
}
