import React, { useRef } from 'react';
import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { RecursiveRecord } from '../../utils/dictionaryType';
import { isStringWithEntries } from '../../utils/parseError';
import { ControlledInputField } from './ControlledInputField';
import { InputFieldProps, ControllerAsElement, FieldProps } from './InputFieldTypes';

function findFirstError(params: RecursiveRecord<string>): string {
    if (isStringWithEntries(params)) {
        return params;
    }

    if (isStringWithEntries(params?.message)) {
        return params.message;
    }

    if (Array.isArray(params)) {
        for (const value of params) {
            return findFirstError(value);
        }
    }

    if (typeof params === 'object') {
        for (const key in params) {
            return findFirstError(params[key]);
        }
    }
    return ``;
}

function findErrorMessage(params: RecursiveRecord<string>, name: string): string {
    if (isStringWithEntries(params)) {
        return params;
    }

    let it = params;
    const path = name.split('.');
    for (const key of path) {
        if (!it) {
            return '';
        }
        it = it[key];
    }
    return findFirstError(it);
}

export function InputField<TAs extends ControllerAsElement, TControl extends Control = Control>(
    inputProps: InputFieldProps<TAs, TControl>,
): JSX.Element {
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const componentRef = useRef<{ focus(): void }>(null);

    const errorMessage = findErrorMessage(inputProps.errors, inputProps.name);
    return (
        <Controller
            render={(props: FieldProps): JSX.Element => {
                return (
                    <ControlledInputField
                        ref={componentRef}
                        errorMessage={errorMessage}
                        fieldProps={props}
                        type={inputProps.type}
                        internalProps={inputProps.internalProps}
                        customField={inputProps.customField}
                        className={inputProps.className}
                        required={inputProps.required}
                        disabled={inputProps.disabled}
                    />
                );
            }}
            onFocus={(): void => {
                scrollRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center',
                });
                componentRef?.current?.focus();
            }}
            name={inputProps.name}
            control={inputProps.control}
            defaultValue={inputProps.defaultValue}
            rules={inputProps.rules}
            className={inputProps.className}
        />
    );
}
