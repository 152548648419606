import styled from 'styled-components';
import { ThemeProps } from '../../utils/types';
import { device } from '../cssUtils/breakpoints';
import { MessageType } from './toastState';

const getErrorMessageCardBackgroundColor = (props: ThemeProps & { $messageType: MessageType }): string => {
    if (props.$messageType === MessageType.Error) {
        return props.theme.palette.red;
    }

    return props.theme.palette.accent;
};

const getErrorMessageCardColor = (props: ThemeProps & { $messageType: MessageType }): string => {
    if (props.$messageType === MessageType.Error) {
        return props.theme.palette.white;
    }

    return props.theme.palette.white;
};

export const ToastContainer = styled.div`
    position: fixed;
    bottom: 14px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
`;

export const ToastCard = styled.div`
    box-shadow: ${(props: ThemeProps): string => props.theme.effects.elevation4};
    min-width: 0px;
    max-width: 60%;
    min-width: 0;
    background-color: ${getErrorMessageCardBackgroundColor};
    color: ${getErrorMessageCardColor};

    @media ${device.featurePhone} {
        padding: 6px;
    }
    @media ${device.tabletPortrait} {
        padding: 12px;
    }
    @media ${device.tabletPortrait} {
        padding: 18px;
    }
`;
