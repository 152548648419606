import { atom, useSetRecoilState } from 'recoil';

export enum MessageType {
    Error,
    Info,
}

export interface InfoToastData {
    infoMessage: string;
    infoMessageParams?: Record<string, unknown>;
    // duration is in seconds
    duration: number;
    messageType: MessageType;
}

const key = 'info-toast-state';
export const InfoToastState = atom<InfoToastData | null>({
    key,
    default: null,
});

type ShowToastFnParams = Pick<InfoToastData, 'infoMessage'> & Partial<Omit<InfoToastData, 'messageType'>>;
type ShowToastFn = (infoData: ShowToastFnParams) => void;
const DefaultToastDuration = 5;

export function useShowError(): ShowToastFn {
    const setToastState = useSetRecoilState(InfoToastState);

    return (infoData: ShowToastFnParams): void => {
        setToastState({
            ...infoData,
            messageType: MessageType.Error,
            duration: infoData.duration ?? DefaultToastDuration,
        });
    };
}

export function useShowInfo(): ShowToastFn {
    const setToastState = useSetRecoilState(InfoToastState);
    return (infoData: ShowToastFnParams): void => {
        setToastState({
            ...infoData,
            messageType: MessageType.Info,
            duration: infoData.duration ?? DefaultToastDuration,
        });
    };
}
