import { AxiosInstance } from 'axios';
import { PaginatedApiOutput, PaginatedApiInput, createPaginatedOutput, GenericCommandResultOutput } from '../../common/paginatedApiOutput';
import { BranchApiModelRelations, BranchListApiModel, BranchListItemApiModel, BranchApiModel } from './branchApiModel';

type UpdateBranchApiModel = BranchApiModel & BranchApiModelRelations.Address;

export class BranchService {
    readonly branchEndpoint = `Branch`;

    constructor(private httpClient: AxiosInstance) {}

    public async readBranches(branchListApiModel: PaginatedApiInput): Promise<BranchListApiModel> {
        const { data } = await this.httpClient.get<PaginatedApiOutput<BranchListItemApiModel>>(`${this.branchEndpoint}/GetPage`, {
            params: branchListApiModel,
        });

        return createPaginatedOutput(data);
    }

    public async updateBranch(params: UpdateBranchApiModel): Promise<string | null> {
        const result = await this.httpClient.put<GenericCommandResultOutput>(`${this.branchEndpoint}/Update`, params);
        return result.data.id;
    }

    public async addBranch(params: UpdateBranchApiModel): Promise<string | null> {
        const result = await this.httpClient.post<GenericCommandResultOutput>(`${this.branchEndpoint}/Create`, params);
        return result.data.id;
    }

    public async getBranch(branchId: string): Promise<UpdateBranchApiModel> {
        const { data } = await this.httpClient.get<UpdateBranchApiModel>(`${this.branchEndpoint}/${branchId}`);
        return data;
    }
}
