import { IColumn } from '@fluentui/react';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Card } from '../../../componentsUx/card/Card';
import { CardTitle } from '../../../componentsUx/card/CardTitle';
import { PaginatedList } from '../../../componentsUx/paginatedList/PaginatedList';
import { BranchOwnedPaginationInputs, useBranchListListInput } from '../../../componentsUx/paginatedList/useListInput';
import { courseProgramService } from '../../../services/dataAccess';
import { CourseType, CourseTypeValue } from '../../common/courseType';
import { createDefaultPaginatedOutput } from '../../common/paginatedApiOutput';
import { APP_PATHS_FROM_ROOT } from '../../routing/commands';
import { CourseProgramListApiModel, CourseProgramListItemApiModel } from './courseProgramApiModel';

export function CourseProgramListCard(props: { branchId: string }): JSX.Element {
    const { branchId } = props;
    const [t] = useTranslation();
    const history = useHistory();
    const [listInput, setListInput] = useBranchListListInput({
        orderByProperty: 'specializationName',
        branchId,
    });
    const [courseProgramsList, setCourseProgramsList] = useState<CourseProgramListApiModel>(createDefaultPaginatedOutput());

    const readCoursePrograms = async (): Promise<void> => {
        const courseProgramList = await courseProgramService.readCoursePrograms(listInput);
        setCourseProgramsList(courseProgramList);
    };

    useEffect(() => {
        readCoursePrograms();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listInput]);

    const onItemClicked = (model: CourseProgramListItemApiModel): void => {
        history.push(APP_PATHS_FROM_ROOT.courseProgramPage(branchId, model.id));
    };

    const onAdd = (): void => {
        history.push(APP_PATHS_FROM_ROOT.newCourseProgramPage(props.branchId));
    };

    const commonColumnProps = {
        minWidth: 100,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: true,
    };

    const columns: IColumn[] = [
        {
            key: 'columnName',
            name: t('COURSE_PROGRAM_LIST_COLUMN_NAME'),
            fieldName: 'specializationName',
            ...commonColumnProps,
        },
        {
            key: 'columnCourseType',
            name: t('COURSE_PROGRAM_LIST_COLUMN_COURSE_TYPE'),
            fieldName: 'courseType',
            onRender: (item: { type: CourseType }): JSX.Element => {
                return <>{t(CourseTypeValue[item.type])}</>;
            },
            ...commonColumnProps,
        },
        {
            key: 'columnSpecializationName',
            name: t('COURSE_PROGRAM_LIST_COLUMN_SPECIALIZATION_NAME'),
            fieldName: 'specializationName',
            ...commonColumnProps,
        },
        {
            key: 'columnSpecializationCode',
            name: t('COURSE_PROGRAM_LIST_COLUMN_SPECIALIZATION_CODE'),
            fieldName: 'specializationCode',
            ...commonColumnProps,
        },
    ];

    return (
        <Card>
            <CardTitle>{t('COURSE_PROGRAM_LIST_TITLE')}</CardTitle>
            <PaginatedList
                entityType="CourseProgram"
                onItemClicked={onItemClicked}
                searchTextLabel={t('SEARCH_BY_NAME')}
                model={courseProgramsList}
                pagination={listInput}
                onReadPage={(inputs: BranchOwnedPaginationInputs): void => setListInput(inputs)}
                columns={columns}
                allowAdd={true}
                onAdd={onAdd}
            />
        </Card>
    );
}
