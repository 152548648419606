import { IColumn } from '@fluentui/react';
import { omit } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ObjectParam, useQueryParams, withDefault } from 'use-query-params';
import { Card } from '../../componentsUx/card/Card';
import { CardTitle } from '../../componentsUx/card/CardTitle';
import { BranchPicker } from '../../componentsUx/inputBranchPicker/BranchPicker';
import { createDefaultColumn } from '../../componentsUx/paginatedList/createDefaultColumn';
import { PaginatedList } from '../../componentsUx/paginatedList/PaginatedList';
import { PaginationInputs, useListInput } from '../../componentsUx/paginatedList/useListInput';
import { createDefaultPaginatedOutput } from '../../features/common/paginatedApiOutput';
import { isNewEntityId } from '../../features/routing/commands';
import { StudentListItemApiModel, StudentsListApiModel } from '../../models/studentApiModel';
import { useSession } from '../../state/SessionState';
import { isInitialized } from '../../utils/isInitialized';
import { sanitizeApiQueryParams } from '../../utils/sanitizeApiQueryParams';
import { useEffectAsync } from '../../utils/useEffectAsync';
import { StudentsListCardQuery } from './studentsListCardQuery';
import { FiltersArea } from './StudentsListCardUtils';

export interface StudentsListCardProps<TPaginatedApiInput extends Record<string, unknown> = Record<string, unknown>> {
    service: StudentsListCardQuery<TPaginatedApiInput>;
    staticServiceParams: Partial<TPaginatedApiInput>;
    allowAdd: boolean;
    onAdd?(): void;
    onItemClicked?(model: StudentListItemApiModel): void;
}

export function StudentsListCard<TPaginatedApiInput extends Record<string, unknown> = Record<string, unknown>>(
    props: StudentsListCardProps<TPaginatedApiInput>,
): JSX.Element {
    const { t } = useTranslation();
    const [listInput, setListInput] = useListInput({ orderByProperty: 'firstName' });
    const [studentsList, setStudentsList] = useState<StudentsListApiModel>(createDefaultPaginatedOutput());
    const [filters, setFilters] = useQueryParams({
        branch: withDefault(ObjectParam, {
            id: '',
            name: '',
        }),
    });
    const [session] = useSession();
    const isNewSeriesId = isNewEntityId(props.staticServiceParams.seriesId as string);

    function setFilterParam<TData>(paramName: string): (data: TData) => void {
        return (data: TData): void => {
            setFilters((prev) => ({
                ...prev,
                [paramName]: data,
            }));
        };
    }

    function isDisplayedSeriesStudents(): boolean {
        return isInitialized(props.staticServiceParams?.seriesId);
    }

    useEffectAsync(
        async ({ canceled }) => {
            if (isNewSeriesId) {
                return;
            }

            // Internal filters without the predefined filters for service
            const filterParams = omit(filters, Object.keys(props.staticServiceParams ?? {}));

            const queryParams = {
                ...filterParams,
                branchId: filterParams?.branch?.id,
                // Pagination data
                ...listInput,
                // Predefined service filters
                ...props.staticServiceParams,
            };
            sanitizeApiQueryParams(queryParams);
            const studentsList = await props.service.run(queryParams);
            if (canceled) {
                return;
            }
            setStudentsList(studentsList);
        },
        [listInput, props.staticServiceParams, props.service, filters],
    );

    const columns: IColumn[] = [
        createDefaultColumn<StudentListItemApiModel, 'firstName'>({
            data: 'string',
            fieldName: 'firstName',
            name: t('STUDENTS_LIST_CARD_COLUMN_FIRST_NAME'),
        }),
        createDefaultColumn<StudentListItemApiModel, 'lastName'>({
            data: 'string',
            fieldName: 'lastName',
            name: t('STUDENTS_LIST_CARD_COLUMN_LAST_NAME'),
        }),
        createDefaultColumn<StudentListItemApiModel, 'socialSecurityNumber'>({
            data: 'string',
            fieldName: 'socialSecurityNumber',
            name: t('STUDENTS_LIST_CARD_COLUMN_SOCIAL_SECURITY_NUMBER'),
        }),
        createDefaultColumn<StudentListItemApiModel, 'email'>({
            data: 'string',
            fieldName: 'email',
            name: t('STUDENTS_LIST_CARD_COLUMN_EMAIL'),
            maxWidth: 250,
        }),
    ];

    if (!isDisplayedSeriesStudents()) {
        columns.push(
            createDefaultColumn<StudentListItemApiModel, 'branchName'>({
                data: 'string',
                fieldName: 'branchName',
                name: t('STUDENTS_LIST_CARD_COLUMN_BRANCH'),
                maxWidth: 250,
            }),
        );
    }

    function onItemClicked(model: StudentListItemApiModel): void {
        props.onItemClicked?.(model);
    }

    function renderFilter(): JSX.Element {
        // We don't show the branch filter if the parent component specified the branchId or are displayed series students
        const BranchIdFilter =
            isInitialized(props.staticServiceParams?.branchId) || isDisplayedSeriesStudents() ? (
                <></>
            ) : (
                <BranchPicker label={t('STUDENT_FIELD_BRANCH_ID')} value={filters.branch} onChange={setFilterParam('branch')} />
            );

        return <FiltersArea>{BranchIdFilter}</FiltersArea>;
    }

    return (
        <Card>
            <CardTitle>{t('STUDENTS_LIST_CARD_TITLE')}</CardTitle>
            <PaginatedList
                entityType="Student"
                onItemClicked={onItemClicked}
                searchTextLabel={t('SEARCH_BY_NAME_CNP_EMAIL')}
                model={studentsList}
                pagination={listInput}
                onReadPage={(inputs: PaginationInputs): void => setListInput(inputs)}
                columns={columns}
                allowAdd={props.allowAdd && session.isCompanyAdmin && !isNewSeriesId}
                onAdd={props.onAdd}
                renderFilter={renderFilter}
            />
        </Card>
    );
}
