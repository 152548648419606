import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CardActions } from '../../componentsUx/card/CardActions';
import { ConditionalCardViewMode, ConditionalCard } from '../../componentsUx/card/ConditionalCard';
import { SubmitButton } from '../../componentsUx/input/SubmitButton';
import { useShowInfo } from '../../componentsUx/toast/toastState';
import { studentSeriesAttendanceService } from '../../services/dataAccess';
import { useFpForm, setFormValues } from '../../utils/useFormHook';
import { ValidFormValues, defaultMissingValues, StudentSeriesAttendanceSchema, mapFormDataToStudentSeriesAttendance, FormValues } from './form.util';
import { StudentSeriesAttendanceEditorFields, FormAutocompletes } from './StudentSeriesAttendanceEditorFields';

interface Props {
    // Form configuration
    objectId?: string;
    formValues: FormValues | null;
    formAutocompletes: FormAutocompletes;
    // View settings
    viewMode: ConditionalCardViewMode;
    // Callback for submit done
    onStudentSeriesAttendanceUpdated?: (studentSeriesAttendanceId: string) => void;
}

export function StudentSeriesAttendanceEditor({
    objectId,
    formValues,
    formAutocompletes,
    onStudentSeriesAttendanceUpdated,
    viewMode,
}: Props): JSX.Element {
    const { t } = useTranslation();
    const showToastInfo = useShowInfo();

    const formMethods = useFpForm<FormValues>({
        schema: StudentSeriesAttendanceSchema,
        defaultValues: defaultMissingValues(),
    });
    const { handleSubmit, setValue } = formMethods;

    useEffect(() => {
        const initialValues = defaultMissingValues(formValues);
        setFormValues(initialValues, setValue);
    }, [formValues, setValue]);

    async function saveStudentSeriesAttendanceDetails(studentData: ValidFormValues): Promise<void> {
        const studentSeriesAttendanceId = await studentSeriesAttendanceService.createOrUpdateAttendance(
            mapFormDataToStudentSeriesAttendance(objectId, studentData),
        );
        showToastInfo({ infoMessage: 'STUDENT_SERIES_ATTENDANCE_SAVE_SUCCESSFULLY' });

        if (studentSeriesAttendanceId) {
            onStudentSeriesAttendanceUpdated?.(studentSeriesAttendanceId);
        }
    }

    return (
        <ConditionalCard viewMode={viewMode} cardTitle={'STUDENT_SERIES_CARD_TITLE'}>
            <form onSubmit={handleSubmit(saveStudentSeriesAttendanceDetails)}>
                <StudentSeriesAttendanceEditorFields form={formMethods} objectId={objectId} formAutocompletes={formAutocompletes} />
                <CardActions>
                    <SubmitButton label={t('FORM_SAVE')} />
                </CardActions>
            </form>
        </ConditionalCard>
    );
}
