import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { device } from '../../../componentsUx/cssUtils/breakpoints';
import { ReportDetailsCard } from './ReportDetailsCard';
import { PreviewGeneratedReportCard } from './PreviewGeneratedReportCard';
import { GeneratingReportLoadingIndicator } from './GeneratingReportLoadingIndicator';
import { APP_PATHS_FROM_ROOT } from '../../routing/commands';
import { useTranslation } from 'react-i18next';
import { Breadcrumb } from '../../../componentsUx/breadcrumb/Breadcrumb';

const ReportGrid = styled.div`
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    @media ${device.featurePhone} {
        grid-template-areas: 'reportCard' 'previewReportCard';
        grid-template-columns: minmax(0, 1fr);
    }

    @media ${device.tabletLandscape} {
        grid-template-columns: 1fr minmax(0, 2fr);
        grid-template-areas: 'reportCard previewReportCard';
    }
`;

const GridCard = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 0;
`;

const ReportCardArea = styled(GridCard)`
    grid-area: reportCard;
`;

const PreviewGeneratedReportArea = styled(GridCard)`
    grid-area: previewReportCard;
`;

export function Report(): JSX.Element {
    const { t } = useTranslation();
    const history = useHistory();
    const { id, generatedReportId } = useParams<{ id: string; generatedReportId?: string }>();
    const [isGenerating, setIsGenerating] = useState(false);

    useEffect(() => {
        setIsGenerating(false);
    }, [generatedReportId]);

    const breadcrumbItems = [
        {
            text: t('REPORT_LIST_TITLE'),
            key: 'REPORT_LIST_TITLE',
            onClick: (): void => history.push(APP_PATHS_FROM_ROOT.reports()),
        },
        {
            text: t('REPORT_CARD_TITLE'),
            key: 'REPORT_CARD_TITLE',
            isCurrentItem: true,
        },
    ];

    return (
        <>
            <Breadcrumb items={breadcrumbItems} />
            <ReportGrid>
                <ReportCardArea>
                    <ReportDetailsCard reportId={id} setIsGenerating={setIsGenerating} />
                </ReportCardArea>
                <PreviewGeneratedReportArea>
                    {generatedReportId && !isGenerating && <PreviewGeneratedReportCard generatedReportId={generatedReportId} />}
                    {isGenerating && <GeneratingReportLoadingIndicator />}
                </PreviewGeneratedReportArea>
            </ReportGrid>
        </>
    );
}
