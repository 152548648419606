import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { isPlainObject, isString, mapValues } from 'lodash';
import { SessionData } from '../../state/models/sessionData';
import { isValid, parseJSON } from 'date-fns';
import { apiUrls } from '../../environment/apiUrls';

type AxiosInterceptor = (config: AxiosRequestConfig) => AxiosRequestConfig;
export type AxiosResponseInterceptor = (response: AxiosResponse<unknown>) => AxiosResponse<unknown> | Promise<AxiosResponse<unknown>>;

export const createAuthHeaderInterceptor = (sessionData: SessionData): AxiosInterceptor => {
    return (config: AxiosRequestConfig): AxiosRequestConfig => {
        if (!config.headers.Authorization && !sessionData.isExpired) {
            config.headers.Authorization = `Bearer ${sessionData.token}`;
        }
        return config;
    };
};

export const createApiRequestUrlInterceptor = (): AxiosInterceptor => {
    const apiUrl = apiUrls.API;
    return (config: AxiosRequestConfig): AxiosRequestConfig => {
        config.url = config.url?.startsWith('http') ? config.url : config.url?.startsWith('/') ? config.url : `${apiUrl}/${config.url}`;
        return config;
    };
};

// This expects to be feed an object that just came over the network, so anything we could expect here are strings, numbers, booleans, arrays,
// objects and nulls.
function parseDatesDeep(value: unknown): unknown {
    if (Array.isArray(value)) {
        return value.map(parseDatesDeep);
    }

    if (isPlainObject(value)) {
        return mapValues(value as Record<string, unknown>, parseDatesDeep);
    }

    if (isString(value)) {
        const parsedDate = parseJSON(value);
        if (isValid(parsedDate)) {
            return parsedDate;
        }
    }

    return value;
}

// Factory for an interceptor that takes the JSON response and traverses it to transform date strings into date objects.
export const createParseDatesInResponseInterceptor = (): AxiosResponseInterceptor => {
    return (response: AxiosResponse<unknown>): AxiosResponse<unknown> => {
        response.data = parseDatesDeep(response.data);
        return response;
    };
};
