import { AxiosInstance } from 'axios';
import { PaginatedApiOutput, BranchOwnedPaginatedApiInput, createPaginatedOutput, GenericCommandResultOutput } from '../../common/paginatedApiOutput';
import { BranchStaffMemberApiModel, BranchStaffMemberListApiModel, BranchStaffMemberListItemApiModel } from './branchStaffMemberApiModel';

export class BranchStaffMemberService {
    readonly branchStaffMemberEndpoint = `BranchStaffMember`;

    constructor(private httpClient: AxiosInstance) {}

    public async readBranchStaffMembers(branchStaffMemberListApiModel: BranchOwnedPaginatedApiInput): Promise<BranchStaffMemberListApiModel> {
        const { data } = await this.httpClient.get<PaginatedApiOutput<BranchStaffMemberListItemApiModel>>(
            `${this.branchStaffMemberEndpoint}/GetPage`,
            {
                params: branchStaffMemberListApiModel,
            },
        );

        return createPaginatedOutput(data);
    }

    public async updateBranchStaffMember(params: BranchStaffMemberApiModel): Promise<string | null> {
        const result = await this.httpClient.put<GenericCommandResultOutput>(`${this.branchStaffMemberEndpoint}/Update`, params);
        return result.data.id;
    }

    public async addBranchStaffMember(params: BranchStaffMemberApiModel): Promise<string | null> {
        const result = await this.httpClient.post<GenericCommandResultOutput>(`${this.branchStaffMemberEndpoint}/Create`, params);
        return result.data.id;
    }

    public async getBranchStaffMember(branchStaffMemberId: string): Promise<BranchStaffMemberApiModel> {
        const { data } = await this.httpClient.get<BranchStaffMemberApiModel>(`${this.branchStaffMemberEndpoint}/${branchStaffMemberId}`);
        return data;
    }
}
