import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { useQueryParams, withDefault, JsonParam } from 'use-query-params';
import { StudentSeriesAttendanceEditor } from '../../../componentsBusiness/studentSeriesAttendanceEditor';
import {
    defaultMissingValues,
    mapStudentSeriesAttendanceToFormData,
    FormValues,
} from '../../../componentsBusiness/studentSeriesAttendanceEditor/form.util';
import { Breadcrumb } from '../../../componentsUx/breadcrumb/Breadcrumb';
import { isInitialized } from '../../../utils/isInitialized';
import { APP_PATHS_FROM_ROOT } from '../../routing/commands';
import { useStudentSeriesAttendance, SeriesQueryParam, StudentQueryParam } from './useStudentSeriesAttendance';

export interface StudentSeriesAttendanceProps {
    fromSerie: boolean;
}

function StudentSeriesAttendance(props: StudentSeriesAttendanceProps): JSX.Element {
    const { fromSerie } = props;
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const [queryParams] = useQueryParams({
        series: withDefault<SeriesQueryParam, null>(JsonParam, null),
        student: withDefault<StudentQueryParam, null>(JsonParam, null),
    });
    const [disabledFields, setDisabledFields] = useState<{ student: boolean; series: boolean }>({
        series: false,
        student: false,
    });
    const [formValues, setFormValues] = useState<FormValues | null>(null);
    const state = useStudentSeriesAttendance(id, queryParams.series, queryParams.student);

    useEffect(() => {
        const formValues: FormValues = state.studentSeriesAttendance
            ? mapStudentSeriesAttendanceToFormData(state.studentSeriesAttendance)
            : {
                  ...defaultMissingValues({
                      // When we create a new series we prefill the student or the series and we prefil the provider details
                      student: state.params.student ?? {},
                      series: state.params.series ?? {},
                      provider: state.branch?.name ?? '',
                      providerCity: state.branch?.address?.city ?? '',
                  }),
              };
        setFormValues(formValues);
        setDisabledFields({
            // We cannot edit the student once studentSeriesAttendance was created or the student was passed by the parent page
            student: isInitialized(state.studentSeriesAttendance) || Boolean(state.params.student),
            // We cannot edit the series if the series was specified by the parent page and we are creating a studentSeriesAttendance
            series: isInitialized(state.studentSeriesAttendance) || Boolean(state.params.series),
        });
    }, [state, queryParams]);

    const history = useHistory();

    if (state.loading) {
        return <></>;
    }

    const currentBreadcrumbItem = {
        text: t('STUDENT_SERIES_CARD_TITLE'),
        key: 'STUDENT_SERIES_CARD_TITLE',
        isCurrentItem: true,
    };

    const breadcrumbItemsFromSerie = [
        {
            text: t('HOME_PAGE_NAV_ACTION_DASHBOARD'),
            key: 'HOME_PAGE_NAV_ACTION_DASHBOARD',
            onClick: (): void => history.push(APP_PATHS_FROM_ROOT.dashboard()),
        },
        {
            text: t('SERIE_CARD_TITLE'),
            key: 'SERIE_CARD_TITLE',
            onClick: (): void => history.push(APP_PATHS_FROM_ROOT.seriesPage(state.params.series?.id || '')),
        },
        currentBreadcrumbItem,
    ];

    const breadcrumbItemsFromStudent = [
        {
            text: t('STUDENTS_LIST_CARD_TITLE'),
            key: 'STUDENTS_LIST_CARD_TITLE',
            onClick: (): void => history.push(APP_PATHS_FROM_ROOT.students()),
        },
        {
            text: t('STUDENTS_LIST_CARD_TITLE'),
            key: 'STUDENTS_LIST_CARD_TITLE',
            onClick: (): void => history.push(APP_PATHS_FROM_ROOT.student(state.params.student?.id || '')),
        },
        currentBreadcrumbItem,
    ];

    return (
        <>
            <Breadcrumb items={fromSerie ? breadcrumbItemsFromSerie : breadcrumbItemsFromStudent} />
            <StudentSeriesAttendanceEditor
                viewMode={'card'}
                objectId={state.studentSeriesAttendance?.id}
                formValues={formValues}
                formAutocompletes={{
                    // Series autocompleted is filtered by the student branchId
                    series: { disabled: disabledFields.series, apiStaticParams: state.filteringParams.student },
                    // Student autocompleted is filtered by the series branchId
                    student: { disabled: disabledFields.student, apiStaticParams: state.filteringParams.series },
                }}
                onStudentSeriesAttendanceUpdated={(): void => history.goBack()}
            />
        </>
    );
}

export const StudentSeriesAttendanceFromSerie = (): JSX.Element => <StudentSeriesAttendance fromSerie={true} />;
export const StudentSeriesAttendanceFromStudent = (): JSX.Element => <StudentSeriesAttendance fromSerie={false} />;
