/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { List, ProgressIndicator } from '@fluentui/react';
import React, { MutableRefObject } from 'react';
import styled from 'styled-components';
import { isInitialized } from '../../utils/isInitialized';
import { ReactProps } from '../../utils/types';
import { InputPickerListItem } from './InputPickerListItem';

const PickerModal = styled.div`
    padding-top: 8px;
    padding-bottom: 8px;
    width: 100%;
    min-width: ${(props: { $maxWidth: string }): string => props.$maxWidth};
    max-height: 200px;
    min-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
    max-width: ${(props: { $maxWidth: string }): string => props.$maxWidth};
    position: relative;
`;

const DialogLoader = styled(ProgressIndicator).attrs({
    barHeight: 4,
})`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    .itemProgress-60 {
        padding-top: 0;
    }
`;

export type InputPickerModalScrollEvent<TItem> = React.UIEvent<HTMLDivElement | List<TItem>, UIEvent>;

export enum SelectedIndexSource {
    MouseOver,
    ArrowNavigation,
    InitialRender,
}

export interface Props<TItem> extends ReactProps {
    loading: boolean;
    selectedItem: { index: number; source: SelectedIndexSource };
    maxWidthPickerModal: string;
    onScroll: (event: InputPickerModalScrollEvent<TItem>) => void;
    modalRef: MutableRefObject<HTMLDivElement | null>;
    items: TItem[];
    onItemSelected: (item: TItem, index: number) => void;
    onItemMouseEnter: (item: TItem, index: number) => void;
    renderItem: (item: TItem) => JSX.Element;
}

export function InputPickerModal<TItem>(props: Props<TItem>): JSX.Element {
    return (
        <PickerModal $maxWidth={props.maxWidthPickerModal} onScroll={props.onScroll} ref={props.modalRef}>
            {props.loading ? <DialogLoader /> : null}
            <List
                items={props.items}
                onRenderCell={(item, index): JSX.Element => {
                    const isSelected = index === props.selectedItem.index;

                    return (
                        <InputPickerListItem
                            isSelected={isSelected}
                            scrollToItem={
                                isSelected &&
                                [SelectedIndexSource.ArrowNavigation, SelectedIndexSource.InitialRender].includes(props.selectedItem.source)
                            }
                            onMouseDown={(): void => {
                                if (isInitialized(item) && isInitialized(index)) {
                                    props.onItemSelected(item, index);
                                }
                            }}
                            onMouseEnter={(): void => {
                                if (isInitialized(item) && isInitialized(index)) {
                                    props.onItemMouseEnter(item, index);
                                }
                            }}
                        >
                            {item && props.renderItem(item)}
                        </InputPickerListItem>
                    );
                }}
            />
        </PickerModal>
    );
}
