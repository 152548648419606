import { Stack } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputPicker } from '../../../../componentsUx/inputPicker/InputPicker';
import { convertToString } from '../../../../utils/convertToString';
import { CourseProgramListItemApiModel } from '../../courseProgram/courseProgramApiModel';
import { BranchFilterPicker } from './BranchFilterPicker';
import { FilterPickerProps } from './filterPickerProps';

export function CourseProgramFilterPicker(props: FilterPickerProps): JSX.Element {
    const [branchId, setBranchId] = useState<string | null>(null);
    const [selectedCourseProgram, setSelectedCourseProgram] = useState<CourseProgramListItemApiModel | null>(null);
    const [t] = useTranslation();

    const onPickerChange = (newSelected: CourseProgramListItemApiModel): void => {
        setSelectedCourseProgram(newSelected);
        props.setId(newSelected?.id);
    };

    useEffect(() => {
        setSelectedCourseProgram(null);
    }, [setSelectedCourseProgram, branchId]);

    return (
        <Stack>
            <BranchFilterPicker id={branchId} setId={setBranchId} prefill={props.prefill} errorMessage={props.errorMessage} />
            {branchId && (
                <InputPicker
                    label={t('REPORT_FILTER_PICKER_COURSE_PROGRAM')}
                    value={selectedCourseProgram}
                    errorMessage={props.errorMessage}
                    onChange={onPickerChange}
                    apiEndpoint="CourseProgram"
                    allowFreeText={false}
                    displayItem={(c: CourseProgramListItemApiModel): string => convertToString(c?.specializationName)}
                    renderItem={(c: CourseProgramListItemApiModel): JSX.Element => (
                        <div>
                            {c?.specializationName} {c.specializationCode}
                        </div>
                    )}
                    getApiParams={(): Record<string, unknown> => {
                        return {
                            branchId: branchId,
                        };
                    }}
                />
            )}
        </Stack>
    );
}
