import { environmentVariables } from './environmentVariables';

class ApiUrls {
    private apiURL: URL;
    private identityURL: URL;

    get API(): string {
        return this.apiURL.toString();
    }

    get IDENTITY(): string {
        return this.identityURL.toString();
    }

    constructor() {
        let apiURL: URL = new URL(window.location.href);
        if (environmentVariables.API_URL) {
            if (this.isValidAbsoluteHttpUrl(environmentVariables.API_URL)) {
                apiURL = new URL(environmentVariables.API_URL);
            } else if (this.isValidRelativeUrl(environmentVariables.API_URL)) {
                apiURL = new URL(window.location.href);
                apiURL.pathname = environmentVariables.API_URL;
            }
        }

        this.apiURL = apiURL;

        let identityURL = new URL(window.location.href);
        if (environmentVariables.OAUTH_API_URL) {
            if (this.isValidAbsoluteHttpUrl(environmentVariables.OAUTH_API_URL)) {
                identityURL = new URL(environmentVariables.OAUTH_API_URL);
            } else if (this.isValidRelativeUrl(environmentVariables.OAUTH_API_URL)) {
                identityURL = new URL(window.location.href);
                identityURL.pathname = environmentVariables.OAUTH_API_URL;
            }
        }

        this.identityURL = identityURL;
    }

    isValidAbsoluteHttpUrl(urlString: string): boolean {
        let url: URL;
        try {
            url = new URL(urlString);
        } catch (_) {
            return false;
        }

        return url.protocol === 'http:' || url.protocol === 'https:';
    }

    isValidRelativeUrl(urlString: string): boolean {
        try {
            const url = new URL(window.location.href);
            url.pathname = urlString;
            return true;
        } catch (_) {
            return false;
        }
    }
}

export const apiUrls = new ApiUrls();
