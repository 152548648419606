import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '../../utils/types';
import { ResponsiveBody } from './ResponsiveBody';

export const CardElevation = styled.div`
    box-shadow: ${(props: ThemeProps): string => props.theme.effects.elevation4};
    background: ${(props): string => props.theme.colors.cardBackground};
`;

export function Card({ children, removeBodyContainer }: PropsWithChildren<{ removeBodyContainer?: boolean }>): JSX.Element {
    const CardBody = removeBodyContainer ? children : <ResponsiveBody>{children}</ResponsiveBody>;
    return <CardElevation>{CardBody}</CardElevation>;
}
