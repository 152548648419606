import React, { useState, FormEvent, useEffect } from 'react';
import { UseFormMethods } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { CardActions } from '../../componentsUx/card/CardActions';
import { ConditionalCard, ConditionalCardViewMode } from '../../componentsUx/card/ConditionalCard';
import { device } from '../../componentsUx/cssUtils/breakpoints';
import { InputField } from '../../componentsUx/input/InputField';
import { SupportedInputType } from '../../componentsUx/input/InputFieldTypes';
import { SubmitButton } from '../../componentsUx/input/SubmitButton';
import { useShowInfo } from '../../componentsUx/toast/toastState';
import { FormFieldValues, toAtomicType } from '../../features/common/baseApiModel';
import { BranchApiModel, BranchApiModelRelations } from '../../features/home/branch/branchApiModel';
import { APP_PATHS_FROM_ROOT } from '../../features/routing/commands';
import { studentsService, studentSeriesAttendanceService } from '../../services/dataAccess';
import { isInitialized } from '../../utils/isInitialized';
import { asType } from '../../utils/types';
import { useFpForm, updateFormValidationAndShowErrors } from '../../utils/useFormHook';
import * as studentFromUtil from '../studentDetailsEditor/form.util';
import { StudentEditorFields } from '../studentDetailsEditor/StudentEditorFields';
import * as studentSeriesAttendanceFormUtil from '../studentSeriesAttendanceEditor/form.util';
import { mapFormDataToStudentSeriesAttendance } from '../studentSeriesAttendanceEditor/form.util';
import { StudentSeriesAttendanceEditorFields, FormAutocompletes } from '../studentSeriesAttendanceEditor/StudentSeriesAttendanceEditorFields';

const StudentDetailsEditorArea = styled.div`
    grid-area: studentDetailsCard;
`;

const StudentRelatedDataArea = styled.div`
    grid-area: studentRelatedDataArea;
`;

const StudentDetailsGrid = styled.div`
    display: grid;
    grid-column-gap: 28px;
    grid-row-gap: 28px;

    @media ${device.featurePhone} {
        grid-template-areas: 'studentDetailsCard' 'studentRelatedDataArea';
        grid-template-columns: minmax(0, 1fr);
    }

    @media ${device.tabletLandscape} {
        grid-template-columns: 1fr minmax(0, 2fr);
        grid-template-areas: 'studentDetailsCard studentRelatedDataArea';
    }
`;
type StudentValidFormValues = studentFromUtil.ValidFormValues & {
    addToSeries: boolean;
};
type StudentFormValues = FormFieldValues<StudentValidFormValues>;

interface Props {
    viewMode: ConditionalCardViewMode;
}
export function AddStudentWithSeriesEditor({ viewMode }: Props): JSX.Element {
    const { t } = useTranslation();
    const showToastInfo = useShowInfo();
    const history = useHistory();
    // Unfortunately  schema validation using yup is very weird when you want to combine 2 schemas into one and make some fields depend on another fields
    // so in this case we are using 2 forms -> and we handle the submit manually
    const studentForm = useFpForm<StudentFormValues>({
        schema: studentFromUtil.StudentDetailsSchema,
        defaultValues: { ...studentFromUtil.defaultMissingStudentValues(), addToSeries: false },
    });
    const studentSeriesAttendanceForm = useFpForm<studentSeriesAttendanceFormUtil.FormValues>({
        schema: studentSeriesAttendanceFormUtil.StudentSeriesAttendanceSchema,
        defaultValues: studentSeriesAttendanceFormUtil.defaultMissingValues(),
    });

    const branchData = studentForm.watch('branch') as FormFieldValues<BranchApiModel & BranchApiModelRelations.Address>;

    useEffect(() => {
        if (!branchData) {
            return;
        }

        studentSeriesAttendanceForm.setValue('provider', branchData.name);
        if (branchData.address?.city) {
            studentSeriesAttendanceForm.setValue('providerCity', branchData.address.city);
        }
    }, [branchData, studentSeriesAttendanceForm]);

    const hasBranch = Boolean(branchData?.id);
    const showSeriesFields = studentForm.watch('addToSeries') as FormFieldValues<boolean>;
    const disableStudentSeriesAttendance = !hasBranch || !showSeriesFields;
    const seriesAttendanceAutocompletes: FormAutocompletes = {
        student: { hidden: true, disabled: true, apiStaticParams: {} },
        series: { disabled: disableStudentSeriesAttendance, apiStaticParams: { branchId: branchData?.id } },
    };
    const [studentId, setStudentId] = useState<string | null>(null);

    async function createOrUpdateStudent(data: StudentValidFormValues): Promise<string | null> {
        const response = await studentsService.createOrUpdateStudent({
            id: studentId ?? undefined,
            ...data,
            branchId: data.branch?.id,
        });
        if (isInitialized(response)) {
            setStudentId(response);
        }
        return response;
    }

    async function createStudentAndNavigateToList(studentValues: StudentValidFormValues): Promise<void> {
        const studentResponseId = await createOrUpdateStudent(studentValues);
        if (isInitialized(studentResponseId)) {
            showToastInfo({ infoMessage: 'STUDENT_DETAILS_SAVE_SUCCESSFULLY' });
            await history.push(APP_PATHS_FROM_ROOT.students());
        }
    }

    async function createStudentAndSeriesAndNavigateToList(studentValues: StudentValidFormValues): Promise<void> {
        const studentId = await createOrUpdateStudent(studentValues);
        if (!isInitialized(studentId)) {
            return;
        }
        const studentSeriesValues = asType<studentSeriesAttendanceFormUtil.ValidFormValues>(studentSeriesAttendanceForm.getValues());
        const studentDetails = await studentsService.readStudentDetails(studentId);
        await studentSeriesAttendanceService.createOrUpdateAttendance(
            mapFormDataToStudentSeriesAttendance(undefined, {
                ...studentSeriesValues,
                student: toAtomicType(studentDetails),
            }),
        );
        showToastInfo({ infoMessage: 'STUDENT_DETAILS_SAVE_SUCCESSFULLY' });
        await history.push(APP_PATHS_FROM_ROOT.students());
    }

    async function handleSubmitForms(ev: FormEvent<HTMLFormElement>): Promise<void> {
        // Since we have 2 forms on the screen we handle the submit manually by validating the student data and then the studentSeriesAttendance
        const isValid = await updateFormValidationAndShowErrors(studentForm, ev);
        if (!isValid) {
            return;
        }
        const studentValues = asType<StudentValidFormValues>(studentForm.getValues());
        // if we don't need to add the series details we save the student
        if (!studentValues.addToSeries) {
            await createStudentAndNavigateToList(studentValues);
            return;
        }
        // If we need to validate also the series we submit both of them
        const isStudentSeriesValid = await updateFormValidationAndShowErrors(studentSeriesAttendanceForm, ev);
        if (!isStudentSeriesValid) {
            return;
        }
        await createStudentAndSeriesAndNavigateToList(studentValues);
    }

    return (
        <ConditionalCard viewMode={viewMode} cardTitle={'STUDENT_CARD_TITLE'}>
            <form onSubmit={handleSubmitForms}>
                <StudentDetailsGrid>
                    <StudentDetailsEditorArea>
                        <StudentEditorFields form={(studentForm as unknown) as UseFormMethods<studentFromUtil.FormValues>} />
                    </StudentDetailsEditorArea>
                    <StudentRelatedDataArea>
                        <InputField
                            control={studentForm.control}
                            errors={studentForm.formState.errors}
                            type={SupportedInputType.Toggle}
                            name="addToSeries"
                            internalProps={{
                                label: t('ADD_STUDENT_WITH_SERIES'),
                            }}
                            disabled={!hasBranch}
                        />
                        {!disableStudentSeriesAttendance && (
                            <StudentSeriesAttendanceEditorFields
                                form={studentSeriesAttendanceForm}
                                formAutocompletes={seriesAttendanceAutocompletes}
                                disabled={disableStudentSeriesAttendance}
                            />
                        )}
                    </StudentRelatedDataArea>
                </StudentDetailsGrid>
                <CardActions>
                    <SubmitButton label={t('FORM_SAVE')} />
                </CardActions>
            </form>
        </ConditionalCard>
    );
}
