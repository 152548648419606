import { Dropdown, IDropdownOption } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { enumValues } from '../../utils/enumValues';
import { isInitialized } from '../../utils/isInitialized';
import { ControllerInputProps } from '../input/InputFieldTypes';

export interface InputDropDownProperties extends ControllerInputProps {
    label: string;
    // The items to be displayed
    options: IDropdownOption[];
    // The type of the enum to be used in the dropdown
    dropDownEnumType?: Record<string | number, string | number>;
    // If the type of enum is numeric enum he contains the mapping and the reverse mapping. For example enum A { A = 0, B = 1} => is the
    // following js object {A: 0, B: 1, 0: 'A', 1: 'B'}. We need to know that to parse enum values correctly
    isNumericEnum?: boolean;
}

export function InputDropDown(props: InputDropDownProperties): JSX.Element {
    const { t } = useTranslation();
    let options = props.options;

    if (isInitialized(props.dropDownEnumType)) {
        const objectValues = enumValues(props.dropDownEnumType, props.isNumericEnum);
        options = objectValues.map(
            (value: number | string, index: number): IDropdownOption => {
                return {
                    key: value,
                    text: props.isNumericEnum
                        ? isInitialized(props.dropDownEnumType)
                            ? t((props.dropDownEnumType[value as string] as string).toUpperCase())
                            : ''
                        : (value as string),
                    index: index,
                };
            },
        );
    }

    const onChange = (_: React.FormEvent<HTMLDivElement>, option: IDropdownOption | undefined): void => {
        props.onChange(option?.key);
    };

    return <Dropdown {...props} disabled={props.disabled} selectedKey={props.value} options={options} onChange={onChange} />;
}
