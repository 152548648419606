import { AxiosInstance } from 'axios';
import { PaginatedApiInput, PaginatedApiOutput, createPaginatedOutput } from '../../common/paginatedApiOutput';
import { CompanyApiModel } from './companyApiModel';

export class CompanyService {
    readonly companyEndpoint = `Company`;

    constructor(private httpClient: AxiosInstance) {}

    public async getCurrentCompany(): Promise<CompanyApiModel> {
        const firstCompanyParams: PaginatedApiInput = {
            maxCount: 1,
        };
        const { data } = await this.httpClient.get<PaginatedApiOutput<CompanyApiModel>>(`${this.companyEndpoint}/GetPage`, {
            params: firstCompanyParams,
        });
        const response = createPaginatedOutput(data);
        // If response.first we have a business logic problem
        return response.first as CompanyApiModel;
    }

    public async updateCurrentCompany(params: CompanyApiModel): Promise<CompanyApiModel> {
        const { data } = await this.httpClient.put<CompanyApiModel>(`${this.companyEndpoint}/Update`, params);
        return data;
    }
}
