export enum SeriesFormatType {
    Physical,
    Mixed,
    Online,
}

export const SeriesFormatTypeValue = {
    [SeriesFormatType.Physical]: 'PHYSICAL',
    [SeriesFormatType.Mixed]: 'MIXED',
    [SeriesFormatType.Online]: 'ONLINE',
};
