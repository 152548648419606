import { convertToString } from './convertToString';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isStringWithEntries(data: any): data is string {
    return typeof data === 'string' && data.length > 0;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function parseError(error: any): string {
    if (isStringWithEntries(error?.response)) {
        return error.response;
    }

    if (isStringWithEntries(error?.response?.data)) {
        return error.response.data;
    }

    if (isStringWithEntries(error?.message)) {
        return error?.message;
    }

    if (isStringWithEntries(error)) {
        return error;
    }

    // TODO implement backend error inspection
    return convertToString(error);
}
