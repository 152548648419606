import { AxiosInstance } from 'axios';
import { GenericCommandResultOutput } from '../features/common/paginatedApiOutput';
import { AttachmentApiModel } from '../models/attachmentApiModel';

export class AttachmentsService {
    private attachmentsUrl = 'Attachments';

    constructor(private client: AxiosInstance) {}

    public async getAttachment(id: string): Promise<AttachmentApiModel> {
        const { data } = await this.client.get<AttachmentApiModel>(`${this.attachmentsUrl}/${id}`);
        return data;
    }

    public async uploadAttachment(ownerId: string, file: File): Promise<string> {
        const attachmentEncoded = await this.toBase64(file);
        const { data } = await this.client.post<GenericCommandResultOutput>(`${this.attachmentsUrl}/Create`, {
            content: attachmentEncoded,
            mimeType: file.type,
            fileName: file.name,
        });
        return data.id as string;
    }

    private toBase64(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (): void => resolve(reader.result as string);
            reader.onerror = (error): void => reject(error);
        });
    }
}
