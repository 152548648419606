import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { Card } from '../../../componentsUx/card/Card';
import { CardActions } from '../../../componentsUx/card/CardActions';
import { CardTitle } from '../../../componentsUx/card/CardTitle';
import { InputField } from '../../../componentsUx/input/InputField';
import { SubmitButton } from '../../../componentsUx/input/SubmitButton';
import { useShowError, useShowInfo } from '../../../componentsUx/toast/toastState';
import { courseCompetencyService } from '../../../services/dataAccess';
import { setFormValues, useFpForm } from '../../../utils/useFormHook';
import { APP_PATHS_FROM_ROOT } from '../../routing/commands';
import { CourseCompetencyApiModel, CourseCompetencyType } from './courseCompetencyApiModel';
import { SupportedInputType } from '../../../componentsUx/input/InputFieldTypes';
import { stringValidator } from '../../../services/validators/stringValidator';
import { enumValuesValidator } from '../../../services/validators/enumValuesValidator';

type CourseCompetencyFormFields = Omit<CourseCompetencyApiModel, 'id' | 'courseProgramId'>;

const BranchFormSchema = yup.object().shape<CourseCompetencyFormFields>({
    description: stringValidator({ isRequired: true }),
    type: enumValuesValidator<CourseCompetencyType>({ enumRef: CourseCompetencyType, isNumericEnum: true }),
});

export function CourseCompetencyCard(parameters: {
    courseCompetencyId: string;
    isAddMode: boolean;
    branchId: string;
    courseProgramId: string;
}): JSX.Element {
    const { t } = useTranslation();
    const [courseCompetency, setCourseCompetency] = useState<Partial<CourseCompetencyApiModel> | null>(null);

    const history = useHistory();

    const { control, formState, handleSubmit, setValue } = useFpForm<CourseCompetencyFormFields>({
        schema: BranchFormSchema,
        defaultValues: {
            description: '',
            type: CourseCompetencyType.Practical,
        },
    });
    const showToastInfo = useShowInfo();
    const showToastError = useShowError();

    useEffect(() => {
        const readCourseCompetency = async (): Promise<void> => {
            const courseCompetencyDetails = parameters.isAddMode
                ? { description: '', type: CourseCompetencyType.Practical }
                : await courseCompetencyService.getCourseCompetency(parameters.courseCompetencyId);
            setCourseCompetency(courseCompetencyDetails);
            setFormValues(courseCompetencyDetails, setValue);
        };

        readCourseCompetency();
    }, [setValue, parameters.courseCompetencyId, parameters.isAddMode]);

    async function saveCourseProgramDetails(params: CourseCompetencyFormFields): Promise<void> {
        if (!courseCompetency) {
            return;
        }
        const courseProgramId = courseCompetency.courseProgramId ?? parameters.courseProgramId;
        if (!courseProgramId) {
            showToastError({ infoMessage: t('COURSE_COMPETENCY_CARD_CREATE_UNSUCCESFULLY') });
            return;
        }
        const courseCompetencyData = {
            id: parameters.courseCompetencyId,
            courseProgramId: courseProgramId,
            ...params,
        };

        parameters.isAddMode
            ? await courseCompetencyService.addCourseCompetency(courseCompetencyData)
            : await courseCompetencyService.updateCourseCompetency(courseCompetencyData);

        showToastInfo({ infoMessage: t('COURSE_COMPETENCY_CARD_SAVE_SUCCESFULLY') });
        history.push(APP_PATHS_FROM_ROOT.courseProgramPage(parameters.branchId, courseProgramId));
    }

    return (
        <Card>
            <CardTitle>{t('COURSE_COMPETENCY_CARD_TITLE')}</CardTitle>
            <form onSubmit={handleSubmit(saveCourseProgramDetails)}>
                <InputField
                    control={control}
                    errors={formState.errors}
                    type={SupportedInputType.TextArea}
                    name="description"
                    internalProps={{
                        label: t('COURSE_COMPETENCY_CARD_FIELD_DESCRIPTION'),
                        multiline: true,
                        rows: 3,
                    }}
                />
                <InputField
                    control={control}
                    errors={formState.errors}
                    name="type"
                    type={SupportedInputType.DropDown}
                    required={true}
                    internalProps={{
                        label: t('COURSE_COMPETENCY_CARD_FIELD_TYPE'),
                        dropDownEnumType: CourseCompetencyType,
                        isNumericEnum: true,
                    }}
                />
                <CardActions>
                    <SubmitButton label={t('FORM_SAVE')} />
                </CardActions>
            </form>
        </Card>
    );
}
