import React, { ReactElement } from 'react';
import { Breadcrumb as BaseBreadcrumb, IBreadcrumbItem } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { device } from '../cssUtils/breakpoints';

export interface IBreadcrumbProps {
    items: IBreadcrumbItem[];
}

const MAX_DISPLAYED_ITEMS = 10;
const OVERFLOW_ARIA_LABEL = 'BREADCRUMB_MORE_LINKS';

const StyledBreadcrumb = styled(BaseBreadcrumb)`
    @media ${device.featurePhone} {
        padding-bottom: 24px;
    }

    @media ${device.tabletPortrait} {
        padding-bottom: 30px;
    }

    @media ${device.desktop} {
        padding-bottom: 36px;
    }

    * {
        font-size: 14px;
    }

    .ms-Breadcrumb-itemLink:hover {
        background: none;
        color: ${(props): string => props.theme.colors.primary};
    }

    .ms-Breadcrumb-itemLink {
        margin-bottom: -1px;
    }

    .ms-Breadcrumb-item {
        color: ${(props): string => props.theme.colors.primary} !important;
    }
`;

export const Breadcrumb = (props: IBreadcrumbProps): ReactElement => {
    const { items } = props;
    const { t } = useTranslation();

    return <StyledBreadcrumb items={items} maxDisplayedItems={MAX_DISPLAYED_ITEMS} overflowAriaLabel={t(OVERFLOW_ARIA_LABEL)} />;
};
