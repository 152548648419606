import { loadTheme } from '@fluentui/react';

const fluentUITheme = loadTheme({});

const colors = {
    // Main color used in titles, navigation actions
    primary: '#20a980',
    // Used in highlighting important information and CRUD actions
    secondary1: '#3976e9',
    // Used for borders, dividers, outlined cards
    secondary2: '#616d6d',
    background: '#f5f8ff',
    cardBackground: '#ffffff',
    headerBackground: '#ffffff',
    //used for the details of the DetailsList
    listDetails: 'rgb(243, 242, 241)',
};

const customTheme = { colors };

export const theme = { ...fluentUITheme, ...customTheme };
