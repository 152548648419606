import React from 'react';
import { Control } from 'react-hook-form/dist/types';
import { DictionaryOf } from '../../utils/dictionaryType';
import { InputFieldSpacing } from '../input/ControlledInputField';
import { InputField } from '../input/InputField';
import { SupportedInputType } from '../input/InputFieldTypes';
import { BranchPicker } from './BranchPicker';

interface Props {
    name: string;
    label: string;
    control: Control;
    formState: { errors: DictionaryOf<unknown> };
    disabled?: boolean;
    onChange?: (...event: any[]) => void;
}

export function InputBranchPicker(props: Props): JSX.Element {
    return (
        <InputField
            control={props.control}
            name={props.name}
            errors={props.formState.errors}
            type={SupportedInputType.Custom}
            required={true}
            customField={(fieldProps): JSX.Element => {
                return (
                    <InputFieldSpacing>
                        <BranchPicker
                            label={props.label}
                            disabled={props.disabled}
                            {...fieldProps}
                            onChange={(branch): void => {
                                fieldProps.onChange(branch);
                                props.onChange?.(branch);
                            }}
                        />
                    </InputFieldSpacing>
                );
            }}
        />
    );
}
