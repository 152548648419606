import { AxiosInstance } from 'axios';
import { GenericCommandResultOutput } from './paginatedApiOutput';

interface DeleteApiModel {
    entityId: string;
    entityName: string;
    isFullDelete: boolean;
}

export class DeletionService {
    readonly deleteEndpoint = `Delete`;

    constructor(private httpClient: AxiosInstance) {}

    public async delete(entityName: string, entityId: string): Promise<GenericCommandResultOutput> {
        const response = await this.httpClient.delete<DeleteApiModel, GenericCommandResultOutput>(this.deleteEndpoint, {
            data: { entityId: entityId, entityName: entityName, isFulDelete: false },
        });
        return response;
    }
}
