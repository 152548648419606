import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from './Card';
import { CardTitle } from './CardTitle';
import { ResponsiveBody } from './ResponsiveBody';

export type ConditionalCardViewMode = 'card' | 'flat' | 'card-responsive';
type Props = PropsWithChildren<{ viewMode: 'card'; cardTitle?: string } | { viewMode: 'card-responsive'; cardTitle?: string } | { viewMode: 'flat' }>;

export function ConditionalCard(props: Props): JSX.Element {
    const { t } = useTranslation();
    if (props.viewMode === 'card' || props.viewMode === 'card-responsive') {
        const renderCardTitle = props.cardTitle ? <CardTitle>{t(props.cardTitle)}</CardTitle> : <></>;
        const renderCardContent = props.viewMode === 'card-responsive' ? <ResponsiveBody>{props.children}</ResponsiveBody> : <>{props.children}</>;
        return (
            <Card>
                {renderCardTitle}
                {renderCardContent}
            </Card>
        );
    }

    return <>{props.children}</>;
}
