import { useState, Dispatch, SetStateAction } from 'react';
import { DateToFilter } from '../../features/common/dateToFilter';
import { BranchOwnedPaginatedApiInput, PaginatedApiInput, SeriesPaginatedApiInput } from '../../features/common/paginatedApiOutput';

export type PaginationInputs = Required<PaginatedApiInput>;
export type BranchOwnedPaginationInputs = Required<BranchOwnedPaginatedApiInput>;
export type SeriesPaginationInputs = Required<SeriesPaginatedApiInput>;

function getMainPaginationInputs(initialData?: Partial<PaginatedApiInput>): PaginationInputs {
    return {
        skip: initialData?.skip ?? 0,
        maxCount: initialData?.maxCount ?? 10,
        searchText: initialData?.searchText ?? '',
        orderByProperty: initialData?.orderByProperty ?? '',
        isDescending: initialData?.isDescending ?? false,
    };
}

export function useListInput(initialData?: Partial<PaginatedApiInput>): [PaginationInputs, Dispatch<SetStateAction<PaginationInputs>>] {
    return useState<PaginationInputs>({
        ...getMainPaginationInputs(initialData),
    });
}

export function useBranchListListInput(
    initialData: Partial<BranchOwnedPaginatedApiInput> & { branchId: string },
): [BranchOwnedPaginationInputs, Dispatch<SetStateAction<BranchOwnedPaginationInputs>>] {
    return useState<BranchOwnedPaginationInputs>({
        ...getMainPaginationInputs(initialData),
        branchId: initialData.branchId,
    });
}

export function useSeriesListInput(
    initialData?: Partial<SeriesPaginatedApiInput>,
): [SeriesPaginationInputs, Dispatch<SetStateAction<SeriesPaginationInputs>>] {
    return useState<SeriesPaginationInputs>({
        ...getMainPaginationInputs(initialData),
        orderByProperty: 'programName',
        showClosed: false,
        startDateFilter: '',
        endDateFilter: '',
        dateToFilter: DateToFilter.StartDate,
    });
}
