import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../componentsUx/card/Card';
import { CardTitle } from '../../../componentsUx/card/CardTitle';
import * as yup from 'yup';
import { AuthorizationCommissionApiModel, AuthorizationCommissionApiModelRelations } from './authorizationCommissionApiModel';
import { stringValidator } from '../../../services/validators/stringValidator';
import { AddressForm } from '../../address/AddressForm';
import { setFormValues, useFpForm } from '../../../utils/useFormHook';
import { InputField } from '../../../componentsUx/input/InputField';
import { CardActions } from '../../../componentsUx/card/CardActions';
import { SubmitButton } from '../../../componentsUx/input/SubmitButton';
import { useShowError, useShowInfo } from '../../../componentsUx/toast/toastState';
import { authorizationCommissionService } from '../../../services/dataAccess';
import { defaultMissingAddressValues } from '../../address/addressApiModel';
import { BranchStaffMembersListCard } from '../branchStaffMember/BranchStaffMemberListCard';

interface AuthorizationCommissionDetailsCardProps {
    branchId: string;
}

type AuthorizationCommissionWithAddress = AuthorizationCommissionApiModel & AuthorizationCommissionApiModelRelations.Address;
type AuthorizationCommissionFormFileds = Omit<AuthorizationCommissionWithAddress, 'id'>;

const AuthorizationCommissionSchema = yup.object().shape<AuthorizationCommissionFormFileds>({
    phoneNumber: stringValidator(),
    email: stringValidator(),
    address: yup.object(),
});

export function AuthorizationCommissionDetailsCard(props: AuthorizationCommissionDetailsCardProps): JSX.Element {
    const { branchId } = props;

    const [t] = useTranslation();
    const [authorizationCommission, setAuthorizationCommission] = useState<Partial<AuthorizationCommissionWithAddress> | null>(null);
    const { control, formState, handleSubmit, setValue, getValues } = useFpForm<AuthorizationCommissionFormFileds>({
        schema: AuthorizationCommissionSchema,
        defaultValues: {
            phoneNumber: '',
            email: '',
            address: defaultMissingAddressValues(),
        },
    });

    useEffect(() => {
        const readAuthorizationCommission = async (): Promise<void> => {
            const emptyModel = {
                phoneNumber: '',
                email: '',
            };
            const authorizationCommissionDetails =
                (await authorizationCommissionService.getAuthorizationCommissionDetails(props.branchId)) || emptyModel;

            setAuthorizationCommission(authorizationCommissionDetails);
            setFormValues(authorizationCommissionDetails, setValue);
        };

        readAuthorizationCommission();
    }, [setValue, props.branchId]);

    const showToastInfo = useShowInfo();
    const showToastError = useShowError();

    async function saveAuthorizationCommissionDetails(params: AuthorizationCommissionFormFileds): Promise<void> {
        if (!branchId) {
            return;
        }

        let authorizationCommissionId: string | null = null;

        if (authorizationCommission && authorizationCommission.id) {
            authorizationCommissionId = await authorizationCommissionService.updateAuthorizationCommission({
                id: authorizationCommission.id,
                ...params,
            });
        } else {
            authorizationCommissionId = await authorizationCommissionService.addAuhtorizationCommission({ branchId, ...params });
        }

        if (authorizationCommissionId) {
            setAuthorizationCommission({ id: authorizationCommissionId, ...params });
            showToastInfo({ infoMessage: t('AUTHORIZATION_COMMISSION_DETAILS_SAVE_SUCCESFULLY') });
        } else {
            showToastError({ infoMessage: t('AUTHORIZATION_COMMISSION_DETAILS_SAVE_UNSUCCESFULLY') });
        }
    }

    return (
        <Card>
            <CardTitle>{t('AUTHORIAZTION_COMMISSION_DETAILS_CARD_TITLE')}</CardTitle>
            <form onSubmit={handleSubmit(saveAuthorizationCommissionDetails)}>
                <InputField
                    control={control}
                    errors={formState.errors}
                    name="phoneNumber"
                    internalProps={{
                        label: t('BRANCH_CARD_FIELD_PHONE_NUMBER'),
                    }}
                />
                <InputField
                    control={control}
                    errors={formState.errors}
                    name="email"
                    internalProps={{
                        label: t('BRANCH_CARD_EMAIL'),
                    }}
                />
                <AddressForm control={control} errors={formState.errors} name="address" getValues={getValues} />

                <CardActions>
                    <SubmitButton label={t('FORM_SAVE')} />
                </CardActions>
            </form>
            <BranchStaffMembersListCard branchId={branchId} />
        </Card>
    );
}
