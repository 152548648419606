import { IColumn } from '@fluentui/react';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Card } from '../../../componentsUx/card/Card';
import { CardTitle } from '../../../componentsUx/card/CardTitle';
import { PaginatedList } from '../../../componentsUx/paginatedList/PaginatedList';
import { useListInput } from '../../../componentsUx/paginatedList/useListInput';
import { branchService } from '../../../services/dataAccess';
import { useSession } from '../../../state/SessionState';
import { createDefaultPaginatedOutput } from '../../common/paginatedApiOutput';
import { APP_PATHS_FROM_ROOT } from '../../routing/commands';
import { BranchListApiModel, BranchListItemApiModel } from './branchApiModel';

export function BranchesListCard(): JSX.Element {
    const [t] = useTranslation();
    const history = useHistory();
    const [listInput, setListInput] = useListInput({
        orderByProperty: 'name',
    });
    const [branchList, setBranchList] = useState<BranchListApiModel>(createDefaultPaginatedOutput());

    const readBranches = async (): Promise<void> => {
        const branchListData = await branchService.readBranches(listInput);
        setBranchList(branchListData);
    };
    const [session] = useSession();

    useEffect(() => {
        readBranches();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listInput]);

    const onItemClicked = (model: BranchListItemApiModel): void => {
        history.push(APP_PATHS_FROM_ROOT.branchPage(model.id));
    };

    const onAdd = (): void => {
        history.push(APP_PATHS_FROM_ROOT.newBranchPage());
    };

    const commonColumnProps = {
        minWidth: 100,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: true,
    };

    const columns: IColumn[] = [
        {
            key: 'columnName',
            name: t('BRANCH_LIST_COLUMN_NAME'),
            fieldName: 'name',
            ...commonColumnProps,
        },
        {
            key: 'columnCity',
            name: t('BRANCH_LIST_COLUMN_CITY'),
            fieldName: 'city',
            ...commonColumnProps,
        },
        {
            key: 'columnStreet',
            name: t('BRANCH_LIST_COLUMN_STREET'),
            fieldName: 'street',
            ...commonColumnProps,
        },
        {
            key: 'columnNumber',
            name: t('BRANCH_LIST_COLUMN_NUMBER'),
            fieldName: 'number',
            ...commonColumnProps,
        },
    ];

    return (
        <Card>
            <CardTitle>{t('BRANCH_LIST_TITLE')}</CardTitle>
            <PaginatedList
                entityType="Branch"
                onItemClicked={onItemClicked}
                searchTextLabel={t('SEARCH_BY_NAME_OR_CITY')}
                model={branchList}
                pagination={listInput}
                onReadPage={setListInput}
                columns={columns}
                allowAdd={session.isCompanyAdmin}
                onAdd={onAdd}
            />
        </Card>
    );
}
