import { BaseApiModel } from '../../common/baseApiModel';
import { PaginatedOutput } from '../../common/paginatedApiOutput';

export enum CourseCompetencyType {
    Practical,
    Theoretical,
    Undefined,
}

export interface CourseCompetencyApiModel extends BaseApiModel {
    description: string;
    type?: CourseCompetencyType;
    courseProgramId: string;
}

export interface CourseCompetencyListItemApiModel extends BaseApiModel {
    description: string;
    type?: CourseCompetencyType;
}

export type CourseCompetencyListApiModel = PaginatedOutput<CourseCompetencyListItemApiModel>;
