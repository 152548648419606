import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import * as yup from 'yup';
import { InputField } from '../../componentsUx/input/InputField';
import { SubmitButton } from '../../componentsUx/input/SubmitButton';
import { authService } from '../../services/dataAccess';
import { SessionState } from '../../state/SessionState';
import { useFpForm } from '../../utils/useFormHook';
import { APP_PATHS_FROM_ROOT } from '../routing/commands';
import { SupportedInputType } from '../../componentsUx/input/InputFieldTypes';
import styled from 'styled-components';
import { Logo } from '../../logo/Logo';
import { stringValidator } from '../../services/validators/stringValidator';

const StyledInputField = styled(InputField)`
    .ms-Label {
        color: ${(props): string => props.theme.colors.primary};
    }
`;

const StyledLogo = styled(Logo)`
    margin: auto;
    margin-bottom: 100px;
`;

interface LoginFormFields {
    userNameOrEmail: string;
    password: string;
}

const LoginSchema = yup.object().shape<LoginFormFields>({
    userNameOrEmail: stringValidator({ isRequired: true }),
    password: stringValidator({ isRequired: true }),
    // TODO define password regex
    // .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, 'INVALID_PASSWORD'),
});

export function Login(): JSX.Element {
    const history = useHistory();
    const { t } = useTranslation();
    const { control, handleSubmit, formState } = useFpForm<LoginFormFields>({
        schema: LoginSchema,
    });
    const setSession = useSetRecoilState(SessionState);

    async function loginUser(userFields: LoginFormFields): Promise<void> {
        const loginResponse = await authService.login(userFields);
        if (loginResponse.isLogged()) {
            setSession(loginResponse);
            history.push(APP_PATHS_FROM_ROOT.dashboard());
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(loginUser)}>
                <StyledLogo width={300} />
                <StyledInputField
                    control={control}
                    errors={formState.errors}
                    name="userNameOrEmail"
                    defaultValue=""
                    internalProps={{
                        label: t('LOGIN_USER_NAME'),
                    }}
                />
                <StyledInputField
                    type={SupportedInputType.Password}
                    control={control}
                    errors={formState.errors}
                    name="password"
                    defaultValue=""
                    internalProps={{
                        label: t('LOGIN_PASSWORD'),
                    }}
                />
                <SubmitButton label={t('LOGIN_SUBMIT')} />
            </form>
        </>
    );
}
