import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { Breadcrumb } from '../../../componentsUx/breadcrumb/Breadcrumb';
import { APP_PATHS_FROM_ROOT } from '../../routing/commands';
import { BranchStaffMemberCard } from './BranchStaffMemberCard';

export const BranchStaffMember = (): ReactElement => {
    const { t } = useTranslation();
    const history = useHistory();
    const { id, branchId } = useParams<{ id: string; branchId: string }>();

    const breadcrumbItems = [
        {
            text: t('HOME_PAGE_NAV_ACTION_COMPANY'),
            key: 'HOME_PAGE_NAV_ACTION_COMPANY',
            onClick: (): void => history.push(APP_PATHS_FROM_ROOT.companyPage()),
        },
        {
            text: t('BRANCH_CARD_TITLE'),
            key: 'BRANCH_CARD_TITLE',
            onClick: (): void => history.push(APP_PATHS_FROM_ROOT.branchPage(branchId)),
        },
        {
            text: t('BRANCH_STAFF_MEMBER_CARD_TITLE'),
            key: 'BRANCH_STAFF_MEMBER_CARD_TITLE',
            isCurrentItem: true,
        },
    ];

    return (
        <>
            <Breadcrumb items={breadcrumbItems} />
            <BranchStaffMemberCard branchStaffMemberId={id} branchId={branchId} />
        </>
    );
};
