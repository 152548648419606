import { ProgressIndicator } from '@fluentui/react';
import React, { memo } from 'react';
import { atom, useSetRecoilState, selector, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { createInitialLoader, markNotLoading, markLoading, track, detachLoader } from './models/componentLoader';

const key = 'global-loader-data';

export const GlobalLoaderState = atom({
    key,
    default: createInitialLoader(),
});

const GlobalLoaderSelector = selector({
    key: 'filteredTodoListState',
    get: ({ get }) => {
        const loaderState = get(GlobalLoaderState);

        const isLoading = loaderState.loadersCount > 0;
        const isBlocked = loaderState.isBlockedCount > 0;
        return { isLoading, isBlocked };
    },
});

interface GlobalLoaderApi {
    markLoading(id: string, shouldBlockScreen?: boolean): void;
    markNotLoading(id: string, unblockNow?: boolean): void;
    track<T>(source: Promise<T>): Promise<T>;
    detachLoader(id: string): void;
}

let GlobalLoader: GlobalLoaderApi | null;
export function useGlobalLoader(): GlobalLoaderApi {
    // This api is global and it never changes but because it's dependent on useSetRecoilState we have to make a hook for it so we are able
    // To call it from components
    const setLoaderState = useSetRecoilState(GlobalLoaderState);
    if (!GlobalLoader) {
        // We have to create the object only once otherwise effects which depend on this will rerun.
        GlobalLoader = {
            markNotLoading: (id: string, unblockNow?: boolean): void => {
                return markNotLoading({ id, unblockNow, setLoaderState });
            },
            markLoading: (id: string, shouldBlockScreen?: boolean): void => {
                return markLoading({ id, shouldBlockScreen, setLoaderState });
            },
            track<T>(source: Promise<T>): Promise<T> {
                return track({ source, setLoaderState });
            },
            detachLoader(id: string): void {
                return detachLoader({ id, setLoaderState });
            },
        };
    }
    return GlobalLoader;
}

const BlockAppOverlay = styled.div`
    position: fixed;
    z-index: 10001;
    pointer-events: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;
const GlobalProgressBarPosition = styled.div`
    position: fixed;
    z-index: 10001;
    pointer-events: none;
    top: 0;
    left: 0;
    right: 0;
`;

export function GlobalProgressBar(): JSX.Element {
    return (
        <GlobalProgressBarPosition>
            <ProgressIndicator className="fp-global-loader" barHeight={4} />
        </GlobalProgressBarPosition>
    );
}

export function GlobalLoaderSetupFn(): JSX.Element {
    const globalLoader = useRecoilValue(GlobalLoaderSelector);

    return (
        <>
            {globalLoader.isLoading ? <GlobalProgressBar /> : null}
            {globalLoader.isBlocked ? <BlockAppOverlay /> : null}
        </>
    );
}

export const GlobalLoaderSetup = memo(GlobalLoaderSetupFn);
