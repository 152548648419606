import { atom, useRecoilState, useSetRecoilState } from 'recoil';
import { BranchApiModel } from '../features/home/branch/branchApiModel';
import React, { useEffect } from 'react';
import { SessionState } from './SessionState';
import { branchService } from '../services/dataAccess';

const key = 'UNIQUE_BRANCH';

export const UniqueBranchState = atom<BranchApiModel | null>({
    key,
    default: null,
});

export function UniqueBranchSetup(): JSX.Element {
    const setUniqueBranch = useSetRecoilState(UniqueBranchState);
    const [sessionData] = useRecoilState(SessionState);

    useEffect(() => {
        const readBranch = async (): Promise<void> => {
            const branchIds = sessionData.getBranchIds();

            if (branchIds.length === 1) {
                const branch = await branchService.getBranch(branchIds[0]);
                setUniqueBranch(branch);
                return;
            }
            setUniqueBranch(null);
        };
        readBranch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionData]);
    return <></>;
}
