import { DictionaryOf } from '../../utils/dictionaryType';
import * as yup from 'yup';
import { MixedSchema } from 'yup';
import { ErrorCode } from './errors.util';

export function enumValuesValidator<T>(options: { enumRef: DictionaryOf<unknown>; isNumericEnum?: boolean }): yup.MixedSchema<T> {
    let enumValues = Object.values(options.enumRef);

    if (options.isNumericEnum) {
        // Numeric enums contain also the reverse mapping
        const keys = Object.keys(options.enumRef).filter((key) => typeof options.enumRef[key as any] === 'number');
        enumValues = keys.map((key) => options.enumRef[key as any]);
    }

    const validator = yup
        .mixed<T>()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .oneOf(enumValues, ErrorCode.Required);

    return validator as MixedSchema<T>;
}
