import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { Card } from '../../../componentsUx/card/Card';
import { CardActions } from '../../../componentsUx/card/CardActions';
import { CardTitle } from '../../../componentsUx/card/CardTitle';
import { InputFieldSpacing } from '../../../componentsUx/input/ControlledInputField';
import { InputField } from '../../../componentsUx/input/InputField';
import { ControllerInputProps, SupportedInputType } from '../../../componentsUx/input/InputFieldTypes';
import { SubmitButton } from '../../../componentsUx/input/SubmitButton';
import { InputBranchPicker } from '../../../componentsUx/inputBranchPicker/InputBranchPicker';
import { InputPicker } from '../../../componentsUx/inputPicker/InputPicker';
import { useShowError, useShowInfo } from '../../../componentsUx/toast/toastState';
import { seriesService } from '../../../services/dataAccess';
import { dateValidator } from '../../../services/validators/dateValidator';
import { numberValidator } from '../../../services/validators/numberValidator';
import { requiredPickerValue } from '../../../services/validators/pickerValueValidator';
import { stringValidator } from '../../../services/validators/stringValidator';
import { convertToString } from '../../../utils/convertToString';
import { setFormValues, useFpForm } from '../../../utils/useFormHook';
import { ContributorType } from '../../common/contributorType';
import { SeriesFormatType } from '../../common/seriesFormatType';
import { APP_PATHS_FROM_ROOT, isNewEntityId } from '../../routing/commands';
import { BranchApiModel } from '../branch/branchApiModel';
import { BranchStaffMemberApiModel } from '../branchStaffMember/branchStaffMemberApiModel';
import { CourseProgramListItemApiModel } from '../courseProgram/courseProgramApiModel';
import { SeriesDetailsResponse } from './seriesService';

type SeriesFormFields = Omit<Omit<SeriesDetailsResponse, 'id'>, 'companyId'>;

const SeriesFormSchema = (): yup.ObjectSchema => {
    return yup.object().shape({
        seriesFormat: yup.number(),
        theoreticalPeriodStartDate: dateValidator({ isRequired: true }),
        theoreticalPeriodEndDate: dateValidator({ isRequired: true }).notRequired().default(undefined),
        practicalPeriodStartDate: dateValidator({ isRequired: true }).notRequired().default(undefined),
        practicalPeriodEndDate: dateValidator({ isRequired: true }),
        examDate: dateValidator({ isRequired: true }),
        branch: requiredPickerValue(),
        program: requiredPickerValue(),
        president: requiredPickerValue(),
        trainerOne: yup.object(),
        trainerTwo: yup.object(),
        seriesNumber: stringValidator({ isRequired: true }),
        observations: stringValidator(),
        maximumStudents: numberValidator({ isRequired: true }),
    });
};

export function SeriesCard(parameters: { seriesId: string; series: SeriesDetailsResponse | null }): JSX.Element {
    const { t } = useTranslation();
    const [seriesId, setSeriesId] = useState<string>(parameters.seriesId ?? ``);
    const series = parameters.series;
    const { control, formState, handleSubmit, setValue, getValues, watch } = useFpForm<SeriesFormFields>({
        schema: SeriesFormSchema(),
        defaultValues: {
            branch: {
                name: '',
            },
            program: {},
            president: {
                firstName: '',
                lastName: '',
            },
            firstTrainer: {
                firstName: '',
                lastName: '',
            },
            secondTrainer: {
                firstName: '',
                lastName: '',
            },
            seriesFormat: SeriesFormatType.Physical,
            theoreticalPeriodStartDate: new Date(),
            theoreticalPeriodEndDate: new Date(),
            practicalPeriodStartDate: new Date(),
            practicalPeriodEndDate: new Date(),
            examDate: new Date(),
            observations: '',
            seriesNumber: '',
            maximumStudents: 28,
        },
    });

    const seriesFormatIsPhysical = watch('seriesFormat') === SeriesFormatType.Physical;
    const startDateLabel = seriesFormatIsPhysical ? 'SERIES_LIST_CARD_FIELD_START_DATE' : 'SERIES_LIST_CARD_FIELD_THEORETICAL_PERIOD_START_DATE';
    const endDateLabel = seriesFormatIsPhysical ? 'SERIES_LIST_CARD_FIELD_END_DATE' : 'SERIES_LIST_CARD_FIELD_PRACTICAL_PERIOD_END_DATE';
    const history = useHistory();
    const isInAddMode = isNewEntityId(seriesId);
    const showToastInfo = useShowInfo();
    const showToastError = useShowError();

    useEffect(() => {
        if (!series) {
            return;
        }

        setFormValues(series, setValue);
    }, [series, setValue]);

    useEffect(() => {
        if (!seriesFormatIsPhysical) {
            setValue('theoreticalPeriodEndDate', series?.theoreticalPeriodEndDate || new Date());
            setValue('practicalPeriodStartDate', series?.practicalPeriodStartDate || new Date());
        }
    }, [seriesFormatIsPhysical, series, setValue]);

    const saveSeriesDetails = async (params: SeriesFormFields): Promise<void> => {
        if (!params) {
            return;
        }

        const seriesData = {
            id: seriesId,
            programId: params.program.id,
            presidentId: params.president.id,
            firstTrainerId: params.firstTrainer ? params.firstTrainer.id : null,
            secondTrainerId: params.secondTrainer ? params.secondTrainer.id : null,
            ...params,
            theoreticalPeriodEndDate: seriesFormatIsPhysical ? params.theoreticalPeriodStartDate : params.theoreticalPeriodEndDate,
            practicalPeriodStartDate: seriesFormatIsPhysical ? params.practicalPeriodEndDate : params.practicalPeriodStartDate,
            branchId: params.branch.id,
        };

        if (isInAddMode) {
            const seriesId = await seriesService.addSeries(seriesData);
            if (seriesId) {
                setSeriesId(seriesId);
                showToastInfo({ infoMessage: t('SERIES_CARD_CREATED_SUCCESSFULLY') });
                history.push(APP_PATHS_FROM_ROOT.seriesPage(seriesId));
            } else {
                showToastError({ infoMessage: t('SERIES_CARD_CREATED_FAILED') });
            }
        } else {
            await seriesService.updateSeries(seriesData);
            showToastInfo({ infoMessage: t('SERIES_CARD_UPDATED_SUCCESSFULLY') });
        }
    };

    function onBranchChange(_: BranchApiModel): void {
        setValue('program', {});
        setValue('president', {});
    }

    function getBranchId(): string {
        return getValues().branch?.id;
    }

    return (
        <Card>
            <CardTitle>{t('SERIE_CARD_TITLE')}</CardTitle>
            <form onSubmit={handleSubmit(saveSeriesDetails)}>
                <InputBranchPicker
                    control={control}
                    formState={formState}
                    name="branch"
                    label={t('STUDENT_FIELD_BRANCH_ID')}
                    disabled={!isInAddMode}
                    onChange={onBranchChange}
                />
                <InputField
                    control={control}
                    errors={formState.errors}
                    name="program"
                    type={SupportedInputType.Custom}
                    required={true}
                    customField={(props: ControllerInputProps): JSX.Element => (
                        <InputFieldSpacing>
                            <InputPicker
                                {...props}
                                label={t('SERIE_CARD_FIELD_PROGRAM_NAME')}
                                disabled={!getBranchId()}
                                apiEndpoint="CourseProgram"
                                getApiParams={(): Record<string, unknown> => {
                                    return {
                                        branchId: getBranchId(),
                                    };
                                }}
                                allowFreeText={false}
                                displayItem={(b: CourseProgramListItemApiModel): string => convertToString(b?.specializationName)}
                                renderItem={(b: CourseProgramListItemApiModel): JSX.Element => (
                                    <div>
                                        {b.specializationName} {b.specializationCode}
                                    </div>
                                )}
                            />
                        </InputFieldSpacing>
                    )}
                />
                <InputField
                    control={control}
                    errors={formState.errors}
                    name="president"
                    type={SupportedInputType.Custom}
                    required={true}
                    customField={(props: ControllerInputProps): JSX.Element => (
                        <InputFieldSpacing>
                            <InputPicker
                                {...props}
                                label={t('PRESIDENT')}
                                disabled={!getBranchId()}
                                apiEndpoint="BranchStaffMember"
                                getApiParams={(): Record<string, unknown> => {
                                    return {
                                        branchId: getBranchId(),
                                        contributorType: ContributorType.External,
                                    };
                                }}
                                allowFreeText={false}
                                displayItem={(b: BranchStaffMemberApiModel): string => `${b?.firstName ?? ''} ${b?.lastName ?? ''}`}
                                renderItem={(b: BranchStaffMemberApiModel): JSX.Element => (
                                    <div>
                                        {b?.firstName} {b?.lastName}
                                    </div>
                                )}
                            />
                        </InputFieldSpacing>
                    )}
                />
                <InputField
                    control={control}
                    errors={formState.errors}
                    name="firstTrainer"
                    type={SupportedInputType.Custom}
                    customField={(props: ControllerInputProps): JSX.Element => (
                        <InputFieldSpacing>
                            <InputPicker
                                {...props}
                                label={t('SERIES_LIST_CARD_FIELD_TRAINER_ONE')}
                                disabled={!getBranchId()}
                                apiEndpoint="BranchStaffMember"
                                getApiParams={(): Record<string, unknown> => {
                                    return {
                                        branchId: getBranchId(),
                                        contributorType: ContributorType.Internal,
                                    };
                                }}
                                allowFreeText={false}
                                displayItem={(b: BranchStaffMemberApiModel): string => `${b?.firstName ?? ''} ${b?.lastName ?? ''}`}
                                renderItem={(b: BranchStaffMemberApiModel): JSX.Element => (
                                    <div>
                                        {b?.firstName} {b?.lastName}
                                    </div>
                                )}
                            />
                        </InputFieldSpacing>
                    )}
                />
                <InputField
                    control={control}
                    errors={formState.errors}
                    name="secondTrainer"
                    type={SupportedInputType.Custom}
                    customField={(props: ControllerInputProps): JSX.Element => (
                        <InputFieldSpacing>
                            <InputPicker
                                {...props}
                                label={t('SERIES_LIST_CARD_FIELD_TRAINER_TWO')}
                                disabled={!getBranchId()}
                                apiEndpoint="BranchStaffMember"
                                getApiParams={(): Record<string, unknown> => {
                                    return {
                                        branchId: getBranchId(),
                                        contributorType: ContributorType.Internal,
                                    };
                                }}
                                allowFreeText={false}
                                displayItem={(b: BranchStaffMemberApiModel): string => `${b?.firstName ?? ''} ${b?.lastName ?? ''}`}
                                renderItem={(b: BranchStaffMemberApiModel): JSX.Element => (
                                    <div>
                                        {b?.firstName} {b?.lastName}
                                    </div>
                                )}
                            />
                        </InputFieldSpacing>
                    )}
                />
                <InputField
                    control={control}
                    errors={formState.errors}
                    name="seriesNumber"
                    type={SupportedInputType.Text}
                    required={true}
                    internalProps={{
                        label: t('SERIES_LIST_CARD_FIELD_NUMBER'),
                    }}
                />
                <InputField
                    control={control}
                    errors={formState.errors}
                    name="seriesFormat"
                    type={SupportedInputType.DropDown}
                    internalProps={{
                        label: t('SERIES_LIST_CARD_FIELD_SERIES_FORMAT'),
                        dropDownEnumType: SeriesFormatType,
                        isNumericEnum: true,
                    }}
                />
                <InputField
                    control={control}
                    errors={formState.errors}
                    name="theoreticalPeriodStartDate"
                    type={SupportedInputType.DatePicker}
                    required={true}
                    internalProps={{
                        label: t(startDateLabel),
                    }}
                />
                {!seriesFormatIsPhysical && (
                    <>
                        <InputField
                            control={control}
                            errors={formState.errors}
                            name="theoreticalPeriodEndDate"
                            type={SupportedInputType.DatePicker}
                            required={true}
                            internalProps={{
                                label: t('SERIES_LIST_CARD_FIELD_THEORETICAL_PERIOD_END_DATE'),
                            }}
                        />
                        <InputField
                            control={control}
                            errors={formState.errors}
                            name="practicalPeriodStartDate"
                            type={SupportedInputType.DatePicker}
                            required={true}
                            internalProps={{
                                label: t('SERIES_LIST_CARD_FIELD_PRACTICAL_PERIOD_START_DATE'),
                            }}
                        />
                    </>
                )}
                <InputField
                    control={control}
                    errors={formState.errors}
                    name="practicalPeriodEndDate"
                    type={SupportedInputType.DatePicker}
                    required={true}
                    internalProps={{
                        label: t(endDateLabel),
                    }}
                />
                <InputField
                    control={control}
                    errors={formState.errors}
                    name="examDate"
                    type={SupportedInputType.DatePicker}
                    required={true}
                    internalProps={{
                        label: t('SERIES_LIST_CARD_FIELD_EXAM_DATE'),
                    }}
                />
                <InputField
                    control={control}
                    errors={formState.errors}
                    name="maximumStudents"
                    type={SupportedInputType.Number}
                    required={true}
                    internalProps={{
                        label: t('SERIES_LIST_CARD_FIELD_MAXIMUM_STUDENTS'),
                    }}
                />
                <InputField
                    control={control}
                    errors={formState.errors}
                    name="observations"
                    type={SupportedInputType.TextArea}
                    internalProps={{
                        label: t('SERIES_LIST_CARD_FIELD_OBSERVATIONS'),
                    }}
                />
                <CardActions>
                    <SubmitButton label={t('FORM_SAVE')} />
                </CardActions>
            </form>
        </Card>
    );
}
