import styled from 'styled-components';
import { device } from '../../componentsUx/cssUtils/breakpoints';

export const FiltersArea = styled.div`
    display: flex;
    flex: 2;

    > * {
        flex: 1;
    }

    @media ${device.featurePhone} {
        flex-direction: column;

        > * {
            margin-left: 0;
        }
    }

    @media ${device.tabletPortrait} {
        flex-direction: row;

        > *:not(:first-child) {
            margin-left: 15px;
        }

        > * {
            max-width: 300px;
        }
    }
`;
