import React, { ReactElement } from 'react';
import { Image } from '@fluentui/react';
import styled from 'styled-components';
import LogoImage from './LogoImage.png';

interface LogoProps {
    width: number;
    className?: string;
    onClick?: () => void;
}

const StyledImage = styled(Image)`
    cursor: pointer;
`;

export const Logo = (props: LogoProps): ReactElement => (
    <StyledImage src={LogoImage} width={props.width} className={props.className} onClick={props.onClick} />
);
