import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { convertToString } from '../../utils/convertToString';
import { ReactProps } from '../../utils/types';
import { ControllerInputProps } from '../input/InputFieldTypes';
import { TextFieldStyled } from '../input/TextFieldStyled';

export interface TimeSpanProps extends ReactProps, ControllerInputProps {
    value: number;
}

const FieldContainer = styled.div`
    flex: 1;
    min-width: 0;
`;

const VerticalLane = styled.div`
    display: flex;
    width: 100%;

    ${FieldContainer}:not(:last-child) {
        margin-right: 15px;
    }
`;

enum ValueChangeType {
    Weeks,
    Days,
    Hours,
}

const VALUE_CHANGE_TO_HOURS = 1000 * 60 * 60;

const VALUE_TO_MILLISECONDS_TRANSFORM: Record<ValueChangeType, number> = {
    [ValueChangeType.Weeks]: 7 * 24 * VALUE_CHANGE_TO_HOURS,
    [ValueChangeType.Days]: 24 * VALUE_CHANGE_TO_HOURS,
    [ValueChangeType.Hours]: VALUE_CHANGE_TO_HOURS,
};

function toMilliseconds(changeSource: ValueChangeType, value: number): number {
    return value * VALUE_TO_MILLISECONDS_TRANSFORM[changeSource];
}

export function InputTimeSpan(props: TimeSpanProps): JSX.Element {
    const { t } = useTranslation();
    const [inputViewModel, setInputViewModel] = useState({
        days: 0,
        hours: 0,
        weeks: 0,
        remainingTime: 0,
        daysView: '',
        hoursView: '',
        weeksView: '',
    });
    const [timeStamp, setTimeStamp] = useState(0);

    useEffect(() => {
        setTimeStamp(props.value);
    }, [props.value]);

    useEffect(() => {
        // Time in in milliseconds
        let remainingMilliseconds = Number(props.value) || Number(0);
        const weeks = Math.floor(remainingMilliseconds / VALUE_TO_MILLISECONDS_TRANSFORM[ValueChangeType.Weeks]);
        remainingMilliseconds = remainingMilliseconds - weeks * VALUE_TO_MILLISECONDS_TRANSFORM[ValueChangeType.Weeks];
        const days = Math.floor(remainingMilliseconds / VALUE_TO_MILLISECONDS_TRANSFORM[ValueChangeType.Days]);
        remainingMilliseconds = remainingMilliseconds - days * VALUE_TO_MILLISECONDS_TRANSFORM[ValueChangeType.Days];
        const hours = Math.floor(remainingMilliseconds / VALUE_TO_MILLISECONDS_TRANSFORM[ValueChangeType.Hours]);
        // If we have milliseconds we try to not lose them
        remainingMilliseconds = remainingMilliseconds - hours * VALUE_TO_MILLISECONDS_TRANSFORM[ValueChangeType.Hours];
        setInputViewModel({
            days: days,
            hours: hours,
            weeks: weeks,
            remainingTime: remainingMilliseconds,
            daysView: convertToString(days),
            hoursView: convertToString(hours),
            weeksView: convertToString(weeks),
        });
    }, [props.value]);

    const onValueChanged = (changeSource: ValueChangeType, newValue?: string): void => {
        const newValueNumber = Number(newValue ?? '');
        const changeValueInMilliseconds = newValueNumber * VALUE_TO_MILLISECONDS_TRANSFORM[changeSource];
        let totalInputValue = inputViewModel.remainingTime + changeValueInMilliseconds;
        if (changeSource === ValueChangeType.Weeks) {
            totalInputValue +=
                toMilliseconds(ValueChangeType.Days, inputViewModel.days) + toMilliseconds(ValueChangeType.Hours, inputViewModel.hours);
            setInputViewModel((prev) => ({ ...prev, weeks: newValueNumber, weeksView: convertToString(newValueNumber) }));
        } else if (changeSource === ValueChangeType.Days) {
            totalInputValue +=
                toMilliseconds(ValueChangeType.Weeks, inputViewModel.weeks) + toMilliseconds(ValueChangeType.Hours, inputViewModel.hours);
            setInputViewModel((prev) => ({ ...prev, days: newValueNumber, daysView: convertToString(newValueNumber) }));
        } else if (changeSource === ValueChangeType.Hours) {
            totalInputValue +=
                toMilliseconds(ValueChangeType.Weeks, inputViewModel.weeks) + toMilliseconds(ValueChangeType.Days, inputViewModel.days);
            setInputViewModel((prev) => ({ ...prev, hours: newValueNumber, hoursView: convertToString(newValueNumber) }));
        }
        setTimeStamp(totalInputValue);
    };

    const onBlur = (): void => {
        props.onChange(timeStamp);
        props.onBlur?.();
    };

    return (
        <>
            <VerticalLane>
                <FieldContainer>
                    <TextFieldStyled
                        type="number"
                        label={t('TIME_SPAN_INPUT.WEEKS')}
                        autoComplete="off"
                        onFocus={props.onFocus}
                        value={inputViewModel.weeksView}
                        disabled={props.disabled}
                        onBlur={onBlur}
                        onChange={(event: unknown, newValue?: string): void => onValueChanged(ValueChangeType.Weeks, newValue)}
                        errorMessage={props.errorMessage}
                    />
                </FieldContainer>
                <FieldContainer>
                    <TextFieldStyled
                        type="number"
                        label={t('TIME_SPAN_INPUT.DAYS')}
                        autoComplete="off"
                        onFocus={props.onFocus}
                        value={inputViewModel.daysView}
                        disabled={props.disabled}
                        onBlur={onBlur}
                        onChange={(event: any, newValue?: string): void => onValueChanged(ValueChangeType.Days, newValue)}
                    />
                </FieldContainer>
                <FieldContainer>
                    <TextFieldStyled
                        type="number"
                        label={t('TIME_SPAN_INPUT.HOURS')}
                        autoComplete="off"
                        onFocus={props.onFocus}
                        value={inputViewModel.hoursView}
                        disabled={props.disabled}
                        onBlur={onBlur}
                        onChange={(event: any, newValue?: string): void => onValueChanged(ValueChangeType.Hours, newValue)}
                    />
                </FieldContainer>
            </VerticalLane>
        </>
    );
}
