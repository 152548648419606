import { stringify } from 'query-string';
import React from 'react';
import { useHistory } from 'react-router';
import { encodeQueryParams, JsonParam } from 'use-query-params';
import { RemoveFiltersItem, SeriesListCard, SeriesListCardProps } from '../../../../componentsBusiness/seriesListCard';
import { StudentInputApiModel } from '../../../../models/studentApiModel';
import { studentSeriesListCardQuery } from '../../../../services/dataAccess';
import { APP_PATHS_FROM_ROOT } from '../../../routing/commands';
import { SeriesListItemApiModel } from '../../serie/seriesApiModel';

interface Props {
    student: StudentInputApiModel;
}

const removedFilters: SeriesListCardProps['removedFilters'] = new Set<RemoveFiltersItem>(['dateToFilter', 'startDateFilter', 'endDateFilter']);

export function StudentSeriesListCard({ student }: Props): JSX.Element {
    const history = useHistory();

    function onAddSeriesToStudent(): void {
        const queryParams = encodeQueryParams({ student: JsonParam }, { student });
        history.push({
            pathname: APP_PATHS_FROM_ROOT.newStudentSeriesAttendanceFromStudent(),
            search: stringify(queryParams),
        });
    }
    const seriesByStudentFilterParams = {
        studentId: student.id,
    };

    return (
        <SeriesListCard
            cardTitle="STUDENT_SERIE_LIST_CARD_TITLE"
            filtersFlex={1}
            removedFilters={removedFilters}
            allowAdd={true}
            service={studentSeriesListCardQuery}
            staticServiceParams={seriesByStudentFilterParams}
            onAdd={onAddSeriesToStudent}
            onItemClicked={(model: SeriesListItemApiModel): void => {
                history.push(APP_PATHS_FROM_ROOT.editStudentSeriesAttendanceFromStudent(model.id));
            }}
        />
    );
}
