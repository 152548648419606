import { IconButton, mergeStyles, Toggle } from '@fluentui/react';
import styled from 'styled-components';
import { device } from '../../componentsUx/cssUtils/breakpoints';

export const FiltersArea = styled.div<{ flex$?: number }>`
    display: flex;
    flex: ${(props): number => props.flex$ ?? 1};
    min-width: 0;

    > * {
        flex: 1;
        min-width: 0;
    }

    @media ${device.featurePhone} {
        flex-direction: column;
        > * {
          margin-left: 0;
        }
    }

    @media ${device.tabletPortrait} {
        flex-direction: row;
        > *:not(:first-child) {
          margin-left: 15px;
        }

    
    .ms-DatePicker i {
        color: ${(props): string => props.theme.colors.primary};
    }

`;

export const ClosedSeriesToggle = styled(Toggle)`
    > div {
        margin-top: 5px;
    }

    label {
        color: ${(props): string => props.theme.colors.secondary1};
    }

    .ms-Toggle-thumb {
        background-color: ${(props): string => (props.checked ? props.theme.palette.white : props.theme.colors.secondary1)};
    }

    .ms-Toggle-thumb:hover {
        background-color: ${(props): string => props.theme.colors.black};
    }
`;

export const ClearIconButton = styled(IconButton)`
    margin-top: 26px;
    stroke: red;

    &:hover {
        background-color: white;
    }
`;

export const SeriesToggleAndClearButton = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
`;

export const iconClass = mergeStyles({
    fontSize: 24,
    height: 24,
    width: 24,
});
