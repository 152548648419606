import { registerIcons } from '@fluentui/react/lib/Styling';
import * as React from 'react';

export function initializeIcons(): void {
    registerIcons({
        icons: {
            // TODO find a way to load icons using async requests instead of embedding them in js
            'fp-company': (
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 512 640">
                    <path d="M490.7,480c1.5-4.2,1.8-8.8,0.8-13.2l-8-36c-0.7-3.3-1.9-6.6-3.5-9.6V146.8l-88-40V16H168v80H32v384H16v16h480v-16H490.7z   M136,480H80v-96h56V480z M168,480h-16V368H64v112H48V112h120V480z M392,124.4l72,32.7v168.6c-17.9-20.4-46.8-27.3-72-17V124.4z   M416,320c26.5,0,48,21.5,48,48v36.6c-1.5-0.9-3.1-1.6-4.7-2.3l-16-6.4c3.1-5,4.7-10.8,4.7-16.7V344h-64v35.2  c0,5.9,1.6,11.7,4.7,16.7l-16,6.4c-1.6,0.7-3.2,1.4-4.7,2.3V368C368,341.5,389.5,320,416,320z M432,360v19.2c0,8.8-7.2,16-16,16  s-16-7.2-16-16V360H432z M184,32h192v16H184V32z M338.3,477c-1.5,1.9-3.8,3-6.2,3H227.9c-4.4,0-8-3.6-8-8c0-0.6,0.1-1.2,0.2-1.7  l8-36c1.7-7.7,7.2-14.1,14.5-17.1l21-8.4c2.7,1.2,5.4,2.1,8.3,2.6V464h16v-52.6c2.9-0.6,5.6-1.5,8.3-2.6l21,8.4  c7.4,2.9,12.8,9.3,14.5,17.1l8,36c0,0.2,0.1,0.4,0.1,0.6c0,0.7,0,1.5,0,2.2C339.8,474.5,339.2,475.9,338.3,477z M304,352v20.2  c0,9.1-5.1,17.4-13.3,21.5c-6.8,3.4-14.7,3.4-21.5,0c-8.2-4-13.3-12.4-13.3-21.5V352H304z M257.4,336c3.4-9.6,12.5-16,22.6-16h24v16  H257.4z M352,421.2c-1.6,3-2.7,6.3-3.5,9.6l-0.5,2.4l-0.5-2.4c-2.9-12.9-11.9-23.6-24.2-28.5l-12.2-4.9c5.8-7.1,8.9-16,8.9-25.2V304  h-40c-22.1,0-40,17.9-40,40v28.2c0,9.2,3.1,18.1,8.9,25.2l-12.2,4.9c-12.3,4.9-21.3,15.6-24.2,28.5l-8,36c-1,4.4-0.7,9,0.8,13.2H184  V64h192v254.1c-15.2,12.1-24,30.5-24,49.9V421.2z M474.3,477c-1.5,1.9-3.8,3-6.2,3H363.9c-4,0-7.4-2.9-7.9-6.9c0-0.7,0-1.5,0-2.2  c0-0.2,0.1-0.4,0.1-0.6l8-36c1.7-7.7,7.2-14.1,14.5-17.1l23.2-9.3c2,1,4,1.7,6.1,2.3V464h16v-53.9c2.1-0.5,4.2-1.3,6.1-2.3l23.2,9.3  c7.4,2.9,12.8,9.3,14.5,17.1l8,36C476.4,472.6,475.8,475.1,474.3,477z" />
                    <path d="M152,208H64v56h88V208z M136,248H80v-24h56V248z" />
                    <path d="M208,168h56V80h-56V168z M224,96h24v56h-24V96z" />
                    <path d="M152,288H64v56h88V288z M136,328H80v-24h56V328z" />
                    <path d="M296,168h56V80h-56V168z M312,96h24v56h-24V96z" />
                    <path d="M208,280h56v-88h-56V280z M224,208h24v56h-24V208z" />
                    <path d="M296,280h56v-88h-56V280z M312,208h24v56h-24V208z" />
                    <rect x="304" y="432" width="16" height="16" />
                    <rect x="440" y="432" width="16" height="16" />
                    <rect x="408" y="168" width="40" height="16" />
                    <rect x="408" y="208" width="40" height="16" />
                    <rect x="408" y="248" width="40" height="16" />
                    <path d="M152,128H64v56h88V128z M136,168H80v-24h56V168z" />
                </svg>
            ),
            'fp-logout': (
                <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 100 125" x="0px" y="0px">
                    <path d="M25.08,89h41.8a7,7,0,0,0,7-7V74a3,3,0,0,0-6,0v8a1,1,0,0,1-1,1H25.08a1,1,0,0,1-1-1V18a1,1,0,0,1,1-1h41.8a1,1,0,0,1,1,1v8a3,3,0,0,0,6,0V18a7,7,0,0,0-7-7H25.08a7,7,0,0,0-7,7V82A7,7,0,0,0,25.08,89Z" />
                    <path d="M64.69,31.76A3,3,0,0,0,60.48,36L71.6,47H39.38a3,3,0,1,0,0,6H71.6L60.48,64a3,3,0,0,0,4.21,4.27L81,52.14a3,3,0,0,0,0-4.27Z" />
                </svg>
            ),
            'fp-close': (
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 100 125" enableBackground="new 0 0 100 100">
                    <g>
                        <circle fill="none" strokeWidth="6" strokeMiterlimit="10" cx="50" cy="50" r="47" />
                        <line fill="none" strokeWidth="6" strokeMiterlimit="10" x1="71.214" y1="71.213" x2="28.787" y2="28.787" />
                        <line fill="none" strokeWidth="6" strokeMiterlimit="10" x1="28.787" y1="71.213" x2="71.214" y2="28.787" />
                    </g>
                </svg>
            ),
            'fp-delete': (
                <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 512 512" viewBox="0 0 512 512">
                    <g>
                        <path d="m424 64h-88v-16c0-26.51-21.49-48-48-48h-64c-26.51 0-48 21.49-48 48v16h-88c-22.091 0-40 17.909-40 40v32c0 8.837 7.163 16 16 16h384c8.837 0 16-7.163 16-16v-32c0-22.091-17.909-40-40-40zm-216-16c0-8.82 7.18-16 16-16h64c8.82 0 16 7.18 16 16v16h-96z" />
                        <path d="m78.364 184c-2.855 0-5.13 2.386-4.994 5.238l13.2 277.042c1.22 25.64 22.28 45.72 47.94 45.72h242.98c25.66 0 46.72-20.08 47.94-45.72l13.2-277.042c.136-2.852-2.139-5.238-4.994-5.238zm241.636 40c0-8.84 7.16-16 16-16s16 7.16 16 16v208c0 8.84-7.16 16-16 16s-16-7.16-16-16zm-80 0c0-8.84 7.16-16 16-16s16 7.16 16 16v208c0 8.84-7.16 16-16 16s-16-7.16-16-16zm-80 0c0-8.84 7.16-16 16-16s16 7.16 16 16v208c0 8.84-7.16 16-16 16s-16-7.16-16-16z" />
                    </g>
                </svg>
            ),
        },
    });
}

initializeIcons();
