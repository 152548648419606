import { AxiosInstance } from 'axios';
import { StudentApiModel, StudentRelationsApiModel } from '../../../models/studentApiModel';
import { GenericCommandResultOutput } from '../../common/paginatedApiOutput';

export type StudentDetailsResponse = StudentApiModel & StudentRelationsApiModel.Address & StudentRelationsApiModel.Branch;

type UpdateStudentApiInput = Partial<StudentApiModel> &
    StudentRelationsApiModel.Address & {
        avatar?: File;
    };

export class StudentsService {
    private studentsApiEndpoint = 'Students';

    constructor(private httpClient: AxiosInstance) {}

    public async readStudentDetails(studentId: string): Promise<StudentDetailsResponse> {
        const { data } = await this.httpClient.get<StudentDetailsResponse>(`${this.studentsApiEndpoint}/${studentId}`);
        return data;
    }

    public async createOrUpdateStudent(apiInput: UpdateStudentApiInput): Promise<string | null> {
        const requestEndpoint = apiInput.id ? 'Update' : 'Create';
        const { data } = await this.httpClient.post<GenericCommandResultOutput>(`${this.studentsApiEndpoint}/${requestEndpoint}`, apiInput);
        return data.id;
    }
}
