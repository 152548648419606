import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { StudentDetailsEditor } from '../../../../componentsBusiness/studentDetailsEditor';
import { device } from '../../../../componentsUx/cssUtils/breakpoints';
import { studentsService } from '../../../../services/dataAccess';
import { useEffectAsync } from '../../../../utils/useEffectAsync';
import { APP_PATHS_FROM_ROOT } from '../../../routing/commands';
import { StudentDetailsResponse } from '../students.service';
import { StudentSeriesListCard } from '../studentsList/StudentSeriesListCard';

const StudentDetailsEditorArea = styled.div`
    grid-area: studentDetailsCard;
`;

const StudentRelatedDataArea = styled.div`
    grid-area: studentRelatedDataArea;
`;

const StudentDetailsGrid = styled.div`
    display: grid;
    grid-column-gap: 28px;
    grid-row-gap: 28px;

    @media ${device.featurePhone} {
        grid-template-areas: 'studentDetailsCard' 'studentRelatedDataArea';
        grid-template-columns: minmax(0, 1fr);
    }

    @media ${device.tabletLandscape} {
        grid-template-columns: 1fr minmax(0, 2fr);
        grid-template-areas: 'studentDetailsCard studentRelatedDataArea';
    }
`;

interface Props {
    studentId: string;
}

export function EditStudentDetails({ studentId }: Props): JSX.Element {
    const [studentDetails, setStudentDetails] = useState<StudentDetailsResponse | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();

    // Read student
    useEffectAsync(
        async ({ canceled }) => {
            const student = await studentsService.readStudentDetails(studentId);
            if (canceled) {
                return;
            }
            setStudentDetails(student);
            setIsLoading(false);
        },
        [studentId],
    );

    if (isLoading) {
        return <></>;
    }

    return (
        <StudentDetailsGrid>
            <StudentDetailsEditorArea>
                <StudentDetailsEditor
                    formValues={studentDetails}
                    viewMode="card"
                    onStudentUpdated={(): void => history.push(APP_PATHS_FROM_ROOT.students())}
                />
            </StudentDetailsEditorArea>
            <StudentRelatedDataArea>{studentDetails ? <StudentSeriesListCard student={studentDetails} /> : <></>}</StudentRelatedDataArea>
        </StudentDetailsGrid>
    );
}
