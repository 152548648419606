import { DependencyList, useEffect } from 'react';

export interface CancellationToken {
    canceled: boolean;
}

type EffectFunction = (cancellationToken: CancellationToken) => Promise<void>;

export function useEffectAsync(props: EffectFunction, deps?: DependencyList): void {
    useEffect(
        () => {
            const cancellationToken: CancellationToken = { canceled: false };
            // cancellation token is needed because react can unmount this component
            props(cancellationToken).catch((err): void => console.error(`Error in useEffectAsync`, err));

            return (): void => {
                cancellationToken.canceled = true;
            };
        },
        // effectCallback is not a dependency so we disable the deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
        deps,
    );
}
