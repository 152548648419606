import { DatePicker, IDatePickerStrings, DayOfWeek } from '@fluentui/react';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ControllerInputProps } from '../input/InputFieldTypes';

export interface InputDatePickerProps extends ControllerInputProps {
    label: string;
}

export interface DatePickerStrings extends IDatePickerStrings {
    separator: string;
}

const MONTHS_STRINGS = ['JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE', 'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'];
const DAYS_STRINGS = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];

export function InputDatePicker(props: InputDatePickerProps): JSX.Element {
    const { t } = useTranslation();
    const [dayPickerStrings, setDayPickerStrings] = useState<DatePickerStrings>();

    useEffect(() => {
        setDayPickerStrings({
            months: MONTHS_STRINGS.map((data) => t(`DATE_PICKER_MONTHS.${data}`)),
            shortMonths: MONTHS_STRINGS.map((data) => t(`DATE_PICKER_MONTHS_SHORT.${data}`)),
            days: DAYS_STRINGS.map((data) => t(`DATE_PICKER_DAYS.${data}`)),
            shortDays: DAYS_STRINGS.map((data) => t(`DATE_PICKER_DAYS_SHORT.${data}`)),
            goToToday: t('DATE_PICKER.GO_TO_TODAY'),
            prevMonthAriaLabel: t('DATE_PICKER.PREV_MONTH_ARIA_LABEL'),
            nextMonthAriaLabel: t('DATE_PICKER.NEXT_MONTH_ARIA_LABEL'),
            prevYearAriaLabel: t('DATE_PICKER.PREV_YEAR_ARIA_LABEL'),
            nextYearAriaLabel: t('DATE_PICKER.NEXT_YEAR_ARIA_LABEL'),
            closeButtonAriaLabel: t('DATE_PICKER.CLOSE_BUTTON_ARIA_LABEL'),
            monthPickerHeaderAriaLabel: t('DATE_PICKER.MONTH_PICKER_HEADER_ARIA_LABEL'),
            yearPickerHeaderAriaLabel: t('DATE_PICKER.YEAR_PICKER_HEADER_ARIA_LABEL'),
            separator: t('DATE_PICKER.SEPARATOR'),
        });
    }, [t]);

    const inputValue = props.value ?? undefined;

    const onFormatDate = (date?: Date): string => {
        // TODO implement date formatting based on selected language
        if (!date) {
            return '';
        }
        return [date.getDate().toString().padStart(2, '0'), (date.getMonth() + 1).toString().padStart(2, '0'), date.getFullYear()].join(
            dayPickerStrings?.separator,
        );
    };

    const onParseDateFromString = (val: string): Date | null => {
        // TODO implement date parsing based on selected language
        const today = new Date();

        const values = (val || '').trim().split(dayPickerStrings?.separator || '.');
        if (values.length < 3) {
            return null;
        }

        const day = val.length > 0 ? Math.max(1, Math.min(31, parseInt(values[0], 10))) : today.getDate();
        const month = val.length > 1 ? Math.max(1, Math.min(12, parseInt(values[1], 10))) - 1 : today.getMonth();
        let year = val.length > 2 ? parseInt(values[2], 10) : today.getFullYear();
        if (year < 100) {
            year += today.getFullYear() - (today.getFullYear() % 100);
        }
        const date = new Date(year, month, day);
        const isDateValid = !isNaN(Date.parse(date.toJSON()));
        if (isDateValid) {
            return date;
        }
        return null;
    };

    const onChange = (dateValue: Date | null | undefined): void => {
        props.onChange(dateValue);
    };

    return (
        <>
            <DatePicker
                allowTextInput={true}
                firstDayOfWeek={DayOfWeek.Monday}
                strings={dayPickerStrings}
                label={props.label}
                ariaLabel={props.label}
                value={inputValue}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                formatDate={onFormatDate}
                parseDateFromString={onParseDateFromString}
                onSelectDate={onChange}
                disabled={props.disabled}
                textField={{ errorMessage: props.errorMessage }}
                disableAutoFocus={true}
                isRequired={props.required}
            />
        </>
    );
}
