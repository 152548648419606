import { AxiosInstance } from 'axios';
import { InMemoryApiService } from './inMemoryApi.service';
import { PaginatedApiOutput, createPaginatedOutput } from './paginatedApiOutput';

export interface InputPickerService {
    getItems<TItem>(apiEndpoint: string, parameters: Record<string, unknown>): Promise<PaginatedApiOutput<TItem>>;
}

export enum PickerInMemoryApi {
    CurrencyList = '@InMemorySearch/CurrencyList',
}

export class PickerListService implements InputPickerService {
    constructor(private httpClient: AxiosInstance, private inMemoryServices: Record<PickerInMemoryApi, InMemoryApiService<unknown>>) {}

    public async getItems<TItem>(apiEndpoint: string, parameters: Record<string, unknown>): Promise<PaginatedApiOutput<TItem>> {
        if (this.inMemoryServices[apiEndpoint]) {
            return this.inMemoryServices[apiEndpoint].getItems<TItem>(parameters);
        }
        const { data } = await this.httpClient.get<PaginatedApiOutput<TItem>>(`${apiEndpoint}/GetPage`, {
            params: parameters,
        });
        return createPaginatedOutput(data);
    }
}
