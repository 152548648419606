import { Omit } from '@fluentui/react';
import { AxiosInstance } from 'axios';
import { GenericCommandResultOutput } from '../../common/paginatedApiOutput';
import { AuthorizationCommissionApiModel, AuthorizationCommissionApiModelRelations } from './authorizationCommissionApiModel';

type UpdateAuthorizationCommissionApiModel = AuthorizationCommissionApiModel & AuthorizationCommissionApiModelRelations.Address;
type CreateAuthorizationCommissionApiModel = Omit<UpdateAuthorizationCommissionApiModel, 'id'> & { branchId: string };

export class AuthorizationCommissionService {
    private authorizationCommissionsEndpoint = 'AuthorizationCommissions';

    constructor(private httpClient: AxiosInstance) {}

    public async getAuthorizationCommissionDetails(branchId: string): Promise<UpdateAuthorizationCommissionApiModel> {
        const { data } = await this.httpClient.get<UpdateAuthorizationCommissionApiModel>(`${this.authorizationCommissionsEndpoint}/${branchId}`);
        return data;
    }

    public async addAuhtorizationCommission(params: CreateAuthorizationCommissionApiModel): Promise<string | null> {
        const result = await this.httpClient.post<GenericCommandResultOutput>(`${this.authorizationCommissionsEndpoint}/Create`, params);
        return result.data.id;
    }

    public async updateAuthorizationCommission(params: UpdateAuthorizationCommissionApiModel): Promise<string | null> {
        const result = await this.httpClient.put<GenericCommandResultOutput>(`${this.authorizationCommissionsEndpoint}/Update`, params);
        return result.data.id;
    }
}
