import { AxiosInstance } from 'axios';
import { PaginatedApiOutput, createPaginatedOutput, PaginatedApiInput } from '../../common/paginatedApiOutput';
import { ReportFilterEntityType } from './filterPickers/reportFilterEntityType';
import {
    GenerateReportApiModel,
    GenerateReportCommandResultOutput,
    ReportApiModel,
    ReportListApiModel,
    ReportListItemApiModel,
} from './reportApiModel';

type ReportListApiInput = PaginatedApiInput & { entityName?: string; entityType?: ReportFilterEntityType };

export class ReportService {
    readonly reportEndpoint = `Report`;

    constructor(private httpClient: AxiosInstance) {}

    public async readReports(reportListApiModel: ReportListApiInput): Promise<ReportListApiModel> {
        const { data } = await this.httpClient.get<PaginatedApiOutput<ReportListItemApiModel>>(`${this.reportEndpoint}/GetPage`, {
            params: reportListApiModel,
        });

        return createPaginatedOutput(data);
    }

    public async getReport(reportId: string): Promise<ReportApiModel> {
        const { data } = await this.httpClient.get<ReportApiModel>(`${this.reportEndpoint}/${reportId}`);
        return data;
    }

    public async generateReport(generateApiModel: GenerateReportApiModel): Promise<string> {
        const result = await this.httpClient.post<GenerateReportCommandResultOutput>(`${this.reportEndpoint}/Generate`, generateApiModel);
        return result.data.generatedReportId;
    }
}
