import { Text } from '@fluentui/react';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

const TitleFont = styled(Text).attrs({
    variant: 'xLarge',
    block: false,
})`
    color: ${(props): string => props.theme.colors.primary};
`;

const TitleContainer = styled.div`
    margin-bottom: 20px;
`;

export function CardTitle({ children }: PropsWithChildren<unknown>): JSX.Element {
    return (
        <TitleContainer>
            <TitleFont>{children}</TitleFont>
        </TitleContainer>
    );
}
