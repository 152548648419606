import { AxiosInstance } from 'axios';
import { GenericCommandResultOutput } from '../../common/paginatedApiOutput';
import { SeriesApiModel, SeriesInputApiModel, SeriesRelations, SeriesListItemApiModel } from './seriesApiModel';

export type SeriesDetailsResponse = SeriesApiModel &
    SeriesRelations.Program &
    SeriesRelations.Branch &
    SeriesRelations.President &
    SeriesRelations.Trainers;

export class SeriesService {
    readonly seriesEndpoint = 'Series';

    constructor(private httpClient: AxiosInstance) {}

    public async addSeries(params: SeriesInputApiModel): Promise<string | null> {
        const { data } = await this.httpClient.post<GenericCommandResultOutput>(`${this.seriesEndpoint}/Create`, params);
        return data.id;
    }

    public async updateSeries(params: SeriesInputApiModel): Promise<string | null> {
        const { data } = await this.httpClient.put<GenericCommandResultOutput>(`${this.seriesEndpoint}/Update`, params);
        return data.id;
    }

    public async getSeries(seriesId: string): Promise<SeriesDetailsResponse> {
        const { data } = await this.httpClient.get<SeriesDetailsResponse>(`${this.seriesEndpoint}/${seriesId}`);
        return data;
    }

    public async getSeriesAsListItem(seriesId: string): Promise<SeriesListItemApiModel> {
        const series = await this.getSeries(seriesId);
        return this.mapSeriesDetailsToListItem(series);
    }

    public mapSeriesDetailsToListItem(series: SeriesDetailsResponse): SeriesListItemApiModel {
        return {
            id: series.id,
            branchId: series.branch.id,
            branchName: series.branch.name,
            programBasePrice: series.program.basePrice,
            courseType: series.program.type,
            programName: series.program.specializationName,
            seriesFormat: series.seriesFormat,
            theoreticalPeriodStartDate: series.theoreticalPeriodStartDate,
            theoreticalPeriodEndDate: series.theoreticalPeriodEndDate,
            practicalPeriodStartDate: series.practicalPeriodStartDate,
            practicalPeriodEndDate: series.practicalPeriodEndDate,
            examDate: series.examDate,
            isClosed: series.isClosed,
            seriesNumber: series.seriesNumber,
            specializationName: series.program.specializationName,
            studentsCount: 0,
            maximumStudents: series.maximumStudents,
            observations: '',
        };
    }
}
