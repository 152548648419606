import React, { ReactNode } from 'react';
import { Route, Redirect } from 'react-router';
import { useSession } from '../../state/SessionState';
import { ReactProps } from '../../utils/types';
import { PATHS } from './commands';

interface Props extends ReactProps {
    path: string;
}

export function UserAuthGuard(props: Props): JSX.Element {
    const [session] = useSession();

    return (
        <Route
            path={props.path}
            render={({ location }): ReactNode =>
                !session?.isExpired ? (
                    props.children
                ) : (
                    <Redirect
                        to={{
                            pathname: `/${PATHS.auth}/${PATHS.login}`,
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}
