import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CardActions } from '../../componentsUx/card/CardActions';
import { ConditionalCard, ConditionalCardViewMode } from '../../componentsUx/card/ConditionalCard';
import { SubmitButton } from '../../componentsUx/input/SubmitButton';
import { useShowInfo } from '../../componentsUx/toast/toastState';
import { StudentDetailsResponse } from '../../features/home/students/students.service';
import { studentsService } from '../../services/dataAccess';
import { useFpForm, setFormValues } from '../../utils/useFormHook';
import { ValidFormValues, StudentDetailsSchema, defaultMissingStudentValues, FormValues } from './form.util';
import { StudentEditorFields } from './StudentEditorFields';

interface Props {
    viewMode: ConditionalCardViewMode;
    formValues?: StudentDetailsResponse | null;
    onStudentUpdated?: (studentId: string) => void;
}

export function StudentDetailsEditor({ formValues, onStudentUpdated, viewMode }: Props): JSX.Element {
    const { t } = useTranslation();
    const showToastInfo = useShowInfo();
    const formMethods = useFpForm<FormValues>({
        schema: StudentDetailsSchema,
        defaultValues: defaultMissingStudentValues(formValues),
    });
    const { handleSubmit, setValue } = formMethods;

    useEffect(() => {
        setFormValues(defaultMissingStudentValues(formValues), setValue);
    }, [formValues, setValue]);

    async function saveStudentDetails(studentData: ValidFormValues): Promise<void> {
        const studentId = await studentsService.createOrUpdateStudent({
            ...formValues,
            ...studentData,
            branchId: studentData.branch?.id ?? formValues?.branchId,
        });
        showToastInfo({ infoMessage: 'STUDENT_DETAILS_SAVE_SUCCESSFULLY' });

        if (studentId) {
            onStudentUpdated?.(studentId);
        }
    }

    return (
        <ConditionalCard viewMode={viewMode} cardTitle={'STUDENT_CARD_TITLE'}>
            <form onSubmit={handleSubmit(saveStudentDetails)}>
                <StudentEditorFields form={formMethods} />

                <CardActions>
                    <SubmitButton label={t('FORM_SAVE')} />
                </CardActions>
            </form>
        </ConditionalCard>
    );
}
