import * as yup from 'yup';
import { MixedSchema } from 'yup';
import { ErrorCode } from './errors.util';

type DateDefaults = Date | '';

export function dateValidator(options: { isRequired?: boolean } = {}): MixedSchema<DateDefaults> {
    let dateValidator = yup
        .date()
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr));

    if (options?.isRequired) {
        dateValidator = dateValidator.required(ErrorCode.Required);
    }

    return dateValidator as MixedSchema<DateDefaults>;
}
