import { IColumn } from '@fluentui/react';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { CardTitle } from '../../../componentsUx/card/CardTitle';
import { PaginatedList } from '../../../componentsUx/paginatedList/PaginatedList';
import { useBranchListListInput, BranchOwnedPaginationInputs } from '../../../componentsUx/paginatedList/useListInput';
import { branchStaffMemberService } from '../../../services/dataAccess';
import { useSession } from '../../../state/SessionState';
import { ContributorType, ContributorTypeValue } from '../../common/contributorType';
import { createDefaultPaginatedOutput } from '../../common/paginatedApiOutput';
import { APP_PATHS_FROM_ROOT } from '../../routing/commands';
import { BranchStaffMemberListApiModel, BranchStaffMemberListItemApiModel } from './branchStaffMemberApiModel';

interface BranchStaffMemberListCardProps {
    branchId: string;
}

export function BranchStaffMembersListCard(props: BranchStaffMemberListCardProps): JSX.Element {
    const { branchId } = props;

    const [t] = useTranslation();
    const history = useHistory();
    const [listInput, setListInput] = useBranchListListInput({
        orderByProperty: 'firstName',
        branchId,
    });
    const [branchStaffMemberList, setBranchStaffMemberList] = useState<BranchStaffMemberListApiModel>(createDefaultPaginatedOutput());
    const [session] = useSession();

    useEffect(() => {
        const readBrancheStaffMembers = async (): Promise<void> => {
            const branchStaffMemberListData = await branchStaffMemberService.readBranchStaffMembers(listInput);
            setBranchStaffMemberList(branchStaffMemberListData);
        };

        readBrancheStaffMembers();
    }, [listInput]);

    const onItemClicked = (model: BranchStaffMemberListItemApiModel): void => {
        history.push(APP_PATHS_FROM_ROOT.branchStaffMemberPage(model.id, branchId));
    };

    const onAdd = (): void => {
        history.push(APP_PATHS_FROM_ROOT.newBranchStaffMemberPage(branchId));
    };

    const commonColumnAttrs = {
        minWidth: 100,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: true,
    };

    const columns: IColumn[] = [
        {
            key: 'columnFirstName',
            name: t('BRANCH_STAFF_LIST_COLUMN_FIRST_NAME'),
            fieldName: 'firstName',
            ...commonColumnAttrs,
        },
        {
            key: 'columnLastName',
            name: t('BRANCH_STAFF_LIST_COLUMN_LAST_NAME'),
            fieldName: 'lastName',
            ...commonColumnAttrs,
        },
        {
            key: 'columnSocialSecurityNumber',
            name: t('SOCIAL_SECURITY_NUMBER'),
            fieldName: 'socialSecurityNumber',
            ...commonColumnAttrs,
        },
        {
            key: 'columnContributorType',
            name: t('BRANCH_STAFF_LIST_COLUMN_CONTRIBUTOR_TYPE'),
            fieldName: 'contributorType',
            onRender: (item: { contributorType: ContributorType }): JSX.Element => {
                return <>{t(ContributorTypeValue[item.contributorType])}</>;
            },
            ...commonColumnAttrs,
        },
    ];

    return (
        <div>
            <CardTitle>{t('BRANCH_STAFF_MEMBERS_LIST_TITLE')}</CardTitle>
            <PaginatedList
                entityType="BranchStaffMember"
                onItemClicked={onItemClicked}
                searchTextLabel={t('SEARCH_BY_NAME')}
                model={branchStaffMemberList}
                pagination={listInput}
                onReadPage={(inputs: BranchOwnedPaginationInputs): void => setListInput(inputs)}
                columns={columns}
                allowAdd={session.isCompanyAdmin}
                onAdd={onAdd}
            />
        </div>
    );
}
