import { ConstrainMode, IColumn, IDropdownOption, Dropdown, FontIcon } from '@fluentui/react';
import { omit } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '../../componentsUx/card/Card';
import { CardTitle } from '../../componentsUx/card/CardTitle';
import { InputDateRange } from '../../componentsUx/inputDateRange/InputDateRange';
import { highlightedColumnClassName, PaginatedList } from '../../componentsUx/paginatedList/PaginatedList';
import { useSeriesListInput, SeriesPaginationInputs } from '../../componentsUx/paginatedList/useListInput';
import { BooleanValue } from '../../features/common/booleanValue';
import { CourseTypeValue } from '../../features/common/courseType';
import { DateToFilter, DateToFilterValue } from '../../features/common/dateToFilter';
import { createDefaultPaginatedOutput } from '../../features/common/paginatedApiOutput';
import { SeriesFormatTypeValue } from '../../features/common/seriesFormatType';
import { SeriesListApiModel, SeriesListItemApiModel } from '../../features/home/serie/seriesApiModel';
import { useSession } from '../../state/SessionState';
import { dateParser } from '../../utils/dateParser';
import { hasValue } from '../../utils/hasValue';
import { sanitizeApiQueryParams } from '../../utils/sanitizeApiQueryParams';
import { useEffectAsync } from '../../utils/useEffectAsync';
import { SeriesListCardQuery } from './seriesListCardQuery';
import { ClearIconButton, ClosedSeriesToggle, FiltersArea, iconClass, SeriesToggleAndClearButton } from './SeriesListCardUtils';

interface CardInternalFilters {
    searchText: string;
    showClosed: boolean;
    dateToFilter: DateToFilter.StartDate;
    startDateFilter: string;
    endDateFilter: string;
}

export type RemoveFiltersItem = keyof CardInternalFilters;

export interface SeriesListCardProps<TPaginatedApiInput extends Record<string, unknown> = Record<string, unknown>> {
    cardTitle: string;
    service: SeriesListCardQuery<TPaginatedApiInput>;
    staticServiceParams: Partial<TPaginatedApiInput>;
    removedFilters?: Set<RemoveFiltersItem>;
    filtersFlex?: number;
    allowAdd: boolean;
    onAdd?(): void;
    onItemClicked?(model: SeriesListItemApiModel): void;
}

export function SeriesListCard<TPaginatedApiInput extends Record<string, unknown> = Record<string, unknown>>(
    props: SeriesListCardProps<TPaginatedApiInput>,
): JSX.Element {
    const [t] = useTranslation();
    const [session] = useSession();
    const [listInput, setListInput] = useSeriesListInput();
    const [filters, setFilters] = useState<CardInternalFilters>({
        searchText: '',
        showClosed: false,
        dateToFilter: DateToFilter.StartDate,
        startDateFilter: '',
        endDateFilter: '',
    });
    const [seriesList, setSeriesList] = useState<SeriesListApiModel>(createDefaultPaginatedOutput());

    function createFilterSetter<T extends keyof CardInternalFilters>(filterName: T): (newValue: CardInternalFilters[T]) => void {
        return (newValue: CardInternalFilters[T]): void => {
            setFilters((prev) => ({ ...prev, [filterName]: newValue }));
        };
    }

    useEffectAsync(
        async ({ canceled }) => {
            const staticServiceParams = props.staticServiceParams ?? {};

            const paramsInput = {
                // Pagination params
                ...listInput,
                // Filters without static filtering
                ...omit(filters, Object.keys(props.staticServiceParams ?? {})),
                // Service static params
                ...staticServiceParams,
            };

            sanitizeApiQueryParams(paramsInput);
            const seriesListData = await props.service.run(paramsInput);
            if (canceled) {
                return;
            }
            setSeriesList(seriesListData);
        },
        [listInput, filters, props.service, props.staticServiceParams],
    );

    const toggleToDisplayClosedSeries = (): void => {
        setFilters((prev) => ({
            ...prev,
            showClosed: !prev.showClosed,
        }));
    };

    const clearFilters = (): void => {
        setFilters({
            searchText: '',
            startDateFilter: '',
            endDateFilter: '',
            dateToFilter: DateToFilter.StartDate,
            showClosed: false,
        });
    };

    const columnCommonProps = {
        minWidth: 150,
        maxWidth: 300,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: true,
        constrainMode: ConstrainMode.horizontalConstrained,
    };

    const columns: IColumn[] = [
        {
            name: t('SERIES_LIST_CARD_FIELD_BRANCH_NAME'),
            fieldName: 'branchName',
            key: 'branchName',
            ...columnCommonProps,
            minWidth: 100,
            maxWidth: 100,
        },
        {
            name: t('SERIES_LIST_CARD_FIELD_PROGRAM_NAME'),
            fieldName: 'programName',
            key: 'programName',
            ...columnCommonProps,
            minWidth: 100,
            maxWidth: 100,
            className: highlightedColumnClassName,
        },
        {
            name: t('SERIES_LIST_CARD_FIELD_SPECIALIZATION_NAME'),
            fieldName: 'specializationName',
            key: 'specializationName',
            ...columnCommonProps,
        },
        {
            name: t('SERIES_LIST_CARD_FIELD_COURSE_TYPE'),
            fieldName: 'courseType',
            key: 'courseType',
            onRender: (item: SeriesListItemApiModel): string => t(CourseTypeValue[item.courseType]),
            ...columnCommonProps,
            minWidth: 60,
            maxWidth: 60,
            className: highlightedColumnClassName,
        },
        {
            name: t('SERIES_LIST_CARD_FIELD_NUMBER'),
            fieldName: 'number',
            key: 'number',
            onRender: (item: SeriesListItemApiModel): string => item.seriesNumber,
            ...columnCommonProps,
            minWidth: 50,
            maxWidth: 50,
        },
        {
            name: t('SERIES_LIST_CARD_FIELD_SERIES_FORMAT'),
            fieldName: 'seriesFormat',
            key: 'seriesFormat',
            onRender: (item: SeriesListItemApiModel): string => t(SeriesFormatTypeValue[item.seriesFormat]),
            ...columnCommonProps,
            minWidth: 75,
            maxWidth: 75,
        },
        {
            name: t('SERIES_LIST_CARD_FIELD_START_DATE'),
            fieldName: 'theoreticalPeriodStartDate',
            key: 'theoreticalPeriodStartDate',
            onRender: (item: SeriesListItemApiModel): string => dateParser(item.theoreticalPeriodStartDate),
            ...columnCommonProps,
            minWidth: 75,
            maxWidth: 75,
        },
        {
            name: t('SERIES_LIST_CARD_FIELD_END_DATE'),
            fieldName: 'practicalPeriodEndDate',
            key: 'practicalPeriodEndDate',
            onRender: (item: SeriesListItemApiModel): string => dateParser(item.practicalPeriodEndDate),
            ...columnCommonProps,
            minWidth: 75,
            maxWidth: 75,
        },
        {
            name: t('SERIES_LIST_CARD_FIELD_EXAM_DATE'),
            fieldName: 'examDate',
            key: 'examDate',
            onRender: (item: SeriesListItemApiModel): string => dateParser(item.examDate),
            ...columnCommonProps,
            minWidth: 75,
            maxWidth: 75,
            className: highlightedColumnClassName,
        },
        {
            name: t('SERIES_LIST_CARD_FIELD_STUDENTS_COUNT'),
            fieldName: 'studentsCount',
            key: 'studentsCount',
            ...columnCommonProps,
            minWidth: 35,
            maxWidth: 35,
        },
        {
            name: t('SERIES_LIST_CARD_FIELD_MAXIMUM_STUDENTS'),
            fieldName: 'maximumStudents',
            key: 'maximumStudents',
            ...columnCommonProps,
            minWidth: 75,
            maxWidth: 75,
        },
        {
            name: t('SERIES_LIST_CARD_FIELD_IS_CLOSED'),
            fieldName: 'isClosed',
            key: 'isClosed',
            onRender: (item: SeriesListItemApiModel): string => t(BooleanValue[Number(item.isClosed)]),
            ...columnCommonProps,
            minWidth: 50,
            maxWidth: 50,
        },
    ];

    const onDateToFilterSelect = (_, option?: IDropdownOption | undefined): void => {
        option && setFilters((prev) => ({ ...prev, dateToFilter: Number(option.key) }));
    };

    const dropdownOptions: IDropdownOption[] = [
        { key: DateToFilter.StartDate, text: t(DateToFilterValue[DateToFilter.StartDate]) },
        { key: DateToFilter.EndDate, text: t(DateToFilterValue[DateToFilter.EndDate]) },
        { key: DateToFilter.ExamDate, text: t(DateToFilterValue[DateToFilter.ExamDate]) },
    ];

    const removedFilters = props.removedFilters ?? new Set<keyof CardInternalFilters>();

    const displayClearIcon = (): boolean => {
        return Object.entries(filters)
            .filter(([key]) => !removedFilters.has(key as RemoveFiltersItem))
            .some(([key, filterValue]) => {
                if (key === 'dateToFilter') {
                    return filterValue !== DateToFilter.StartDate;
                }
                return hasValue(filterValue);
            });
    };

    const renderFilter = (): JSX.Element => {
        return (
            <FiltersArea flex$={props.filtersFlex}>
                {!removedFilters.has('dateToFilter') && (
                    <Dropdown
                        label={t('SERIES_LIST_CARD_CHOOSE_FILTER_DATE')}
                        options={dropdownOptions}
                        selectedKey={filters.dateToFilter}
                        onChange={onDateToFilterSelect}
                    />
                )}
                {!removedFilters.has('startDateFilter') && !removedFilters.has('endDateFilter') && (
                    <InputDateRange
                        startDate={{
                            label: t('SERIES_LIST_CARD_CHOOSE_START_DATE'),
                            value: filters.startDateFilter,
                            onChange: createFilterSetter('startDateFilter'),
                        }}
                        endDate={{
                            label: t('SERIES_LIST_CARD_CHOOSE_END_DATE'),
                            value: filters.endDateFilter,
                            onChange: createFilterSetter('endDateFilter'),
                        }}
                    />
                )}
                <SeriesToggleAndClearButton>
                    {!removedFilters.has('showClosed') && (
                        <ClosedSeriesToggle
                            label={t('SERIES_LIST_CARD_SHOW_CLOSED_SERIES')}
                            checked={filters.showClosed}
                            onChange={toggleToDisplayClosedSeries}
                        />
                    )}
                    <ClearIconButton onClick={clearFilters} style={{ visibility: displayClearIcon() ? 'visible' : 'hidden' }}>
                        <FontIcon iconName="fp-close" className={iconClass} />
                    </ClearIconButton>
                </SeriesToggleAndClearButton>
            </FiltersArea>
        );
    };

    return (
        <Card>
            <CardTitle>{t(props.cardTitle)}</CardTitle>
            <PaginatedList
                entityType="Series"
                onItemClicked={(model: SeriesListItemApiModel): void => props.onItemClicked?.(model)}
                searchTextLabel={t('SEARCH_SERIES_BY_BRANCH_SPECIALIZATION_OR_PROGRAM')}
                renderFilter={renderFilter}
                model={seriesList}
                pagination={listInput}
                onReadPage={(inputs: SeriesPaginationInputs): void => setListInput(inputs)}
                columns={columns}
                allowAdd={props.allowAdd && session.isCompanyAdmin}
                onAdd={(): void => props.onAdd?.()}
                searchText={filters.searchText}
                setSearchText={createFilterSetter('searchText')}
            />
        </Card>
    );
}
