import { AxiosInstance } from 'axios';
import { SeriesListCardQuery } from '../../../componentsBusiness/seriesListCard/seriesListCardQuery';
import { PaginatedApiInput, createPaginatedOutput, PaginatedApiOutput } from '../../common/paginatedApiOutput';
import { SeriesListApiModel, SeriesListItemApiModel } from '../serie/seriesApiModel';

export interface SeriesPaginatedApiInput {
    showClosed?: boolean;
}

export class DashboardSeriesListCardQuery implements SeriesListCardQuery<SeriesPaginatedApiInput> {
    readonly seriesEndpoint = 'Series';

    constructor(private httpClient: AxiosInstance) {}

    public async run(apiInput: SeriesPaginatedApiInput & PaginatedApiInput): Promise<SeriesListApiModel> {
        const { data } = await this.httpClient.get<PaginatedApiOutput<SeriesListItemApiModel>>(`${this.seriesEndpoint}/GetPage`, {
            params: apiInput,
        });

        return createPaginatedOutput(data);
    }
}
