import React from 'react';
import { InputDatePicker } from '../inputDatePicker/InputDatePicker';

export interface InputDate {
    value: string;
    label: string;
    onChange(value: string): void;
}

export interface InputDateRangeProps {
    startDate: InputDate;
    endDate: InputDate;
}

export function InputDateRange(props: InputDateRangeProps): JSX.Element {
    return (
        <>
            <InputDatePicker label={props.startDate.label} value={props.startDate.value} onChange={props.startDate.onChange} />
            <InputDatePicker label={props.endDate.label} value={props.endDate.value} onChange={props.endDate.onChange} />
        </>
    );
}
