import { convertToString } from '../../utils/convertToString';

export interface SessionDataProps {
    token: string;
    isExpired: boolean;
}

enum UserRoleType {
    // Mange companies
    Admin,
    // Manage own company data + branches + branches users
    CompanyAdmin,
    // Manage tenant data(branch data)
    BranchAdmin,
}

interface UserRole {
    Type: UserRoleType;
    CompanyId: string;
    BranchId: string;
}

interface AppUser {
    Id: number;
    UserName: string;
    ExpireDate: Date;
    Roles: UserRole[];
}

export class SessionData implements SessionDataProps {
    public readonly token: string;
    public readonly isExpired: boolean;
    public readonly isCompanyAdmin: boolean;
    private readonly user: AppUser | null;

    constructor(data?: SessionDataProps) {
        this.token = convertToString(data?.token);
        this.isExpired = data?.isExpired ?? true;
        const tokenData = data?.token?.split('.')[1];
        if (tokenData) {
            const buff = Buffer.from(tokenData, 'base64');
            const jsonToken = buff.toString('utf-8');
            try {
                this.user = JSON.parse(jsonToken) as AppUser;
                this.isCompanyAdmin = this.user.Roles.findIndex((ur) => ur.Type === UserRoleType.CompanyAdmin) !== -1;
            } catch {
                this.token = '';
                this.isExpired = true;
                this.user = null;
                this.isCompanyAdmin = false;
            }
        } else {
            this.user = null;
            this.isCompanyAdmin = false;
        }
    }

    public getCompanyId(): string | undefined {
        return this.user?.Roles.filter((ur) => ur.Type === UserRoleType.CompanyAdmin)[0]?.CompanyId;
    }

    public isLogged(): boolean {
        return this.user !== null;
    }

    public toJson(): SessionDataProps {
        return {
            token: this.token,
            isExpired: this.isExpired,
        };
    }

    public isBranchAdmin(branchId: string | undefined = undefined): boolean {
        return (
            this.user !== null &&
            this.user.Roles &&
            this.user.Roles.findIndex((ur) => ur.Type === UserRoleType.BranchAdmin && (!branchId || branchId === ur.BranchId)) !== -1
        );
    }

    public getBranchIds(): string[] {
        return this.user?.Roles.filter((userRole) => userRole.Type === UserRoleType.BranchAdmin).map((userRole) => userRole.BranchId) ?? [];
    }
}
