import { yupResolver } from '@hookform/resolvers/yup';
import { FormEvent } from 'react';
import { useForm, FieldName } from 'react-hook-form';
import { UseFormOptions, FieldValues, UseFormMethods } from 'react-hook-form/dist/types';
import Yup from 'yup';

type FormParams<TFieldValues extends FieldValues = FieldValues, TContext extends Record<string, unknown> = Record<string, unknown>> = Pick<
    UseFormOptions<TFieldValues, TContext>,
    'defaultValues'
> & {
    schema?: Yup.ObjectSchema | Yup.Lazy;
};

export function useFpForm<TFieldValues extends FieldValues = FieldValues, TContext extends Record<string, unknown> = Record<string, unknown>>(
    params: FormParams<TFieldValues, TContext>,
): UseFormMethods<TFieldValues> {
    const useFormParams: UseFormOptions<TFieldValues, TContext> = {
        mode: 'all',
        reValidateMode: 'onChange',
        shouldFocusError: true,
    };
    if (params.defaultValues) {
        useFormParams.defaultValues = params.defaultValues;
    }
    if (params.schema) {
        useFormParams.resolver = yupResolver(params.schema);
    }

    return useForm<TFieldValues, TContext>(useFormParams);
}

export function setFormValues<TFieldValues extends FieldValues = FieldValues>(
    valuesParams: TFieldValues,
    setValue: UseFormMethods<TFieldValues>['setValue'],
): void {
    if (!valuesParams) {
        return;
    }
    for (const key in valuesParams) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setValue(key as FieldName<TFieldValues>, valuesParams[key] != null ? valuesParams[key] : undefined);
    }
}

export async function updateFormValidationAndShowErrors<TFieldValues extends FieldValues = FieldValues>(
    form: UseFormMethods<TFieldValues>,
    ev: FormEvent<HTMLFormElement>,
): Promise<boolean> {
    ev.preventDefault();
    ev.persist();
    const isFormValid = await form.trigger();
    if (!isFormValid) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        await form.handleSubmit(() => {})(ev);
    }
    return isFormValid;
}
