type PersistenceServiceDeserialize<Source, T> = (params: Source) => T;

export class PersistenceService {
    get length(): number {
        return localStorage.length;
    }

    getItem<Source, T>(key: string, deserializeFn?: PersistenceServiceDeserialize<Source, T>): T | null {
        const data = localStorage.getItem(key);
        try {
            const dataParsed = data ? JSON.parse(data) : null;

            if (deserializeFn instanceof Function) {
                return deserializeFn(dataParsed);
            }

            return dataParsed;
        } catch (e) {
            console.error(e);
        }

        return null;
    }

    setItem(key: string, someData: unknown): void {
        localStorage.setItem(key, JSON.stringify(someData));
    }

    clear(): void {
        localStorage.clear();
    }

    removeItem(key: string): void {
        localStorage.removeItem(key);
    }
}

export const persistenceService = new PersistenceService();
