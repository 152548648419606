import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Breadcrumb } from '../../../componentsUx/breadcrumb/Breadcrumb';
import { APP_PATHS_FROM_ROOT } from '../../routing/commands';
import { CourseCompetencyCard } from './CourseCompetencyCard';

export function CourseCompetency(): JSX.Element {
    const { t } = useTranslation();
    const history = useHistory();
    const { id, courseProgramId, branchId } = useParams<{ id: string; courseProgramId: string; branchId: string }>();

    const breadcrumbItems = [
        {
            text: t('HOME_PAGE_NAV_ACTION_COMPANY'),
            key: 'HOME_PAGE_NAV_ACTION_COMPANY',
            onClick: (): void => history.push(APP_PATHS_FROM_ROOT.companyPage()),
        },
        {
            text: t('BRANCH_CARD_TITLE'),
            key: 'BRANCH_CARD_TITLE',
            onClick: (): void => history.push(APP_PATHS_FROM_ROOT.branchPage(branchId)),
        },
        {
            text: t('COURSE_PROGRAM_CARD_TITLE'),
            key: 'COURSE_PROGRAM_CARD_TITLE',
            onClick: (): void => history.push(APP_PATHS_FROM_ROOT.courseProgramPage(branchId, courseProgramId)),
        },
        {
            text: t('COURSE_COMPETENCY_CARD_TITLE'),
            key: 'COURSE_COMPETENCY_CARD_TITLE',
            isCurrentItem: true,
        },
    ];

    return (
        <>
            <Breadcrumb items={breadcrumbItems} />
            <CourseCompetencyCard courseProgramId={courseProgramId} isAddMode={id === 'new'} courseCompetencyId={id} branchId={branchId} />
        </>
    );
}
