export const enumValues = (enumRef: Record<string, unknown>, isNumericEnum?: boolean): (number | string)[] => {
    let enumValues = Object.values(enumRef);
    if (isNumericEnum) {
        // Numeric enums contain also the reverse mapping
        const keys = Object.keys(enumRef).filter((key) => typeof enumRef[key as any] === 'number');
        enumValues = keys.map((key) => enumRef[key as any]);
    }

    return enumValues as (number | string)[];
};
