import * as yup from 'yup';
import { MixedSchema } from 'yup';
import { ErrorCode } from './errors.util';
import { isInitialized } from '../../utils/isInitialized';

export enum NumberType {
    Integer = 'INTEGER',
}

type NumberDefaults = number | '';

export function numberValidator(
    options: { isRequired?: boolean; type?: NumberType } = { isRequired: false, type: NumberType.Integer },
): MixedSchema<NumberDefaults> {
    if (!isInitialized(options.type)) {
        options.type = NumberType.Integer;
    }

    const schemaRecords: Record<NumberType, MixedSchema<NumberDefaults>> = {
        [NumberType.Integer]: yup.mixed<NumberDefaults>().test(
            ErrorCode.Integer,
            ErrorCode.Integer,
            (receivedValue: unknown): Promise<boolean> => {
                const value = receivedValue as NumberDefaults;
                if (!options.isRequired && value === '') {
                    return Promise.resolve(true);
                }

                let validator = yup
                    .number()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .typeError(ErrorCode.Integer)
                    .integer(ErrorCode.Integer);

                if (options.isRequired) {
                    validator = validator.required();
                } else {
                    validator = validator.optional();
                }
                return validator.isValid(value);
            },
        ),
    };

    return schemaRecords[options.type];
}
