import styled from 'styled-components';

export const CardActions = styled.div`
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    // All siblings have a margin left of 10 pixels
    & > {
        * + * {
            margin-left: 10px;
        }
    }
`;
