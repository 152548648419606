import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Card } from '../../../componentsUx/card/Card';
import { CardActions } from '../../../componentsUx/card/CardActions';
import { CardTitle } from '../../../componentsUx/card/CardTitle';
import { InputField } from '../../../componentsUx/input/InputField';
import { SubmitButton } from '../../../componentsUx/input/SubmitButton';
import { companyService } from '../../../services/dataAccess';
import { useSession } from '../../../state/SessionState';
import { useFpForm, setFormValues } from '../../../utils/useFormHook';
import { CompanyApiModel } from './companyApiModel';
import { stringValidator } from '../../../services/validators/stringValidator';

type CompanyFormFields = Omit<CompanyApiModel, 'id'>;

const CompanyFormSchema = yup.object().shape<CompanyFormFields>({
    name: stringValidator({ isRequired: true }),
    directorName: stringValidator(),
    fiscalNumber: stringValidator({ isRequired: true }),
    registerNumber: stringValidator({ isRequired: true }),
});

export function CompanyCard(): JSX.Element {
    const { t } = useTranslation();
    const [company, setCompany] = useState<CompanyApiModel | null>(null);
    const { control, formState, handleSubmit, setValue } = useFpForm<CompanyFormFields>({
        schema: CompanyFormSchema,
        defaultValues: {
            name: '',
            fiscalNumber: '',
            directorName: '',
            registerNumber: '',
        },
    });
    const [session] = useSession();

    useEffect(() => {
        const readCompany = async (): Promise<void> => {
            const companyDetails = await companyService.getCurrentCompany();
            setCompany(companyDetails);
            setFormValues(companyDetails, setValue);
        };

        readCompany();
    }, [setValue]);

    async function saveCompanyDetails(params: CompanyFormFields): Promise<void> {
        if (company) {
            await companyService.updateCurrentCompany({
                id: company.id,
                ...params,
            });
        }
    }

    return (
        <Card>
            <CardTitle>{t('COMPANY_CARD_TITLE')}</CardTitle>
            <form onSubmit={handleSubmit(saveCompanyDetails)}>
                <InputField
                    control={control}
                    errors={formState.errors}
                    name="name"
                    required={true}
                    internalProps={{
                        label: t('COMPANY_CARD_FIELD_NAME'),
                        disabled: !session.isCompanyAdmin,
                    }}
                />
                <InputField
                    control={control}
                    errors={formState.errors}
                    name="directorName"
                    internalProps={{
                        label: t('COMPANY_CARD_FIELD_DIRECTOR_NAME'),
                        disabled: !session.isCompanyAdmin,
                    }}
                />
                <InputField
                    control={control}
                    errors={formState.errors}
                    name="fiscalNumber"
                    required={true}
                    internalProps={{
                        label: t('COMPANY_CARD_FIELD_FISCAL_NUMBER'),
                        disabled: !session.isCompanyAdmin,
                    }}
                />
                <InputField
                    control={control}
                    errors={formState.errors}
                    name="registerNumber"
                    required={true}
                    internalProps={{
                        label: t('COMPANY_CARD_FIELD_REGISTER_NUMBER'),
                        disabled: !session.isCompanyAdmin,
                    }}
                />
                {session.isCompanyAdmin && (
                    <CardActions>
                        <SubmitButton label={t('FORM_SAVE')} />
                    </CardActions>
                )}
            </form>
        </Card>
    );
}
