import styled from 'styled-components';
import { CompanyCard } from './CompanyCard';
import { BranchesListCard } from '../branch/BranchesListCard';
import { device } from '../../../componentsUx/cssUtils/breakpoints';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumb } from '../../../componentsUx/breadcrumb/Breadcrumb';

const CompanyGrid = styled.div`
    display: grid;
    grid-column-gap: 28px;
    grid-row-gap: 28px;

    @media ${device.featurePhone} {
        grid-template-areas: 'companyCard' 'branchesListCard';
        grid-template-columns: minmax(0, 1fr);
    }

    @media ${device.tabletLandscape} {
        grid-template-columns: 1fr minmax(0, 2fr);
        grid-template-areas: 'companyCard branchesListCard';
    }
`;

const GridArea = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 0;
`;

const CompanyGridArea = styled(GridArea)`
    grid-area: companyCard;
`;
const BranchListGridArea = styled(GridArea)`
    grid-area: branchesListCard;
`;

export function Company(): JSX.Element {
    const { t } = useTranslation();

    const breadcrumbItems = [
        {
            text: t('HOME_PAGE_NAV_ACTION_COMPANY'),
            key: 'HOME_PAGE_NAV_ACTION_COMPANY',
            isCurrentItem: true,
        },
    ];

    return (
        <>
            <Breadcrumb items={breadcrumbItems} />
            <CompanyGrid>
                <CompanyGridArea>
                    <CompanyCard />
                </CompanyGridArea>
                <BranchListGridArea>
                    <BranchesListCard />
                </BranchListGridArea>
            </CompanyGrid>
        </>
    );
}
