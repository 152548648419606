import i18n from 'i18next';

export const dateParser = (date: Date): string => {
    if (!date) {
        return '';
    }
    let processedDate = date;

    if (typeof date === 'string') {
        processedDate = new Date(date);
    }

    return processedDate.toLocaleDateString(i18n.language).split('T')[0];
};
