import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useSetRecoilState } from 'recoil';
import * as yup from 'yup';
import { Card } from '../../../componentsUx/card/Card';
import { CardActions } from '../../../componentsUx/card/CardActions';
import { CardTitle } from '../../../componentsUx/card/CardTitle';
import { InputField } from '../../../componentsUx/input/InputField';
import { SubmitButton } from '../../../componentsUx/input/SubmitButton';
import { useShowError, useShowInfo } from '../../../componentsUx/toast/toastState';
import { authService, branchService } from '../../../services/dataAccess';
import { SessionState, useSession } from '../../../state/SessionState';
import { useFpForm, setFormValues } from '../../../utils/useFormHook';
import { AddressForm } from '../../address/AddressForm';
import { APP_PATHS_FROM_ROOT } from '../../routing/commands';
import { BranchApiModelRelations, BranchApiModel } from './branchApiModel';
import { stringValidator } from '../../../services/validators/stringValidator';
import { defaultMissingAddressValues } from '../../address/addressApiModel';

type BranchWithAddress = BranchApiModel & BranchApiModelRelations.Address;
type BranchFormFields = Omit<BranchWithAddress, 'id' | 'companyId'>;

const BranchFormSchema = yup.object().shape<BranchFormFields>({
    name: stringValidator({ isRequired: true }),
    email: stringValidator({ isRequired: true, isEmail: true }),
    phoneNumber: stringValidator(),
    address: yup.object(),
});

export function BranchCard(parameters: { branchId: string; isInAddMode: boolean }): JSX.Element {
    const { t } = useTranslation();
    const isAddMode = parameters.branchId === 'new';
    const [branch, setBranch] = useState<Partial<BranchWithAddress> | null>(null);

    const [session] = useSession();

    const history = useHistory();

    const { control, formState, handleSubmit, setValue, getValues } = useFpForm<BranchFormFields>({
        schema: BranchFormSchema,
        defaultValues: {
            name: '',
            email: '',
            phoneNumber: '',
            address: defaultMissingAddressValues(),
        },
    });
    const showToastInfo = useShowInfo();
    const showToastError = useShowError();

    useEffect(() => {
        const readBranch = async (): Promise<void> => {
            const branchDetails = isAddMode ? { name: '', email: '', phoneNumber: '' } : await branchService.getBranch(parameters.branchId);
            setBranch(branchDetails);
            setFormValues(branchDetails, setValue);
        };

        readBranch();
    }, [setValue, parameters.branchId, isAddMode]);
    const setSession = useSetRecoilState(SessionState);

    async function saveBranchDetails(params: BranchFormFields): Promise<void> {
        if (!branch) {
            return;
        }
        const companyId = branch.companyId ?? session.getCompanyId();
        if (!companyId) {
            showToastError({ infoMessage: t('BRANCH_CARD_CREATE_UNSUCCESFULLY') });
            return;
        }
        const branchData = {
            id: parameters.branchId,
            companyId: companyId,
            ...params,
        };
        let branchId: string | null = null;

        if (parameters.isInAddMode) {
            branchId = await branchService.addBranch(branchData);
            // When we add a new branch, we need to get a new token which will have the updated permissions in order to access the branch data.
            const sessionData = await authService.repopulateToken();
            setSession(sessionData);
        } else {
            branchId = await branchService.updateBranch(branchData);
        }

        showToastInfo({ infoMessage: t('BRANCH_CARD_SAVE_SUCCESFULLY') });
        if (branchId && branchId !== parameters.branchId) {
            history.push(APP_PATHS_FROM_ROOT.branchPage(branchId));
        }
    }

    return (
        <Card>
            <CardTitle>{t('BRANCH_CARD_TITLE')}</CardTitle>
            <form onSubmit={handleSubmit(saveBranchDetails)}>
                <InputField
                    control={control}
                    errors={formState.errors}
                    name="name"
                    required={true}
                    internalProps={{
                        label: t('BRANCH_CARD_FIELD_NAME'),
                    }}
                />
                <InputField
                    control={control}
                    errors={formState.errors}
                    name="email"
                    internalProps={{
                        label: t('BRANCH_CARD_EMAIL'),
                    }}
                />
                <InputField
                    control={control}
                    errors={formState.errors}
                    name="phoneNumber"
                    internalProps={{
                        label: t('BRANCH_CARD_FIELD_PHONE_NUMBER'),
                    }}
                />

                <AddressForm control={control} errors={formState.errors} name="address" getValues={getValues} />

                <CardActions>
                    <SubmitButton label={t('FORM_SAVE')} />
                </CardActions>
            </form>
        </Card>
    );
}
