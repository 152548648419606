import { Stack } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StudentProgramAttendanceListItemApiModel } from '../../studentSeriesAttendance/studentProgramAttendanceApiModel';
import { CourseProgramFilterPicker } from './CourseProgramFilterPicker';
import { FilterPickerProps } from './filterPickerProps';
import { InputPicker } from '../../../../componentsUx/inputPicker/InputPicker';

export function StudentProgramAttendanceFilterPicker(props: FilterPickerProps): JSX.Element {
    const [courseProgramId, setCourseProgramId] = useState<string | null>(null);
    const [selectedStudent, setSelectedStudent] = useState<StudentProgramAttendanceListItemApiModel | null>(null);
    const [t] = useTranslation();
    const onPickerChange = (newSelected: StudentProgramAttendanceListItemApiModel): void => {
        setSelectedStudent(newSelected);
        props.setId(newSelected?.id);
    };

    useEffect(() => {
        setSelectedStudent(null);
    }, [setSelectedStudent, courseProgramId]);

    return (
        <Stack>
            <CourseProgramFilterPicker id={courseProgramId} setId={setCourseProgramId} prefill={props.prefill} errorMessage={props.errorMessage} />
            {courseProgramId && (
                <InputPicker
                    label={t('REPORT_FILTER_PICKER_STUDENT_PROGRAM_ATTENDANCE')}
                    value={selectedStudent}
                    errorMessage={props.errorMessage}
                    onChange={onPickerChange}
                    apiEndpoint="StudentProgramAttendance"
                    allowFreeText={false}
                    displayItem={(c: StudentProgramAttendanceListItemApiModel): string => c && `${c.firstName} ${c.lastName}`}
                    renderItem={(c: StudentProgramAttendanceListItemApiModel): JSX.Element => (
                        <div>
                            {c?.firstName} {c?.lastName} ({t('REPORT_FILTER_PICKER_STUDENT_CONTRACT')} {c?.contractNumber} )
                        </div>
                    )}
                    getApiParams={(): Record<string, unknown> => {
                        return {
                            courseProgramId: courseProgramId,
                        };
                    }}
                />
            )}
        </Stack>
    );
}
