import React from 'react';
import styled from 'styled-components';
import { Card } from '../../../componentsUx/card/Card';
import { apiUrls } from '../../../environment/apiUrls';

const StyledIframe = styled.iframe`
    border: 0px solid #ffffff;
    width: 100%;
    min-height: 600px;
    height: 75vh;
`;

export function PreviewGeneratedReportCard(props: { generatedReportId: string }): JSX.Element {
    const apiUrl = apiUrls.API;
    return (
        <Card>
            <StyledIframe title="Generated Report" src={`${apiUrl}/Report/GeneratedReport/${props.generatedReportId}/0`} />
        </Card>
    );
}
