import { AxiosInstance } from 'axios';
import { StudentsListCardQuery } from '../../../componentsBusiness/studentListCard/studentsListCardQuery';
import { StudentListItemApiModel, StudentsListApiModel } from '../../../models/studentApiModel';
import { PaginatedApiInput, createPaginatedOutput, PaginatedApiOutput } from '../../common/paginatedApiOutput';

export interface SeriesStudentListCardApiInput {
    showClosed?: boolean;
    seriesId: string;
}

export class SeriesStudentListCardQuery implements StudentsListCardQuery<SeriesStudentListCardApiInput> {
    private studentsSeriesAttendance = 'StudentSeriesAttendance/SeriesStudents';

    constructor(private httpClient: AxiosInstance) {}

    public async run(studentsListApiModel: Partial<SeriesStudentListCardApiInput> & PaginatedApiInput): Promise<StudentsListApiModel> {
        const { data } = await this.httpClient.get<PaginatedApiOutput<StudentListItemApiModel>>(`${this.studentsSeriesAttendance}/GetPage`, {
            params: studentsListApiModel,
        });

        return createPaginatedOutput(data);
    }
}
