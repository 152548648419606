import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { Card } from '../../componentsUx/card/Card';
import { device } from '../../componentsUx/cssUtils/breakpoints';
import { CenteredPage } from '../../componentsUx/pageContent/CentredPage';
import { PATHS } from '../routing/commands';
import { Login } from './Login';

const AuthContentSize = styled.div`
    width: 100%;

    @media ${device.featurePhone} {
        max-width: 80%;
        min-width: 296px;
    }

    @media ${device.tabletPortrait} {
        max-width: 480px;
    }
`;

export function Auth(): JSX.Element {
    const match = useRouteMatch();
    return (
        <CenteredPage>
            <AuthContentSize>
                <Card>
                    <Route path={`${match?.path}/${PATHS.login}`}>
                        <Login />
                    </Route>
                </Card>
            </AuthContentSize>
        </CenteredPage>
    );
}
