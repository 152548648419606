import { convertToString } from '../../utils/convertToString';
import { InMemoryApiService } from './inMemoryApi.service';
import { PaginatedApiOutput } from './paginatedApiOutput';

export class CurrencyListService implements InMemoryApiService<string> {
    private currencyList = ['lei', 'RON', '€', 'EUR', '$', 'USD', '£', 'GBP', '¥', 'JPY'];

    getCurrencySuggestions(searchText?: string): Promise<string[]> {
        const searchTextLower = (searchText ?? '').toLocaleLowerCase();
        return Promise.resolve(this.currencyList.filter((it) => it.toLocaleLowerCase().includes(searchTextLower)));
    }

    getItems(parameters: Record<string, unknown>): Promise<PaginatedApiOutput<string>> {
        const searchText = convertToString(parameters.searchText as string).toLocaleLowerCase();
        const currencyItems = this.currencyList.filter((it) => it.toLocaleLowerCase().includes(searchText));
        return Promise.resolve({
            hasNextPage: false,
            items: currencyItems,
            totalItems: 0,
        });
    }
}
