import React from 'react';
import { Stack } from '@fluentui/react';
import { InputField } from '../../../componentsUx/input/InputField';
import { DictionaryOf } from '../../../utils/dictionaryType';
import { FilterPickerProps } from './filterPickers/filterPickerProps';
import { ReportFilterApiModel, ReportFilterType } from './reportFilterApiModel';
import { Control } from 'react-hook-form';
import { ControllerInputProps } from '../../../componentsUx/input/InputFieldTypes';
import { useTranslation } from 'react-i18next';
import { ReportFilterVariableSeparator } from './ReportFilterVariableSeparator';
import { ReportFilterEntityPicker } from './filterPickers/ReportFilterEntityPicker';

const filterTypeToInputTypeMap = {
    [ReportFilterType.Boolean]: 'toggle',
    [ReportFilterType.String]: 'text',
    [ReportFilterType.Numeric]: 'number',
    [ReportFilterType.Date]: 'date-picker',
    [ReportFilterType.Time]: 'time-span',
    [ReportFilterType.Guid]: 'custom',
};

interface ReportFilterParameters {
    filter: ReportFilterApiModel;
    errors: DictionaryOf<any>;
    control: Control;
    defaultValue: unknown;
    prefillFilterValues: FilterPickerProps['prefill'];
}

export function ReportFilter(props: ReportFilterParameters): JSX.Element {
    const [t] = useTranslation();
    return (
        <Stack>
            <ReportFilterVariableSeparator description={props.filter.description} />
            <InputField
                name={props.filter.id}
                errors={props.errors}
                type={filterTypeToInputTypeMap[props.filter.type]}
                control={props.control}
                internalProps={{ label: '' }}
                customField={(fieldProps: ControllerInputProps): JSX.Element => (
                    <ReportFilterEntityPicker
                        entityType={props.filter.entityType}
                        errorMessage={fieldProps.errorMessage}
                        id={fieldProps.value}
                        setId={fieldProps.onChange}
                        prefill={props.prefillFilterValues}
                    />
                )}
                rules={{ required: props.filter.isMandatory ? (t('REQUIRED') as string) : false }}
                defaultValue={props.defaultValue ?? ''}
            />
        </Stack>
    );
}
