export const BreakpointSize = {
    featurePhone: 0,
    phone: 320,
    tabletPortrait: 600,
    tabletLandscape: 900,
    desktop: 1236,
    bigDesktop: 1800,
};

export const device = {
    featurePhone: `(min-width: ${BreakpointSize.featurePhone}px)`,
    phone: `(min-width: ${BreakpointSize.phone}px)`,
    tabletPortrait: `(min-width: ${BreakpointSize.tabletPortrait}px)`,
    tabletLandscape: `(min-width: ${BreakpointSize.tabletLandscape}px)`,
    desktop: `(min-width: ${BreakpointSize.desktop}px)`,
    bigDesktop: `(min-width: ${BreakpointSize.bigDesktop}px)`,
};
