import { defaultsDeep } from 'lodash';
import * as yup from 'yup';
import { defaultMissingAddressValues } from '../../features/address/addressApiModel';
import { EditableFormFields, FormFieldValues, PickerFieldValue } from '../../features/common/baseApiModel';
import { BranchApiModel, defaultBranchMissingValues } from '../../features/home/branch/branchApiModel';
import { SexType } from '../../models/enums/sexType';
import { StudiesType } from '../../models/enums/studiesType';
import { StudentApiModel, StudentRelationsApiModel } from '../../models/studentApiModel';
import { dateValidator } from '../../services/validators/dateValidator';
import { enumValuesValidator } from '../../services/validators/enumValuesValidator';
import { ErrorCode } from '../../services/validators/errors.util';
import { requiredPickerValue } from '../../services/validators/pickerValueValidator';
import { fatherInitial, stringValidator } from '../../services/validators/stringValidator';

export type ValidFormValues = EditableFormFields<Omit<StudentApiModel, 'branchId'>> &
    StudentRelationsApiModel.Address & {
        branch: PickerFieldValue<BranchApiModel>;
    };

export type FormValues = FormFieldValues<ValidFormValues>;

export function defaultMissingStudentValues(student?: Partial<FormValues> | null): FormValues {
    return defaultsDeep(student ?? {}, {
        seriesIdCard: '',
        studiesType: '',
        socialSecurityNumber: '',
        sexType: '',
        phoneNumber: '',
        numberIdCard: '',
        motherName: '',
        fatherName: '',
        bornCity: '',
        bornCounty: '',
        birthDate: '',
        branch: defaultBranchMissingValues(student?.branch),
        lastName: '',
        firstName: '',
        address: defaultMissingAddressValues(student?.address),
        email: '',
        parentInitials: '',
        company: '',
    });
}

export const FormSchema = {
    fields: {
        firstName: stringValidator({ isRequired: true }),
        lastName: stringValidator({ isRequired: true }),
        email: stringValidator({ isRequired: true, isEmail: true }),
        address: yup.object(),
        birthDate: dateValidator({ isRequired: true }),
        bornCity: stringValidator(),
        bornCounty: stringValidator(),
        fatherName: stringValidator(),
        parentInitials: fatherInitial(),
        motherName: stringValidator(),
        phoneNumber: stringValidator({ isRequired: true }),
        sexType: enumValuesValidator<SexType>({ enumRef: SexType, isNumericEnum: true }),
        socialSecurityNumber: stringValidator({
            isRequired: true,
            length: {
                size: 13,
                errorCode: ErrorCode.InvalidSocialSecurityNumber,
            },
        }),
        studiesType: enumValuesValidator({ enumRef: StudiesType, isNumericEnum: true }),
        numberIdCard: stringValidator({ isRequired: true }),
        seriesIdCard: stringValidator({ isRequired: true }),
        branch: requiredPickerValue(),
        company: stringValidator(),
    },
    dependencies: [],
};

export const StudentDetailsSchema = yup.object().shape(FormSchema.fields, FormSchema.dependencies);
