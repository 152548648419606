import { BreakpointSize } from './breakpoints';

// TODO recheck this if we want to have a left menu but this has to be rechecked
const leftMenuSize = 0;

export const CssGlobals = {
    pagePadding: {
        smallScreen: 8,
        mediumScreen: 12,
        largeScreen: 18,
    },
    buttonPadding: 8,
    maxContentWidth: BreakpointSize.desktop - leftMenuSize,
};
