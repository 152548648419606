import { AxiosInstance } from 'axios';
import { PaginatedApiOutput, createPaginatedOutput, GenericCommandResultOutput, BranchOwnedPaginatedApiInput } from '../../common/paginatedApiOutput';
import {
    CourseProgramApiModel,
    CourseProgramListApiModel,
    CourseProgramListItemApiModel,
    CourseProgramApiModelRelations,
} from './courseProgramApiModel';

export type GetCourseProgramApiModel = CourseProgramApiModel & CourseProgramApiModelRelations.Specialization;
export type CourseProgramInputApiModel = Omit<CourseProgramApiModel, 'specializationName' | 'specializationCode'> & {
    specializationId: string;
};

export class CourseProgramService {
    readonly courseProgramEndpoint = `CourseProgram`;

    constructor(private httpClient: AxiosInstance) {}

    public async readCoursePrograms(courseProgramListApiModel: BranchOwnedPaginatedApiInput): Promise<CourseProgramListApiModel> {
        const { data } = await this.httpClient.get<PaginatedApiOutput<CourseProgramListItemApiModel>>(`${this.courseProgramEndpoint}/GetPage`, {
            params: courseProgramListApiModel,
        });

        return createPaginatedOutput(data);
    }

    public async updateCourseProgram(params: CourseProgramInputApiModel): Promise<string | null> {
        const result = await this.httpClient.put<GenericCommandResultOutput>(`${this.courseProgramEndpoint}/Update`, params);
        return result.data.id;
    }

    public async addCourseProgram(params: CourseProgramInputApiModel): Promise<string | null> {
        const result = await this.httpClient.post<GenericCommandResultOutput>(`${this.courseProgramEndpoint}/Create`, params);
        return result.data.id;
    }

    public async getCourseProgram(courseProgramId: string): Promise<GetCourseProgramApiModel> {
        const { data } = await this.httpClient.get<GetCourseProgramApiModel>(`${this.courseProgramEndpoint}/${courseProgramId}`);
        return data;
    }
}
