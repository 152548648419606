import { IColumn } from '@fluentui/react';
import { startCase } from 'lodash';

export function createDefaultColumn<TEntity, EntityProp extends keyof TEntity>(
    params: {
        fieldName: EntityProp;
        name: string;
        data: TEntity[EntityProp];
    } & Partial<Omit<IColumn, 'fieldName' | 'name' | 'date'>>,
): IColumn {
    return createColumn(params);
}

export function createColumn(
    params: {
        fieldName: unknown;
        name: string;
        data: unknown;
    } & Partial<Omit<IColumn, 'fieldName' | 'name' | 'date'>>,
): IColumn {
    return {
        key: `column${startCase(params.name)}`,
        minWidth: 100,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        ...params,
        fieldName: params.fieldName as string,
    };
}
