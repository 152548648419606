import React from 'react';
import { Control } from 'react-hook-form';
import { Stack } from '@fluentui/react';
import { InputField } from '../../../componentsUx/input/InputField';
import { DictionaryOf } from '../../../utils/dictionaryType';
import { ReportVariableApiModel, ReportVariableType } from './reportVariableApiModel';
import { useTranslation } from 'react-i18next';
import { ReportFilterVariableSeparator } from './ReportFilterVariableSeparator';

const variableTypeToInputTypeMap = {
    [ReportVariableType.Boolean]: 'toggle',
    [ReportVariableType.String]: 'text',
    [ReportVariableType.Numeric]: 'number',
    [ReportVariableType.Date]: 'date-picker',
    [ReportVariableType.Time]: 'time-span',
};

interface ReportVariableParameters {
    variable: ReportVariableApiModel;
    errors: DictionaryOf<any>;
    control: Control;
    defaultValue: unknown;
}

export function ReportVariable(props: ReportVariableParameters): JSX.Element {
    const [t] = useTranslation();
    return (
        <Stack>
            <ReportFilterVariableSeparator description={props.variable.name} />
            <InputField
                name={props.variable.id}
                errors={props.errors}
                type={variableTypeToInputTypeMap[props.variable.type]}
                control={props.control}
                internalProps={{ label: '' }}
                rules={{ required: props.variable.isMandatory ? (t('REQUIRED') as string) : false }}
                defaultValue={props.defaultValue ?? ''}
            />
        </Stack>
    );
}
