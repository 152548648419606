import { Layer } from '@fluentui/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { convertSecondsToMilliseconds } from '../../utils/convertSecondsToMilliseconds';
import { ToastCard, ToastContainer } from './toastCard';
import { InfoToastState } from './toastState';

function Toast(): JSX.Element {
    const [toast, setToastState] = useRecoilState(InfoToastState);
    const { t } = useTranslation();

    useEffect(() => {
        if (!toast) {
            return;
        }

        const timeoutId = setTimeout(() => {
            setToastState((previousState) => {
                if (previousState === toast) {
                    return null;
                }
                return previousState;
            });
        }, convertSecondsToMilliseconds(toast.duration));

        return (): void => {
            clearTimeout(timeoutId);
        };
    }, [toast, setToastState]);

    if (!toast) {
        return <></>;
    }

    return (
        <Layer>
            <ToastContainer>
                <ToastCard $messageType={toast.messageType}>{t(toast.infoMessage, toast.infoMessageParams)}</ToastCard>
            </ToastContainer>
        </Layer>
    );
}

export default Toast;
