import { defaultsDeep } from 'lodash';

export interface AddressApiModel {
    street?: string;
    number?: string;
    block?: string;
    apartment?: string;
    level?: string;
    staircase?: string;
    zipCode?: string;
    city?: string;
    county?: string;
    country?: string;
}

export function defaultMissingAddressValues(address?: Partial<AddressApiModel> | null): AddressApiModel {
    return defaultsDeep(address ?? {}, {
        street: '',
        number: '',
        block: '',
        apartment: '',
        level: '',
        staircase: '',
        zipCode: '',
        city: '',
        county: '',
        country: '',
    });
}
