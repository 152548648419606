import { stringify } from 'query-string';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { encodeQueryParams, JsonParam } from 'use-query-params';
import { StudentsListCard } from '../../../componentsBusiness/studentListCard';
import { device } from '../../../componentsUx/cssUtils/breakpoints';
import { StudentListItemApiModel } from '../../../models/studentApiModel';
import { seriesStudentListCardQuery, seriesService } from '../../../services/dataAccess';
import { isInitialized } from '../../../utils/isInitialized';
import { useEffectAsync } from '../../../utils/useEffectAsync';
import { APP_PATHS_FROM_ROOT, isNewEntityId } from '../../routing/commands';
import { ReportFilterEntityType } from '../report/filterPickers/reportFilterEntityType';
import { ReportListItemApiModel } from '../report/reportApiModel';
import { ReportsListCard } from '../report/ReportsListCard';
import { SeriesQueryParam } from '../studentSeriesAttendance/useStudentSeriesAttendance';
import { SeriesCard } from './SeriesCard';
import { SeriesDetailsResponse } from './seriesService';
import { useTranslation } from 'react-i18next';
import { Breadcrumb } from '../../../componentsUx/breadcrumb/Breadcrumb';

const SeriesGrid = styled.div`
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    @media ${device.featurePhone} {
        grid-template-areas: 'serieCard' 'usersAndReportsListCard';
        grid-template-columns: minmax(0, 1fr);
    }

    @media ${device.tabletLandscape} {
        grid-template-columns: 1fr minmax(0, 2fr);
        grid-template-areas: 'serieCard usersAndReportsListCard';
    }
`;

const GridCard = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 0;
`;

const SeriesCardArea = styled(GridCard)`
    grid-area: serieCard;
`;

const SeriesUsersAndReportsArea = styled(GridCard)`
    grid-area: usersAndReportsListCard;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
`;

export function Series(): JSX.Element {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const { t } = useTranslation();
    const [series, setSeries] = useState<SeriesDetailsResponse | null>(null);

    useEffectAsync(
        async ({ canceled }): Promise<void> => {
            if (isNewEntityId(id)) {
                return;
            }
            const seriesDetails = await seriesService.getSeries(id);
            if (canceled) {
                return;
            }
            setSeries(seriesDetails);
        },
        [id],
    );

    function onAddStudentToSeries(): void {
        if (!isInitialized(series)) {
            return;
        }
        const seriesQueryParam: SeriesQueryParam = seriesService.mapSeriesDetailsToListItem(series);
        const queryParams = encodeQueryParams({ series: JsonParam }, { series: seriesQueryParam });
        history.push({
            pathname: APP_PATHS_FROM_ROOT.newStudentSeriesAttendanceFromSerie(),
            search: stringify(queryParams),
        });
    }

    const onReportClicked = (model: ReportListItemApiModel): void => {
        if (!series) {
            return;
        }
        history.push({
            pathname: APP_PATHS_FROM_ROOT.reportPage(model.id),
            search: stringify(
                encodeQueryParams(
                    { prefillFilterValues: JsonParam },
                    {
                        prefillFilterValues: {
                            branch: series.branch,
                            series: seriesService.mapSeriesDetailsToListItem(series),
                        },
                    },
                ),
            ),
        });
    };

    const breadcrumbItems = [
        {
            text: t('HOME_PAGE_NAV_ACTION_DASHBOARD'),
            key: 'HOME_PAGE_NAV_ACTION_DASHBOARD',
            onClick: (): void => history.push(APP_PATHS_FROM_ROOT.dashboard()),
        },
        {
            text: t('SERIE_CARD_TITLE'),
            key: 'SERIE_CARD_TITLE',
            isCurrentItem: true,
        },
    ];

    return (
        <>
            <Breadcrumb items={breadcrumbItems} />
            <SeriesGrid>
                <SeriesCardArea>
                    <SeriesCard seriesId={id} series={series} />
                </SeriesCardArea>
                <SeriesUsersAndReportsArea>
                    <StudentsListCard
                        service={seriesStudentListCardQuery}
                        staticServiceParams={{ seriesId: id }}
                        allowAdd={true}
                        onAdd={onAddStudentToSeries}
                        onItemClicked={(model: StudentListItemApiModel): void =>
                            history.push(APP_PATHS_FROM_ROOT.editStudentSeriesAttendanceFromSerie(model.id))
                        }
                    />
                    <ReportsListCard entityType={ReportFilterEntityType.Series} onReportClicked={onReportClicked} />
                </SeriesUsersAndReportsArea>
            </SeriesGrid>
        </>
    );
}
