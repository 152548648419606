import { Dropdown, Stack, Toggle, IDropdownOption } from '@fluentui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputDateRange } from '../../../../componentsUx/inputDateRange/InputDateRange';
import { InputPicker } from '../../../../componentsUx/inputPicker/InputPicker';
import { DateToFilter, getDateToFilterOptions } from '../../../common/dateToFilter';
import { SeriesListItemApiModel } from '../../serie/seriesApiModel';
import { BranchFilterPicker } from './BranchFilterPicker';
import { FilterPickerProps } from './filterPickerProps';
import { convertSeriesToString } from '../../../../utils/convertToString';
import { SeriesItem } from '../../../../componentsBusiness/studentSeriesAttendanceEditor/StudentSeriesAttendanceEditorFields';

export function SeriesFilterPicker({ prefill, errorMessage, setId }: FilterPickerProps): JSX.Element {
    const [branchId, setBranchId] = useState<string | null>(prefill?.branch?.id ?? null);
    const [selectedSeries, setSelectedSeries] = useState<SeriesListItemApiModel | null>(prefill?.series ?? null);
    const [showClosedSeries, setClosedSeries] = useState<boolean>(false);
    const [t] = useTranslation();
    const [dateToFilter, setDateToFilter] = useState<number>(DateToFilter.StartDate);
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');

    const onSeriesChangeChange = (newSelected: SeriesListItemApiModel): void => {
        setSelectedSeries(newSelected);
        setId(newSelected?.id);
    };

    const dropDownOptions = getDateToFilterOptions(t);
    const onDateToFilterSelect = (_, option?: IDropdownOption | undefined): void => {
        option && setDateToFilter(Number(option.key));
        setSelectedSeries(null);
    };

    const onDateChange = (dateSetter: (date: string) => void): ((newDate: string) => void) => {
        return (newDate: string): void => {
            dateSetter(newDate);
            setSelectedSeries(null);
        };
    };

    return (
        <Stack>
            <BranchFilterPicker id={branchId} setId={setBranchId} prefill={prefill} errorMessage={errorMessage} />
            {branchId && (
                <Stack>
                    <Toggle
                        label={t('SERIES_LIST_CARD_SHOW_CLOSED_SERIES')}
                        onChange={(): void => {
                            setClosedSeries((oldValue) => !oldValue);
                        }}
                    />
                    <Dropdown
                        label={t('SERIES_LIST_CARD_CHOOSE_FILTER_DATE')}
                        options={dropDownOptions}
                        selectedKey={dateToFilter}
                        onChange={onDateToFilterSelect}
                    />
                    <InputDateRange
                        startDate={{
                            label: t('SERIES_LIST_CARD_CHOOSE_START_DATE'),
                            value: startDate,
                            onChange: onDateChange(setStartDate),
                        }}
                        endDate={{
                            label: t('SERIES_LIST_CARD_CHOOSE_END_DATE'),
                            value: endDate,
                            onChange: onDateChange(setEndDate),
                        }}
                    />
                    <InputPicker
                        label={t('REPORT_FILTER_PICKER_SERIES')}
                        value={selectedSeries}
                        errorMessage={errorMessage}
                        onChange={onSeriesChangeChange}
                        apiEndpoint="Series"
                        allowFreeText={false}
                        displayItem={(t: SeriesListItemApiModel): string => convertSeriesToString(t)}
                        renderItem={(item: SeriesListItemApiModel): JSX.Element => <SeriesItem {...item} />}
                        getApiParams={(): Record<string, unknown> => {
                            return {
                                branchId: branchId,
                                showClosed: showClosedSeries,
                                startDateFilter: startDate,
                                endDateFilter: endDate,
                                dateToFilter: dateToFilter,
                            };
                        }}
                    />
                </Stack>
            )}
        </Stack>
    );
}
