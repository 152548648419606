import React from 'react';
import { BranchFilterPicker } from './BranchFilterPicker';
import { CourseProgramFilterPicker } from './CourseProgramFilterPicker';
import { FilterPickerProps } from './filterPickerProps';
import { ReportFilterEntityType } from './reportFilterEntityType';
import { SeriesFilterPicker } from './SeriesFilterPicker';
import { StudentProgramAttendanceFilterPicker } from './StudentProgramAttendanceFilterPicker';
import { StudentSeriesAttendanceFilterPicker } from './StudentSeriesAttendanceFilterPicker';

interface ReportFilterEntityPickerParameters extends FilterPickerProps {
    entityType: string | undefined;
}

export function ReportFilterEntityPicker(props: ReportFilterEntityPickerParameters): JSX.Element {
    const entityTypeToReportFilterMap: Record<ReportFilterEntityType, () => JSX.Element> = {
        [ReportFilterEntityType.StudentProgramAttendance]: () => <StudentProgramAttendanceFilterPicker {...props} />,
        [ReportFilterEntityType.StudentSeriesAttendance]: () => <StudentSeriesAttendanceFilterPicker {...props} />,
        [ReportFilterEntityType.Branch]: () => <BranchFilterPicker {...props} />,
        [ReportFilterEntityType.Series]: () => <SeriesFilterPicker {...props} />,
        [ReportFilterEntityType.CourseProgram]: () => <CourseProgramFilterPicker {...props} />,
    };

    const reportFilterFunc = entityTypeToReportFilterMap[props.entityType as ReportFilterEntityType];
    return reportFilterFunc?.() ?? <h1>N/A</h1>;
}
