import { BaseApiModel, Amount } from '../../common/baseApiModel';
import { CourseType } from '../../common/courseType';
import { CourseProgramApiModel } from '../courseProgram/courseProgramApiModel';

export enum PaymentForm {
    Individual = 0,
    Employer = 1,
    Insurance = 2,
    OtherForms = 3,
}

export interface StudentProgramAttendanceApiModel extends BaseApiModel {
    branchId: string;
    programId: string;
    paymentForm: PaymentForm;
    price: Amount;
    contractNumber: string;
    contractDate: Date;
    isInstallmentable: boolean;
    numberOfInstallments: number;
    firstInstallmentDate: Date;
    dayOfInstallment: number;
    notes: string;
    certificateNumber: string;
    certificateSeries: string;
    certificateSeriesNumber: string;
    provider: string;
    providerCity: string;
    startCoursesDate: Date | null;
    endCoursesDate: Date | null;
    graduationDate: Date | null;
}

export namespace StudentProgramAttendanceApiModelRelations {
    export interface CourseProgram<Relations = unknown> {
        program: CourseProgramApiModel & Relations;
    }
}

export interface StudentProgramAttendanceListItemApiModel extends BaseApiModel {
    firstName?: string;
    lastName?: string;
    programName?: string;
    contractNumber?: string;
    courseProgramType?: CourseType;
}
