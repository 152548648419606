import { Dictionary } from 'lodash';
import React from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputField } from '../../componentsUx/input/InputField';
import { AddressApiModel } from './addressApiModel';
import { CityApiModel } from './city/cityApiModel';
import { CountryApiModel } from './country/countryApiModel';
import { CountyApiModel } from './county/countyApiModel';
import { SupportedInputType } from '../../componentsUx/input/InputFieldTypes';

interface AddressFormProperties {
    control: Control;
    errors: Dictionary<any>;
    name: string;
    getValues: () => Partial<{ address: AddressApiModel }>;
}

export function AddressForm(inputProps: AddressFormProperties): JSX.Element {
    const { t } = useTranslation();
    return (
        <div>
            <InputField
                control={inputProps.control}
                errors={inputProps.errors}
                name={`${inputProps.name}.country`}
                defaultValue={'România'}
                internalProps={{
                    label: t('ADDRESS_CARD_FIELD_COUNTRY'),
                    apiEndpoint: 'Country',
                    allowFreeText: true,
                    displayItem: (t: CountryApiModel): string => t?.name,
                }}
            />

            <InputField
                control={inputProps.control}
                errors={inputProps.errors}
                name={`${inputProps.name}.county`}
                type={SupportedInputType.Picker}
                internalProps={{
                    label: t('ADDRESS_CARD_FIELD_COUNTY'),
                    apiEndpoint: 'County',
                    allowFreeText: true,
                    displayItem: (t: CountyApiModel): string => t?.name,
                }}
            />

            <InputField
                control={inputProps.control}
                errors={inputProps.errors}
                name={`${inputProps.name}.city`}
                type={SupportedInputType.Picker}
                internalProps={{
                    label: t('ADDRESS_CARD_FIELD_CITY'),
                    apiEndpoint: 'City',
                    allowFreeText: true,
                    displayItem: (t: CityApiModel): string => t?.name,
                    getApiParams: (): Record<string, unknown> => {
                        return {
                            county: inputProps.getValues().address?.county,
                        };
                    },
                }}
            />

            <InputField
                control={inputProps.control}
                errors={inputProps.errors}
                name={`${inputProps.name}.zipCode`}
                internalProps={{
                    label: t('ADDRESS_CARD_FIELD_ZIP_CODE'),
                }}
            />

            <InputField
                control={inputProps.control}
                errors={inputProps.errors}
                name={`${inputProps.name}.street`}
                internalProps={{
                    label: t('ADDRESS_CARD_FIELD_STREET'),
                }}
            />

            <InputField
                control={inputProps.control}
                errors={inputProps.errors}
                name={`${inputProps.name}.number`}
                internalProps={{
                    label: t('ADDRESS_CARD_FIELD_NUMBER'),
                }}
            />

            <InputField
                control={inputProps.control}
                errors={inputProps.errors}
                name={`${inputProps.name}.block`}
                internalProps={{
                    label: t('ADDRESS_CARD_FIELD_BLOCK'),
                }}
            />

            <InputField
                control={inputProps.control}
                errors={inputProps.errors}
                name={`${inputProps.name}.apartment`}
                internalProps={{
                    label: t('ADDRESS_CARD_FIELD_APARTMENT'),
                }}
            />

            <InputField
                control={inputProps.control}
                errors={inputProps.errors}
                name={`${inputProps.name}.level`}
                internalProps={{
                    label: t('ADDRESS_CARD_FIELD_LEVEL'),
                }}
            />

            <InputField
                control={inputProps.control}
                errors={inputProps.errors}
                name={`${inputProps.name}.staircase`}
                internalProps={{
                    label: t('ADDRESS_CARD_FIELD_STAIRCASE'),
                }}
            />
        </div>
    );
}
