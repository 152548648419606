import React from 'react';
import { UseFormMethods } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';
import { InputField } from '../../componentsUx/input/InputField';
import { SupportedInputType } from '../../componentsUx/input/InputFieldTypes';
import { InputBranchPicker } from '../../componentsUx/inputBranchPicker/InputBranchPicker';
import { AddressForm } from '../../features/address/AddressForm';
import { SexType } from '../../models/enums/sexType';
import { StudiesType } from '../../models/enums/studiesType';
import { FormValues } from './form.util';
import { CountyApiModel } from '../../features/address/county/countyApiModel';
import { CityApiModel } from '../../features/address/city/cityApiModel';

export interface Props {
    form: UseFormMethods<FormValues>;
}

export function StudentEditorFields({ form }: Props): JSX.Element {
    const { control, formState, getValues } = form;
    const { t } = useTranslation();

    return (
        <>
            <InputBranchPicker control={control} formState={formState} name="branch" label={t('STUDENT_FIELD_BRANCH_ID')} />

            <InputField
                control={control}
                errors={formState.errors}
                name="lastName"
                required={true}
                internalProps={{
                    label: t('STUDENT_FIELD_LAST_NAME'),
                }}
            />
            <InputField
                control={control}
                errors={formState.errors}
                name="firstName"
                required={true}
                internalProps={{
                    label: t('STUDENT_FIELD_FIRST_NAME'),
                }}
            />

            <InputField
                control={control}
                errors={formState.errors}
                name="socialSecurityNumber"
                required={true}
                internalProps={{
                    label: t('STUDENT_FIELD_SOCIAL_SECURITY_NUMBER'),
                }}
            />
            <InputField
                control={control}
                errors={formState.errors}
                name="registrationNumber"
                required={true}
                internalProps={{
                    label: t('STUDENT_FIELD_REGISTRATION_NUMBER'),
                }}
            />

            <InputField
                control={control}
                errors={formState.errors}
                name="birthDate"
                type={SupportedInputType.DatePicker}
                required={true}
                internalProps={{
                    label: t('STUDENT_FIELD_BIRTH_DATE'),
                }}
            />

            <InputField
                control={control}
                errors={formState.errors}
                name="bornCounty"
                type={SupportedInputType.Picker}
                internalProps={{
                    label: t('STUDENT_FIELD_BORN_COUNTY'),
                    apiEndpoint: 'County',
                    allowFreeText: true,
                    displayItem: (t: CountyApiModel): string => t?.name,
                }}
            />

            <InputField
                control={control}
                errors={formState.errors}
                name="bornCity"
                type={SupportedInputType.Picker}
                internalProps={{
                    label: t('STUDENT_FIELD_BORN_CITY'),
                    apiEndpoint: 'City',
                    allowFreeText: true,
                    displayItem: (t: CityApiModel): string => t?.name,
                    getApiParams: (): Record<string, unknown> => {
                        return {
                            county: getValues().bornCounty,
                        };
                    },
                }}
            />

            <InputField
                control={control}
                errors={formState.errors}
                name="seriesIdCard"
                required={true}
                internalProps={{
                    label: t('STUDENT_FIELD_SERIES_CARD_ID'),
                }}
            />

            <InputField
                control={control}
                errors={formState.errors}
                name="numberIdCard"
                required={true}
                internalProps={{
                    label: t('STUDENT_FIELD_NUMBER_CARD_ID'),
                }}
            />

            <InputField
                control={control}
                errors={formState.errors}
                name="sexType"
                type={SupportedInputType.DropDown}
                required={true}
                internalProps={{
                    label: t('STUDENT_FIELD_SEX_TYPE'),
                    dropDownEnumType: SexType,
                    isNumericEnum: true,
                }}
            />

            <InputField
                control={control}
                errors={formState.errors}
                name="fatherName"
                internalProps={{
                    label: t('STUDENT_FIELD_FATHER_NAME'),
                }}
            />
            <InputField
                control={control}
                errors={formState.errors}
                name="motherName"
                internalProps={{
                    label: t('STUDENT_FIELD_MOTHER_NAME'),
                }}
            />
            <InputField
                control={control}
                errors={formState.errors}
                name="parentInitials"
                required={true}
                internalProps={{
                    label: t('STUDENT_FIELD_PARENT_INITIALS'),
                }}
            />

            <InputField
                control={control}
                errors={formState.errors}
                name="email"
                required={true}
                internalProps={{
                    label: t('STUDENT_FIELD_EMAIL'),
                }}
            />

            <InputField
                control={control}
                errors={formState.errors}
                name="phoneNumber"
                required={true}
                internalProps={{
                    label: t('STUDENT_FIELD_PHONE_NUMBER'),
                }}
            />

            <AddressForm control={control} errors={formState.errors} name="address" getValues={getValues} />

            <InputField
                control={control}
                errors={formState.errors}
                name="company"
                required={false}
                internalProps={{
                    label: t('STUDENT_FIELD_COMPANY'),
                }}
            />

            <InputField
                control={control}
                errors={formState.errors}
                name="studiesType"
                type={SupportedInputType.DropDown}
                required={true}
                internalProps={{
                    label: t('STUDENT_FIELD_STUDIES_TYPE'),
                    dropDownEnumType: StudiesType,
                    isNumericEnum: true,
                }}
            />
        </>
    );
}
