import { PrimaryButton } from '@fluentui/react';
import React from 'react';
import styled from 'styled-components';
const ButtonSpacing = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export function SubmitButton(props: { label: string }): JSX.Element {
    return (
        <ButtonSpacing>
            <PrimaryButton type="submit"> {props.label}</PrimaryButton>
        </ButtonSpacing>
    );
}
