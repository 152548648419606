import React, { ReactNode } from 'react';
import { Route, Redirect } from 'react-router';
import { useSession } from '../../state/SessionState';
import { PATHS } from './commands';

export function NoMatch(): JSX.Element {
    const [session] = useSession();

    return (
        <Route
            render={({ location }): ReactNode =>
                !session?.isExpired ? (
                    session?.isBranchAdmin() ? (
                        <Redirect
                            to={{
                                pathname: `/${PATHS.home}/${PATHS.dashboard}`,
                                state: { from: location },
                            }}
                        />
                    ) : (
                        <Redirect
                            to={{
                                pathname: `${PATHS.home}/${PATHS.company}`,
                            }}
                        />
                    )
                ) : (
                    <Redirect
                        to={{
                            pathname: `/${PATHS.auth}/${PATHS.login}`,
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}
