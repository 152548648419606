import React, { useEffect } from 'react';
import { UseFormMethods } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { device } from '../../componentsUx/cssUtils/breakpoints';
import { InputField } from '../../componentsUx/input/InputField';
import { SupportedInputType } from '../../componentsUx/input/InputFieldTypes';
import { PickerFieldValue } from '../../features/common/baseApiModel';
import { SeriesListItemApiModel } from '../../features/home/serie/seriesApiModel';
import { PaymentForm } from '../../features/home/studentSeriesAttendance/studentProgramAttendanceApiModel';
import { StudentListItemApiModel } from '../../models/studentApiModel';
import { convertSeriesToString, convertToString } from '../../utils/convertToString';
import { isInitialized } from '../../utils/isInitialized';
import { setFormValues } from '../../utils/useFormHook';
import { FormValues } from './form.util';
import { dateParser } from '../../utils/dateParser';

const Spacer = styled.div`
    min-width: 15px;
`;
const ItemWrapper = styled.div`
    flex: 1;
`;
const HorizontalFields = styled.div`
    display: flex;

    @media ${device.featurePhone} {
        flex-direction: column;
    }

    @media ${device.tabletLandscape} {
        flex-direction: row;
    }
`;

const SeriesItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px 0;
    margin-bottom: 4px;

    span {
        margin-left: 4px;
    }

    div {
        display: flex;
        flex-direction: row;
    }
`;

const SeriesItemLabel = styled.div`
    font-weight: bold;
`;

export interface FormAutocompletes {
    student: { disabled: boolean; apiStaticParams: Partial<{ branchId: string }>; hidden?: boolean };
    series: { disabled: boolean; apiStaticParams: Partial<{ branchId: string }> };
}

export interface Props {
    objectId?: string | null;
    disabled?: boolean;
    form: UseFormMethods<FormValues>;
    formAutocompletes: FormAutocompletes;
}

export function SeriesItem(series: SeriesListItemApiModel): JSX.Element {
    const { t } = useTranslation();
    return (
        <SeriesItemContainer>
            <div>
                <SeriesItemLabel>{t('SERIE_CARD_FIELD_PROGRAM_NAME')}: </SeriesItemLabel>
                <span>{series.specializationName}</span>
            </div>
            <div>
                <SeriesItemLabel>{t('SERIES_LIST_CARD_FIELD_NUMBER')}: </SeriesItemLabel>
                <span>{series.seriesNumber}</span>
            </div>
            <div>
                <SeriesItemLabel>{t('STUDENT_SERIES_ATTENDANCE_FIELD_SERIES_PERIOD')}:</SeriesItemLabel>
                <span>
                    {dateParser(series.theoreticalPeriodStartDate)} - {dateParser(series.practicalPeriodEndDate)}
                </span>
            </div>
        </SeriesItemContainer>
    );
}

export function StudentSeriesAttendanceEditorFields({ objectId, form, formAutocompletes, disabled }: Props): JSX.Element {
    const { t } = useTranslation();
    const { control, formState, watch, setValue } = form;

    const isInstallmentable = watch('isInstallmentable', false);
    const selectedSeries = watch('series') as PickerFieldValue<SeriesListItemApiModel>;

    useEffect(() => {
        if (!selectedSeries || !Object.values(selectedSeries).length || isInitialized(objectId)) {
            return;
        }
        setFormValues(
            {
                price: selectedSeries.programBasePrice,
                startCoursesDate: selectedSeries.theoreticalPeriodStartDate,
                endCoursesDate: selectedSeries.practicalPeriodEndDate,
                graduationDate: selectedSeries.examDate,
            },
            setValue,
        );
    }, [selectedSeries, objectId, setValue]);

    return (
        <>
            {!formAutocompletes.student.hidden && (
                <InputField
                    control={control}
                    errors={formState.errors}
                    name="student"
                    type={SupportedInputType.Picker}
                    required={true}
                    internalProps={{
                        label: t('STUDENT_SERIES_ATTENDANCE_FIELD_STUDENT'),
                        disabled: formAutocompletes.student.disabled || disabled,
                        apiEndpoint: 'Students',
                        allowFreeText: false,
                        getApiParams: (): unknown => formAutocompletes.student.apiStaticParams,
                        displayItem: (t: StudentListItemApiModel): string => `${convertToString(t?.firstName)} ${convertToString(t?.lastName)}`,
                    }}
                />
            )}

            <InputField
                control={control}
                errors={formState.errors}
                name="series"
                type={SupportedInputType.Picker}
                required={true}
                internalProps={{
                    label: t('STUDENT_SERIES_ATTENDANCE_FIELD_SERIES'),
                    disabled: formAutocompletes.series.disabled || disabled,
                    apiEndpoint: 'Series',
                    allowFreeText: false,
                    getApiParams: (): unknown => formAutocompletes.series.apiStaticParams,
                    displayItem: (t: SeriesListItemApiModel): string => convertSeriesToString(t),
                    renderItem: (item: SeriesListItemApiModel): JSX.Element => <SeriesItem {...item} />,
                }}
            />

            <HorizontalFields>
                <InputField
                    control={control}
                    errors={formState.errors}
                    type={SupportedInputType.Toggle}
                    name="abandoned"
                    internalProps={{
                        disabled,
                        label: t('STUDENT_SERIES_ATTENDANCE_FIELD_ABANDONED'),
                    }}
                />
                <Spacer />
                <InputField
                    control={control}
                    errors={formState.errors}
                    type={SupportedInputType.Toggle}
                    name="wasAbsent"
                    internalProps={{
                        disabled,
                        label: t('STUDENT_SERIES_ATTENDANCE_FIELD_WAS_ABSENT'),
                    }}
                />
            </HorizontalFields>

            <InputField
                control={control}
                errors={formState.errors}
                type={SupportedInputType.TextArea}
                name="comments"
                internalProps={{
                    disabled,
                    label: t('STUDENT_SERIES_ATTENDANCE_FIELD_COMMENTS'),
                    multiline: true,
                    rows: 3,
                }}
            />

            <InputField
                control={control}
                errors={formState.errors}
                type={SupportedInputType.DropDown}
                name="paymentForm"
                required={true}
                internalProps={{
                    disabled,
                    label: t('STUDENT_SERIES_ATTENDANCE_FIELD_PAYMENT_FORM'),
                    dropDownEnumType: PaymentForm,
                    isNumericEnum: true,
                }}
            />
            <InputField
                control={control}
                errors={formState.errors}
                name="price"
                type={SupportedInputType.Price}
                internalProps={{
                    disabled,
                    label: t('STUDENT_SERIES_ATTENDANCE_FIELD_PRICE'),
                    labelSecond: t('STUDENT_SERIES_ATTENDANCE_FIELD_CURRENCY'),
                }}
            />

            <InputField
                control={control}
                errors={formState.errors}
                name="contractNumber"
                type={SupportedInputType.Text}
                internalProps={{
                    disabled,
                    label: t('STUDENT_SERIES_ATTENDANCE_FIELD_CONTRACT_NUMBER'),
                }}
            />
            <InputField
                control={control}
                errors={formState.errors}
                name="contractDate"
                type={SupportedInputType.DatePicker}
                internalProps={{
                    disabled,
                    label: t('STUDENT_SERIES_ATTENDANCE_FIELD_CONTRACT_DATE'),
                }}
            />
            <InputField
                control={control}
                errors={formState.errors}
                name="isInstallmentable"
                type={SupportedInputType.Toggle}
                internalProps={{
                    disabled,
                    label: t('STUDENT_SERIES_ATTENDANCE_FIELD_IS_INSTALLMENTABLE'),
                }}
            />

            {isInstallmentable && (
                <>
                    <InputField
                        control={control}
                        errors={formState.errors}
                        name="installmentableDependent.numberOfInstallments"
                        type={SupportedInputType.Number}
                        required={true}
                        internalProps={{
                            disabled,
                            label: t('STUDENT_SERIES_ATTENDANCE_FIELD_NUMBER_OF_INSTALLMENTS'),
                        }}
                    />
                    <InputField
                        control={control}
                        errors={formState.errors}
                        name="installmentableDependent.firstInstallmentDate"
                        required={true}
                        type={SupportedInputType.DatePicker}
                        internalProps={{
                            disabled,
                            label: t('STUDENT_SERIES_ATTENDANCE_FIELD_FIRST_INSTALLMENT_DATE'),
                        }}
                    />
                    <InputField
                        control={control}
                        errors={formState.errors}
                        name="installmentableDependent.dayOfInstallment"
                        required={true}
                        type={SupportedInputType.Number}
                        internalProps={{
                            disabled,
                            label: t('STUDENT_SERIES_ATTENDANCE_FIELD_DAY_OF_INSTALLMENT'),
                        }}
                    />
                </>
            )}

            <InputField
                control={control}
                errors={formState.errors}
                type={SupportedInputType.TextArea}
                name="notes"
                internalProps={{
                    disabled,
                    label: t('STUDENT_SERIES_ATTENDANCE_FIELD_NOTES'),
                    multiline: true,
                    rows: 3,
                }}
            />

            <InputField
                control={control}
                errors={formState.errors}
                type={SupportedInputType.Text}
                name="certificateNumber"
                internalProps={{
                    disabled,
                    label: t('STUDENT_SERIES_ATTENDANCE_FIELD_CERTIFICATE_NUMBER'),
                }}
            />

            <HorizontalFields>
                <ItemWrapper>
                    <InputField
                        control={control}
                        errors={formState.errors}
                        type={SupportedInputType.Text}
                        name="certificateSeries"
                        internalProps={{
                            disabled,
                            label: t('STUDENT_SERIES_ATTENDANCE_FIELD_CERTIFICATE_SERIES'),
                        }}
                    />
                </ItemWrapper>
                <Spacer />
                <ItemWrapper>
                    <InputField
                        control={control}
                        errors={formState.errors}
                        type={SupportedInputType.Text}
                        name="certificateSeriesNumber"
                        internalProps={{
                            disabled,
                            label: t('STUDENT_SERIES_ATTENDANCE_FIELD_CERTIFICATE_SERIES_NUMBER'),
                        }}
                    />
                </ItemWrapper>
            </HorizontalFields>
            <HorizontalFields>
                <ItemWrapper>
                    <InputField
                        control={control}
                        errors={formState.errors}
                        name="startCoursesDate"
                        type={SupportedInputType.DatePicker}
                        internalProps={{
                            disabled,
                            label: t('STUDENT_SERIES_ATTENDANCE_FIELD_START_COURSES_DATE'),
                        }}
                    />
                </ItemWrapper>
                <Spacer />
                <ItemWrapper>
                    <InputField
                        control={control}
                        errors={formState.errors}
                        name="endCoursesDate"
                        type={SupportedInputType.DatePicker}
                        internalProps={{
                            disabled,
                            label: t('STUDENT_SERIES_ATTENDANCE_FIELD_END_COURSES_DATE'),
                        }}
                    />
                </ItemWrapper>
            </HorizontalFields>
            <InputField
                control={control}
                errors={formState.errors}
                name="graduationDate"
                type={SupportedInputType.DatePicker}
                internalProps={{
                    disabled,
                    label: t('STUDENT_SERIES_ATTENDANCE_FIELD_GRADUATION_DATE'),
                }}
            />
        </>
    );
}
