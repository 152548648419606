import React from 'react';
import { useHistory } from 'react-router-dom';
import { APP_PATHS_FROM_ROOT } from '../../routing/commands';
import { ReportListItemApiModel } from './reportApiModel';
import { useTranslation } from 'react-i18next';
import { ReportsListCard } from './ReportsListCard';
import { Breadcrumb } from '../../../componentsUx/breadcrumb/Breadcrumb';

export function ReportsList(): JSX.Element {
    const history = useHistory();
    const { t } = useTranslation();

    const onReportClicked = (model: ReportListItemApiModel): void => {
        history.push(APP_PATHS_FROM_ROOT.reportPage(model.id));
    };

    const breadcrumbItems = [
        {
            text: t('REPORT_LIST_TITLE'),
            key: 'REPORT_LIST_TITLE',
            isCurrentItem: true,
        },
    ];

    return (
        <>
            <Breadcrumb items={breadcrumbItems} />
            <ReportsListCard onReportClicked={onReportClicked} />
        </>
    );
}
