import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { ReactProps, ThemeProps } from '../../utils/types';

const ItemContainer = styled.div`
    width: 100%;
    background-color: ${(props: { $isSelected: boolean } & ThemeProps): string => (props.$isSelected ? 'rgb(243, 242, 241)' : 'transparent')};
    display: flex;
    padding: 0 8px;
    min-height: 35px;
    align-items: center;

    :hover {
        cursor: pointer;
    }
`;

type ListItemProps = {
    isSelected: boolean;
    scrollToItem: boolean;
    onMouseDown: () => void;
    onMouseEnter: () => void;
} & ReactProps;

export const InputPickerListItem = (props: ListItemProps): JSX.Element => {
    const elementRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (props.scrollToItem) {
            elementRef?.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            });
        }
    }, [props.scrollToItem, elementRef]);

    return (
        <ItemContainer $isSelected={props.isSelected} onMouseDown={props.onMouseDown} ref={elementRef} onMouseEnter={props.onMouseEnter}>
            {props.children}
        </ItemContainer>
    );
};
