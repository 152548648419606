import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { StudentsListCard } from '../../../../componentsBusiness/studentListCard';
import { Breadcrumb } from '../../../../componentsUx/breadcrumb/Breadcrumb';
import { StudentListItemApiModel } from '../../../../models/studentApiModel';
import { allStudentsListQuery } from '../../../../services/dataAccess';
import { APP_PATHS_FROM_ROOT } from '../../../routing/commands';

export function Students(): JSX.Element {
    const history = useHistory();
    const { t } = useTranslation();

    function onAdd(): void {
        history.push(APP_PATHS_FROM_ROOT.newStudent());
    }

    function onItemClicked(model: StudentListItemApiModel): void {
        history.push(APP_PATHS_FROM_ROOT.student(model.id));
    }

    const breadcrumbItems = [
        {
            text: t('STUDENTS_LIST_CARD_TITLE'),
            key: 'STUDENTS_LIST_CARD_TITLE',
            isCurrentItem: true,
        },
    ];

    return (
        <>
            <Breadcrumb items={breadcrumbItems} />
            <StudentsListCard service={allStudentsListQuery} staticServiceParams={{}} allowAdd={true} onAdd={onAdd} onItemClicked={onItemClicked} />
        </>
    );
}
