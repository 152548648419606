import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Amount } from '../../features/common/baseApiModel';
import { PickerInMemoryApi } from '../../features/common/pickerList.service';
import { convertToString } from '../../utils/convertToString';
import { ReactProps } from '../../utils/types';
import { ControllerInputProps } from '../input/InputFieldTypes';
import { TextFieldStyled } from '../input/TextFieldStyled';
import { InputPicker } from '../inputPicker/InputPicker';

export interface InputPriceProps extends ReactProps, ControllerInputProps {
    label: string;
    labelSecond: string;
    value: Amount;
}

const FieldContainer = styled.div`
    flex: 1;
    min-width: 0;
`;

const HorizontalLane = styled.div`
    display: flex;
    width: 100%;

    ${FieldContainer}:not(:last-child) {
        margin-right: 15px;
    }
`;

export function InputPrice(props: InputPriceProps): JSX.Element {
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        setInputValue(convertToString(props.value?.value));
    }, [props.value]);

    const onValueChanged = (event: unknown, value?: string): void => {
        props.onChange({
            ...props.value,
            value: Number(value),
        });
    };

    const onCurrencyChanged = (currency: string): void => {
        props.onChange({
            ...props.value,
            currency,
        });
    };

    return (
        <HorizontalLane>
            <FieldContainer>
                <TextFieldStyled
                    type="number"
                    label={props.label}
                    autoComplete="off"
                    onFocus={props.onFocus}
                    value={inputValue}
                    disabled={props.disabled}
                    onBlur={props.onBlur}
                    onChange={onValueChanged}
                    errorMessage={props.errorMessage}
                />
            </FieldContainer>
            <FieldContainer>
                <InputPicker
                    apiEndpoint={PickerInMemoryApi.CurrencyList}
                    allowFreeText={false}
                    label={props.labelSecond}
                    onFocus={props.onFocus}
                    value={props.value?.currency}
                    disabled={props.disabled}
                    onBlur={props.onBlur}
                    onChange={onCurrencyChanged}
                    renderItem={(item: string): JSX.Element => <div>{item}</div>}
                />
            </FieldContainer>
        </HorizontalLane>
    );
}
