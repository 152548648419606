import { IColumn } from '@fluentui/react';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Card } from '../../../componentsUx/card/Card';
import { CardTitle } from '../../../componentsUx/card/CardTitle';
import { PaginatedList } from '../../../componentsUx/paginatedList/PaginatedList';
import { useListInput } from '../../../componentsUx/paginatedList/useListInput';
import { courseCompetencyService } from '../../../services/dataAccess';
import { createDefaultPaginatedOutput, PaginatedApiInput } from '../../common/paginatedApiOutput';
import { APP_PATHS_FROM_ROOT } from '../../routing/commands';
import { CourseCompetencyListApiModel, CourseCompetencyListItemApiModel, CourseCompetencyType } from './courseCompetencyApiModel';

export function CourseCompetenciesListCard(props: { branchId: string; courseProgramId: string }): JSX.Element {
    const [t] = useTranslation();
    const history = useHistory();
    const [listInput, setListInput] = useListInput({
        orderByProperty: 'description',
    });
    const [courseCompetenciesList, setCourseCompetenciesList] = useState<CourseCompetencyListApiModel>(createDefaultPaginatedOutput());

    const readCoursePrograms = async (): Promise<void> => {
        const courseCompetenciesList = await courseCompetencyService.readCourseCompetencies({ ...listInput, courseProgramId: props.courseProgramId });
        setCourseCompetenciesList(courseCompetenciesList);
    };

    useEffect(() => {
        readCoursePrograms();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listInput]);

    const onItemClicked = (model: CourseCompetencyListItemApiModel): void => {
        history.push(APP_PATHS_FROM_ROOT.courseCompetencyPage(props.branchId, props.courseProgramId, model.id));
    };

    const onAdd = (): void => {
        history.push(APP_PATHS_FROM_ROOT.newCourseCompetencyPage(props.branchId, props.courseProgramId));
    };

    const commonColumnProps = {
        minWidth: 100,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: true,
    };

    const columns: IColumn[] = [
        {
            key: 'columnDescription',
            name: t('COURSE_COMPETENCY_LIST_COLUMN_DESCRIPTION'),
            fieldName: 'description',
            ...commonColumnProps,
        },
        {
            key: 'columnCourseComptencyType',
            name: t('COURSE_COMPETENCY_LIST_COLUMN_TYPE'),
            fieldName: 'type',
            onRender: (item: any): JSX.Element => {
                return item.type === CourseCompetencyType.Theoretical ? t('COURSE_COMPETENCY_THEORETICAL') : t('COURSE_COMPETENCY_PRACTICAL');
            },
            ...commonColumnProps,
        },
    ];

    return (
        <Card>
            <CardTitle>{t('COURSE_COMPETENCY_LIST_TITLE')}</CardTitle>
            <PaginatedList
                entityType="CourseCompetency"
                onItemClicked={onItemClicked}
                searchTextLabel={t('SEARCH_BY_DESCRIPTION')}
                model={courseCompetenciesList}
                pagination={listInput}
                onReadPage={(inputs: Required<PaginatedApiInput>): void => setListInput(inputs)}
                columns={columns}
                allowAdd={true}
                onAdd={onAdd}
            />
        </Card>
    );
}
