import axios, { AxiosInstance } from 'axios';
import { AuthService } from '../../features/auth/auth.service';
import { CurrencyListService } from '../../features/common/currencyList.service';
import { PickerListService, PickerInMemoryApi } from '../../features/common/pickerList.service';
import { BranchService } from '../../features/home/branch/branch.service';
import { DashboardSeriesListCardQuery } from '../../features/home/dashboard/dashboardSeriesListCard.query';
import { SeriesService } from '../../features/home/serie/seriesService';
import { CompanyService } from '../../features/home/company/company.service';
import { CourseProgramService } from '../../features/home/courseProgram/courseProgram.service';
import { StudentsService } from '../../features/home/students/students.service';
import { StudentSeriesAttendanceListCardQuery } from '../../features/home/students/studentSeriesAttendanceListCardQuery';
import { AllStudentsListQuery } from '../../features/home/students/studentsListCard.query';
import { StudentSeriesAttendanceService } from '../../features/home/studentSeriesAttendance/studentSeriesAttendance.service';
import { AttachmentsService } from '../attachments.service';
import { CourseCompetencyService } from '../../features/home/courseCompetency/courseCompetency.service';
import { ReportService } from '../../features/home/report/report.service';
import { SeriesStudentListCardQuery } from '../../features/home/serie/seriesStudentListCardQuery.query';
import { DeletionService } from '../../features/common/deleteData.service';
import { BranchStaffMemberService } from '../../features/home/branchStaffMember/branchStaffMember.service';
import { AuthorizationCommissionService } from '../../features/home/authorizationCommission/authorizationCommission.service';

export const httpClient: AxiosInstance = axios.create();
export const attachmentsService = new AttachmentsService(httpClient);
export const authService = new AuthService(httpClient);
export const companyService = new CompanyService(httpClient);
export const studentsService = new StudentsService(httpClient);
export const allStudentsListQuery = new AllStudentsListQuery(httpClient);
export const studentSeriesAttendanceService = new StudentSeriesAttendanceService(httpClient);
export const branchService = new BranchService(httpClient);
export const branchStaffMemberService = new BranchStaffMemberService(httpClient);
export const seriesService = new SeriesService(httpClient);
export const seriesStudentListCardQuery = new SeriesStudentListCardQuery(httpClient);
export const dashboardSeriesListCardQuery = new DashboardSeriesListCardQuery(httpClient);
export const studentSeriesListCardQuery = new StudentSeriesAttendanceListCardQuery(httpClient);
export const currencyListService = new CurrencyListService();
export const pickerListService = new PickerListService(httpClient, {
    [PickerInMemoryApi.CurrencyList]: currencyListService,
});
export const courseProgramService = new CourseProgramService(httpClient);
export const courseCompetencyService = new CourseCompetencyService(httpClient);
export const reportService = new ReportService(httpClient);
export const deletionService = new DeletionService(httpClient);
export const authorizationCommissionService = new AuthorizationCommissionService(httpClient);
