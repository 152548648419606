import { generateUuid } from '../../utils/generateUuid';
import { omit } from 'lodash';

interface LoaderInternalState {
    loadersCount: number;
    isBlockedCount: number;
    loaders: { [id: string]: { count: number; blocks: boolean } };
}

export function createInitialLoader(): LoaderInternalState {
    return {
        isBlockedCount: 0,
        loadersCount: 0,
        loaders: {},
    };
}

type SetLoaderStateFn = (prevState: LoaderInternalState) => LoaderInternalState;

export function markLoading(params: { id: string; shouldBlockScreen?: boolean; setLoaderState: (setterFn: SetLoaderStateFn) => void }): void {
    params.setLoaderState((prevState) => {
        const loaderState = !prevState.loaders[params.id] ? { count: 0, blocks: false } : { ...prevState.loaders[params.id] };

        loaderState.count++;
        const loadersCount = prevState.loadersCount + 1;
        let isBlockedCount = prevState.isBlockedCount;
        if (!loaderState.blocks && params.shouldBlockScreen) {
            loaderState.blocks = true;
            isBlockedCount++;
        }
        return {
            loadersCount,
            isBlockedCount,
            loaders: {
                ...prevState.loaders,
                [params.id]: loaderState,
            },
        };
    });
}

export function markNotLoading(params: { id: string; unblockNow?: boolean; setLoaderState: (setterFn: SetLoaderStateFn) => void }): void {
    params.setLoaderState((prevState) => {
        if (!prevState.loaders[params.id]) {
            return prevState;
        }
        const loaderState = { ...prevState.loaders[params.id] };
        loaderState.count--;
        const loadersCount = prevState.loadersCount - 1;
        let isBlockedCount = prevState.isBlockedCount;
        if (loaderState.blocks && loaderState.count <= 0) {
            loaderState.blocks = false;
            isBlockedCount--;
        } else if (params.unblockNow) {
            loaderState.blocks = false;
            isBlockedCount--;
        }

        if (loaderState.count === 0) {
            return {
                loadersCount,
                isBlockedCount,
                loaders: {
                    ...prevState.loaders,
                },
            };
        }

        return {
            loadersCount,
            isBlockedCount,
            loaders: {
                ...prevState.loaders,
                [params.id]: loaderState,
            },
        };
    });
}

export function detachLoader(params: { id: string; setLoaderState: (setterFn: SetLoaderStateFn) => void }): void {
    params.setLoaderState((prevState) => {
        if (!prevState.loaders[params.id]) {
            return prevState;
        }
        const loaderState = { ...prevState.loaders[params.id] };
        const loadersCount = prevState.loadersCount - loaderState.count;
        const isBlockedCount = loaderState.blocks ? prevState.isBlockedCount - 1 : prevState.isBlockedCount;
        return {
            loadersCount,
            isBlockedCount,
            loaders: omit(prevState.loaders, [params.id]),
        };
    });
}

/**
 * Replace a promise with a promise which handles the loading/loaded states
 */
export function track<T>(params: { source: Promise<T>; trackId?: string; setLoaderState: (setterFn: SetLoaderStateFn) => void }): Promise<T> {
    return new Promise(async (resolve, reject) => {
        params.trackId = params.trackId ?? generateUuid();
        markLoading({ id: params.trackId, setLoaderState: params.setLoaderState });
        try {
            markNotLoading({ id: params.trackId, setLoaderState: params.setLoaderState });
            const response = await params.source;
            resolve(response);
        } catch (e) {
            markNotLoading({ id: params.trackId, setLoaderState: params.setLoaderState });
            reject(e);
        }
    });
}
