import * as yup from 'yup';
import { MixedSchema, StringSchema } from 'yup';
import { ErrorCode } from './errors.util';

export type StringInputData = string | '';

export function stringValidator(options?: {
    isRequired?: boolean;
    isEmail?: boolean;
    length?: {
        size: number;
        errorCode: ErrorCode;
    };
}): MixedSchema<StringInputData> {
    let stringSchema = yup.string();

    if (options?.isRequired) {
        stringSchema = stringSchema.required(ErrorCode.Required);
    }

    if (options?.isEmail) {
        stringSchema = stringSchema.email(ErrorCode.Email);
    }

    if (options?.length) {
        // TODO find a way to pass length to the error renderer.
        stringSchema = stringSchema.length(options.length.size, options.length.errorCode);
    }

    return stringSchema as MixedSchema<StringInputData>;
}

export function fatherInitial(): StringSchema<string> {
    return yup.string().test(ErrorCode.FatherInitial, ErrorCode.FatherInitial, (value) => value?.length === 1) as StringSchema<string>;
}
