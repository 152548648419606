import { isInitialized } from './isInitialized';

const DEFAULT_PARAMS = {
    string: [''],
    number: [null, undefined, ''],
    date: [null, undefined, ''],
    boolean: [false],
};

export function hasValue<T>(data: T | null | undefined): data is T {
    if (isInitialized(data)) {
        const dataType = typeof data;
        if (DEFAULT_PARAMS[dataType]) {
            return !DEFAULT_PARAMS[dataType].includes(data);
        }
        return true;
    }
    return false;
}
