import {
    BaseButton,
    Button,
    DefaultButton,
    DetailsList,
    DetailsListLayoutMode,
    DialogType,
    Fabric,
    FontIcon,
    IColumn,
    IconButton,
    mergeStyles,
    SelectionMode,
    Dialog,
    DialogFooter,
    PrimaryButton,
} from '@fluentui/react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseApiModel } from '../../features/common/baseApiModel';
import { CardActions } from '../card/CardActions';
import { PaginatedListProps } from './paginatedListProps';
import styled from 'styled-components';
import { TextFieldStyled } from '../input/TextFieldStyled';
import { device } from '../cssUtils/breakpoints';
import { deletionService } from '../../services/dataAccess';

export const highlightedColumnClassName = 'ms-HighlightedColumn';

const StyledDeleteIconButton = styled(IconButton)`
    fill: grey;
    margin-top: -6px;

    :hover {
        fill: red;
    }
`;

const deleteIconClass = mergeStyles({
    height: 20,
    width: 20,
});

const SearchAndFilterContainer = styled.div`
    display: flex;
    min-width: 0;

    @media ${device.featurePhone} {
        flex-direction: column;
    }

    @media ${device.desktop} {
        flex-direction: row;

        ${TextFieldStyled} {
            margin-right: 16px;
        }
    }

    ${TextFieldStyled} {
        flex: 1;
        min-width: 0;
    }
`;

const StyledFabric = styled(Fabric)`
    .ms-Viewport {
        transform: translate(0px, 0px);
    }
`;

const StyledDetailsList = styled(DetailsList)`
    .ms-DetailsHeader-cellName {
        color: ${(props): string => props.theme.colors.primary};
        font-weight: bold;
    }

    .ms-List-cell {
        cursor: pointer;
    }

    .ms-DetailsRow-cell.${highlightedColumnClassName} {
        color: ${(props): string => props.theme.colors.secondary1};
    }

    .deleteCell:hover {
        background: ${(props): string => props.theme.colors.listDetails};
    }

    .deleteCell,
    .ms-DetailsHeader-cell:last-child {
        position: fixed;
        right: 0px;
        background: white;
    }

    .deleteCell {
        border-bottom: 1px solid ${(props): string => props.theme.colors.listDetails};
        max-height: 43px;
    }
`;
const deleteDialogStyles = { main: { maxWidth: 750 } };

const AddButton = styled(DefaultButton)`
    color: ${(props): string => props.theme.palette.white};
    background: ${(props): string => props.theme.colors.secondary1};
    border: none;
`;

const ItemsCount = styled.div`
    flex: 1;
    font-weight: bold;
`;

const PagesCount = styled.div`
    margin: 0 12px;
    font-weight: bold;
`;

export function PaginatedList<TItemModel extends BaseApiModel>(props: PaginatedListProps<TItemModel>): JSX.Element {
    const [t] = useTranslation();
    const [columns, setColumns] = useState<IColumn[]>([]);
    const [descending, setDescending] = useState<boolean>(props.pagination.isDescending);
    const [searchText, setSearchText] = useState<string>('');
    const [sortedColumnFieldName, setSortedColumnFieldName] = useState<string>(props.pagination.orderByProperty);
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const [selectedItemForDeletion, setSelectedItemForDeletion] = useState<TItemModel | null>(null);

    const dialogContentProps = {
        type: DialogType.normal,
        closeButtonAriaLabel: 'Close',
        title: t('LIST_DIALOG_DELETE_TITLE'),
        subText: t('LIST_DIALOG_DELETE_TEXT'),
    };
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: deleteDialogStyles,
        }),
        [labelId, subTextId],
    );

    const onDeleteIconClicked = (
        entity: TItemModel,
        event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement, MouseEvent>,
    ): void => {
        event.stopPropagation();
        event.preventDefault();
        setSelectedItemForDeletion(entity);
        toggleHideDialog();
    };

    const confirmDeletingSelectedItem = async (): Promise<void> => {
        if (!selectedItemForDeletion) {
            return;
        }
        const entityId = selectedItemForDeletion.id;
        setSelectedItemForDeletion(null);

        await deletionService.delete(props.entityType, entityId);
        toggleHideDialog();

        props.onReadPage({ ...props.pagination });
    };

    useEffect(() => {
        const newColumns = [...props.columns];
        newColumns.forEach((column) => {
            if (column.fieldName === props.pagination.orderByProperty) {
                column.isSortedDescending = descending;
                column.isSorted = true;
            }
        });
        setColumns([
            ...newColumns,
            {
                name: '',
                fieldName: 'deleteAction',
                key: 'deleteAction',
                className: 'deleteCell',
                minWidth: 30,
                maxWidth: 30,
                onRender: (t): JSX.Element => {
                    return (
                        <StyledDeleteIconButton onMouseDown={(event): void => onDeleteIconClicked(t, event)}>
                            <FontIcon iconName="fp-delete" className={deleteIconClass} />
                        </StyledDeleteIconButton>
                    );
                },
            },
        ]);
        // this effect should only be called once the component did mount so there should not be any dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setSearchText(props.searchText || '');
    }, [props.searchText]);

    const getKey = (item: TItemModel): string => {
        return item.id;
    };

    useEffect(() => {
        props.onReadPage(props.pagination);
    }, [props]);

    const onChangeText = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string | undefined): void => {
        props.setSearchText?.(text || '') ?? setSearchText(text || '');

        props.onReadPage({
            ...props.pagination,
            searchText: text ?? '',
            skip: 0,
        });
    };

    const onSortByColumn = (ev?: React.MouseEvent<HTMLElement>, column?: IColumn): void => {
        if (column?.fieldName === 'deleteAction') {
            return;
        }

        const newSortedColumn = onColumnHeaderClick(column);
        props.onReadPage({
            ...props.pagination,
            orderByProperty: newSortedColumn?.fieldName ?? '',
            isDescending: newSortedColumn?.isSortedDescending || false,
            skip: 0,
        });
    };

    const onColumnHeaderClick = (column?: IColumn): IColumn | undefined => {
        const processedColumns = [...columns];
        const isDescending = sortedColumnFieldName === column?.fieldName ? !descending : false;

        const newSortedColumn = processedColumns.find((c) => c.fieldName === column?.fieldName);

        if (sortedColumnFieldName === column?.fieldName && newSortedColumn) {
            newSortedColumn.isSortedDescending = isDescending;
            newSortedColumn.isSorted = true;
        } else {
            processedColumns.forEach((existingColumn) => {
                if (existingColumn.fieldName === sortedColumnFieldName) {
                    existingColumn.isSortedDescending = undefined;
                    existingColumn.isSorted = undefined;
                } else if (existingColumn.fieldName === column?.fieldName) {
                    existingColumn.isSortedDescending = false;
                    existingColumn.isSorted = true;
                }
            });
        }

        setColumns(processedColumns);
        setSortedColumnFieldName(column?.fieldName ?? '');
        setDescending(isDescending);

        return newSortedColumn;
    };

    const onPreviousClicked = (): void => {
        props.onReadPage({
            ...props.pagination,
            skip: props.pagination.skip - props.pagination.maxCount,
        });
    };

    const onNextClicked = (): void => {
        props.onReadPage({
            ...props.pagination,
            skip: props.pagination.skip + props.pagination.maxCount,
        });
    };

    const renderFilter = props.renderFilter ?? ((): JSX.Element => <></>);

    const totalPages = Math.ceil(props.model.totalItems / props.pagination.maxCount);
    const currentPage = props.pagination.skip / props.pagination.maxCount + 1;
    const startDisplayedItem = props.pagination.skip + 1;
    const endDisplayedItem = props.pagination.skip + props.model.items.length;

    return (
        <StyledFabric>
            <SearchAndFilterContainer>
                <TextFieldStyled className={'search-component'} value={searchText} label={props.searchTextLabel} onChange={onChangeText} />
                {renderFilter()}
            </SearchAndFilterContainer>
            <StyledDetailsList
                onActiveItemChanged={props.onItemClicked}
                items={props.model.items}
                selectionMode={SelectionMode.none}
                getKey={getKey}
                isHeaderVisible={true}
                layoutMode={DetailsListLayoutMode.justified}
                columns={columns}
                onColumnHeaderClick={onSortByColumn}
            />

            <CardActions>
                {props.model.totalItems > 0 && (
                    <ItemsCount>
                        {startDisplayedItem} - {endDisplayedItem} {t('OF')} {props.model.totalItems}
                    </ItemsCount>
                )}

                {props.pagination.skip > 0 ? <DefaultButton onClick={onPreviousClicked}>{t('LIST_PREVIOUS_BUTTON')}</DefaultButton> : null}
                {totalPages > 1 && (
                    <PagesCount>
                        {t('PAGE')} {currentPage} / {totalPages}
                    </PagesCount>
                )}
                {props.model.hasNextPage ? <DefaultButton onClick={onNextClicked}>{t('LIST_NEXT_BUTTON')}</DefaultButton> : null}
                {props.allowAdd && <AddButton onClick={props.onAdd}>{t('LIST_ADD_BUTTON')}</AddButton>}
                {props.actions}
            </CardActions>
            <Dialog hidden={hideDialog} onDismiss={toggleHideDialog} dialogContentProps={dialogContentProps} modalProps={modalProps}>
                <DialogFooter>
                    <PrimaryButton onClick={confirmDeletingSelectedItem} text={t('LIST_DIALOG_DELETE_CONFIRM')} />
                    <DefaultButton onClick={toggleHideDialog} text={t('LIST_DIALOG_DELETE_ABORT')} />
                </DialogFooter>
            </Dialog>
        </StyledFabric>
    );
}
