import { BaseApiModel } from '../../common/baseApiModel';
import { PaginatedOutput } from '../../common/paginatedApiOutput';
import { ReportFilterApiModel } from './reportFilterApiModel';
import { ReportVariableApiModel } from './reportVariableApiModel';

export interface ReportApiModel extends BaseApiModel {
    entityType: string;
    name: string;
    filters: ReportFilterApiModel[];
    variables: ReportVariableApiModel[];
}

export interface ReportListItemApiModel extends BaseApiModel {
    entityType: string;
    name: string;
}

export interface GenerateReportApiModel {
    reportId: string;
    filterValues: Record<string, string>;
    variableValues: Record<string, string>;
}

export interface GenerateReportCommandResultOutput {
    generatedReportId: string;
}

export type ReportListApiModel = PaginatedOutput<ReportListItemApiModel>;

export const entityTypeToReportTypeMap = {
    StudentProgramAttendance: 'STUDENT_PROGRAM_ATTENDANCE',
    Series: 'SERIES',
    StudentSeriesAttendance: 'STUDENT_SERIES_ATTENDANCE',
    CourseProgram: 'COURSE_PROGRAM',
} as const;
