import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { device } from '../../../componentsUx/cssUtils/breakpoints';
import { CourseProgramListCard } from '../courseProgram/CourseProgramsListCard';
import { BranchCard } from './BranchCard';
import { useTranslation } from 'react-i18next';
import { APP_PATHS_FROM_ROOT } from '../../routing/commands';
import { Breadcrumb } from '../../../componentsUx/breadcrumb/Breadcrumb';
import { AuthorizationCommissionDetailsCard } from '../authorizationCommission/AuthorizationCommissionDetailsCard';

const BranchGrid = styled.div`
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    @media ${device.featurePhone} {
        grid-template-areas: 'branchCard' 'branchDetails';
        grid-template-columns: minmax(0, 1fr);
    }

    @media ${device.tabletLandscape} {
        grid-template-columns: 1fr minmax(0, 2fr);
        grid-template-areas: 'branchCard branchDetails';
    }
`;

const GridCard = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 0;
`;

const BranchCardArea = styled(GridCard)`
    grid-area: branchCard;
`;

const BranchDetailsArea = styled(GridCard)`
    grid-area: branchDetails;
`;

const BranchProgramsArea = styled.div`
    margin-bottom: 20px;
`;

const BranchAuthorizationCommissionArea = styled.div``;

export function Branch(): JSX.Element {
    const { t } = useTranslation();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const isInAddMode = id === 'new';

    const breadcrumbItems = [
        {
            text: t('HOME_PAGE_NAV_ACTION_COMPANY'),
            key: 'HOME_PAGE_NAV_ACTION_COMPANY',
            onClick: (): void => history.push(APP_PATHS_FROM_ROOT.companyPage()),
        },
        {
            text: t('BRANCH_CARD_TITLE'),
            key: 'BRANCH_CARD_TITLE',
            isCurrentItem: true,
        },
    ];

    return (
        <>
            <Breadcrumb items={breadcrumbItems} />
            <BranchGrid>
                <BranchCardArea>
                    <BranchCard branchId={id} isInAddMode={isInAddMode} />
                </BranchCardArea>
                {isInAddMode || (
                    <BranchDetailsArea>
                        <BranchProgramsArea>
                            <CourseProgramListCard branchId={id} />
                        </BranchProgramsArea>
                        <BranchAuthorizationCommissionArea>
                            <AuthorizationCommissionDetailsCard branchId={id} />
                        </BranchAuthorizationCommissionArea>
                    </BranchDetailsArea>
                )}
            </BranchGrid>
        </>
    );
}
