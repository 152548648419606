import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputPicker } from '../../../../componentsUx/inputPicker/InputPicker';
import { useSession } from '../../../../state/SessionState';
import { BranchListItemApiModel } from '../../branch/branchApiModel';
import { FilterPickerProps } from './filterPickerProps';

export function BranchFilterPicker(props: FilterPickerProps): JSX.Element {
    const [wasAutopopulated, setWasAutopopulated] = useState(false);
    const [t] = useTranslation();
    const [session] = useSession();
    const [selectedBranch, setSelectedBranch] = useState<BranchListItemApiModel | null>(props.prefill?.branch ?? null);

    // We're adding an effect when the branchId is empty, to check if we have access to only one branch and automatically populate it.
    useEffect(() => {
        if (props.id || props.prefill?.branch) {
            return;
        }
        const branchIds = session.getBranchIds();
        if (branchIds?.length === 1) {
            setWasAutopopulated(true);
            props.setId(branchIds[0]);
        }
    }, [props, session]);

    const onPickerChange = (newSelected: BranchListItemApiModel): void => {
        setSelectedBranch(newSelected);
        props.setId(newSelected?.id);
    };

    if (wasAutopopulated) {
        return <></>;
    }
    return (
        <InputPicker
            label={t('REPORT_FILTER_PICKER_BRANCH')}
            value={selectedBranch}
            onChange={onPickerChange}
            apiEndpoint="Branch"
            errorMessage={props.errorMessage}
            allowFreeText={false}
            displayItem={(b: BranchListItemApiModel): string => b?.name}
            renderItem={(b: BranchListItemApiModel): JSX.Element => <div>{b?.name}</div>}
        />
    );
}
