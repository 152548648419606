import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Breadcrumb } from '../../../../componentsUx/breadcrumb/Breadcrumb';
import { APP_PATHS_FROM_ROOT, isNewEntityId } from '../../../routing/commands';
import { AddStudentDetails } from './AddStudentDetails';
import { EditStudentDetails } from './EditStudentDetails';

export function StudentDetails(): JSX.Element {
    const { t } = useTranslation();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();

    const breadcrumbItems = [
        {
            text: t('STUDENTS_LIST_CARD_TITLE'),
            key: 'STUDENTS_LIST_CARD_TITLE',
            onClick: (): void => history.push(APP_PATHS_FROM_ROOT.students()),
        },
        {
            text: t('STUDENT_CARD_TITLE'),
            key: 'STUDENT_CARD_TITLE',
            isCurrentItem: true,
        },
    ];

    return (
        <>
            <Breadcrumb items={breadcrumbItems} />
            {isNewEntityId(id) ? <AddStudentDetails /> : <EditStudentDetails studentId={id} />}
        </>
    );
}
