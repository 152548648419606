import { DateToFilter } from './dateToFilter';

export type PaginatedApiInput<T = unknown> = T & {
    maxCount: number;
    skip?: number;
    searchText?: string;
    orderByProperty?: string;
    isDescending?: boolean;
};

export interface BranchOwnedPaginatedApiInput extends PaginatedApiInput {
    branchId: string;
}

export interface SeriesPaginatedApiInput extends PaginatedApiInput {
    showClosed?: boolean;
    startDateFilter?: string;
    endDateFilter?: string;
    dateToFilter?: DateToFilter;
}

export interface PaginatedApiOutput<TItem> {
    items: TItem[];
    hasNextPage: boolean;
    totalItems: number;
}

export interface GenericCommandResultOutput {
    wasSuccessful: boolean;
    id: string | null;
}

export class PaginatedOutput<TItem> {
    items: TItem[];
    hasNextPage: boolean;
    totalItems: number;

    get first(): TItem | null {
        return this.items?.length ? this.items[0] : null;
    }

    constructor(props: PaginatedApiOutput<TItem>) {
        this.items = props?.items ?? [];
        this.hasNextPage = props?.hasNextPage ?? false;
        this.totalItems = props?.totalItems ?? 0;
    }
}

export function createPaginatedOutput<TItem>(params: PaginatedApiOutput<TItem>): PaginatedOutput<TItem> {
    return new PaginatedOutput<TItem>(params);
}

export function createDefaultPaginatedOutput<TItem>(): PaginatedOutput<TItem> {
    return createPaginatedOutput({ items: [], hasNextPage: false, totalItems: 0 });
}
