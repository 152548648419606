import React, { useEffect, useState } from 'react';
import { BranchApiModel } from '../../features/home/branch/branchApiModel';
import { ControllerInputProps } from '../input/InputFieldTypes';
import { InputPicker } from '../inputPicker/InputPicker';
import { useRecoilState } from 'recoil';
import { UniqueBranchState } from '../../state/UniqueBranchState';
import { isInitialized } from '../../utils/isInitialized';

interface Props extends ControllerInputProps {
    label: string;
}

export function BranchPicker(props: Props): JSX.Element {
    const [isDisabled, setDisabled] = useState(props.disabled);
    const [uniqueBranch] = useRecoilState(UniqueBranchState);

    useEffect(() => {
        if (isInitialized(uniqueBranch)) {
            props.onChange(uniqueBranch);
            setDisabled(true);
        }
    }, [props, uniqueBranch]);

    return (
        <InputPicker
            {...props}
            onChange={(branch): void => props.onChange(branch)}
            apiEndpoint="Branch"
            allowFreeText={false}
            disabled={isDisabled}
            displayItem={(t: BranchApiModel): string => t?.name as string}
        />
    );
}
